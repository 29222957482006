import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { connect } from 'react-redux';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText, makeStyles, TextField, IconButton } from '@material-ui/core';
import { getCalendars, RemoveTransfStatus, getUsers } from '../../actions/UsersActions.js';
import '../../styles/Users.css';
import PsnCheckbox from './../GeneralElements/PsnCheckbox';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Chip from '@material-ui/core/Chip';
import InfoRed from '../../assets/info-red.svg';
import SelectCalendar from './SelectCalendar.js';
import AdminToast from '../GeneralElements/adminToast.js';
import { withTranslation } from 'react-i18next';
import '../../styles/Users.css';

const mapStateToProps = state => ({
	usersForModals: state.users.usersForModals,
	calendars: state.users.calendars,
});

const mapDispatchToProps = dispatch => ({
	getCalendars: (mail, t) => dispatch(getCalendars(mail, t)),
	getUsers: (page, search, t) => dispatch(getUsers(page, search, t)),
});

const useStyles = makeStyles(theme => ({
	dialog: {
		backgroundColor: 'white',
		width: '500px',
		// height: "78px",
		// paddingTop: "12px",
		// paddingLeft: "8px",
		// paddingBottom: "32px",
		borderRadius: '4px',
		boxShadow: '0px 0px 12px rgba(25, 37, 46, 0.24) !important',
		'&.MuiDialogContent-root': {
			paddingLeft: '50px',
		},
	},
	dialogCont: {
		backgroundColor: 'rgba(0, 0, 0, 0.40)',
		zIndex: '4000',
	},
	dialogBody: {
		display: 'column',
		// alignItems: "center"
	},
	footer: {
		marginTop: '24px',
		paddingLeft: '24px',
		justifyContent: 'flex-start',
		paddingBottom: '24px',
		paddingTop: '0px',
	},
	row: {
		width: '348px',
		marginLeft: '8px',
		fontStyle: 'normal',
		fontWeight: 'normal',
		fontSize: '14px',
		lineHeight: '24px',
		color: 'black',
	},

	root: {
		'& .Mui-selected': {
			backgroundColor: 'pink',
			color: 'red',
			fontWeight: 'bold',
		},
		'& .Mui-selected:hover': {
			backgroundColor: 'tomato',
		},
	},

	mainButton: {
		// marginLeft: '16px',
		// marginTop: "4px",
		color: '#19252E',
		backgroundColor: 'rgba(68, 187, 255, 0.56)',
		fontWeight: '550',
		width: '79px',
		height: '32px',
		textTransform: 'none',
		fontSize: '14px',
		lineHeight: '24px',
		letterSpacing: '0.35px',
		'&:hover': {
			backgroundColor: 'rgba(68, 187, 255, 0.72)',
			color: '#19252E',
		},
		'&:active': {
			backgroundColor: 'red(68, 187, 255, 0.88)',
			color: '#19252E',
		},
		'&:disabled': {
			backgroundColor: 'rgba(25, 37, 46, 0.08) !important',
			color: '#A3A8AB',
			border: '1px solid rgba(25, 37, 46, 0.08)',
		},
	},
	cancelButton: {
		fontWeight: '550',
		width: '84px',
		height: '32px',
		textTransform: 'none',
		fontSize: '14px',
		lineHeight: '16px',
		letterSpacing: '0.35px',
		border: '1px solid #C0C0C0',
		'&:hover': {
			backgroundColor: '#EDEEEF !important',
			color: '#19252E',
		},
	},
	chipsUser: {
		fontFamily: 'PT Sans',
		fontStyle: 'normal',
		fontWeight: '400',
		fontSize: '16px',
		lineHeight: '24px',
		backgroundColor: 'red',
		color: 'rgba(22, 31, 41, 0.92)',
		marginRight: '8px',
		marginBottom: '12px',
		maxWidth: '472px',
		'text-overflow': 'ellipsis',
		overflowX: 'hidden',
		whiteSpace: 'nowrap',
		'&.MuiChip-outlined': {
			backgroundColor: 'rgba(25, 37, 46, 0.16)',
		},
	},
	dropdownStyle: {
		width: '452px',
		maxHeight: '300px',
	},
	roundTooltip: {
		width: '32px',
		height: '32px',
		position: 'absolute',
		top: '12px',
		right: '12px',
		cursor: 'pointer',
		'&:hover': {
			backgroundColor: 'rgba(25, 37, 46, 0.08); !important',
		},
		'&:active': {
			backgroundColor: 'rgba(25, 37, 46, 0.16); !important',
		},
	},
}));

const CalendarManager = ({
	open,
	setOpen,
	mail,
	t,
	// calendars,
	calendars_status,
	transf_status,
	transf_code,
	calendarsFetching,
	calendarsTransfering,
	mainButton,
	closeButton,
	usersForModals,
	getUsers,
}) => {
	const dispatch = useDispatch();
	const [calendarsToTransfer, setCalendarsToTransfer] = useState([]);
	const [newUser, setNewUser] = useState('');
	const classes = useStyles();

	useEffect(() => {
		dispatch(RemoveTransfStatus());
		setNewUser('');
	}, [open]);

	const transfSubmit = () => {
		let replace = [];
		replace.push({ op: 'replace', path: `/new_owner`, value: `${newUser.mail}` });
		let i;
		for (i = 0; i < calendarsToTransfer.length; i++) {
			replace.push({ op: 'replace', path: `/calendar_ids`, value: calendarsToTransfer[i] });
		}
		mainButton.action(replace);
	};
	const closeButtonAction = () => {
		setOpen('');
	};

	return (
		<>
			<Dialog open={open != ''} onClose={() => setOpen(false)} maxWidth='false' classes={{ paper: classes.dialog, container: classes.dialogCont }} className='calManager'>
				<IconButton className={classes.roundTooltip} onClick={closeButtonAction}>
					<div style={{ width: '24px', height: '24px' }}>
						<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
							<path d='M5 6.4L6.4 5L12 10.6L17.6 5L19 6.4L13.4 12L19 17.6L17.6 19L12 13.4L6.4 19L5 17.6L10.6 12L5 6.4Z' fill='#19252E' />
						</svg>
					</div>
				</IconButton>
				<div
					className='serverError'
					style={{
						display: transf_status === 500 || transf_status === 'unknown' ? 'flex' : 'none',
					}}
				>
					<img style={{ marginRight: '8px' }} src={InfoRed} alt={InfoRed} width='24px' height='24px' />
					{t('domains_page.delete_error')}
				</div>
				<DialogTitle>
					<div className='calendarsTitle'>{t('users_page.manage_user_calendars')}</div>
				</DialogTitle>
				<DialogContent className={classes.dialogBody}>
					<DialogContentText className='calendarsSmallTitle'>{t('users_page.new_owner_info')}</DialogContentText>
					<SelectCalendar t={t} mail={mail} open={open} calendarsToTransfer={calendarsToTransfer} setCalendarsToTransfer={v => setCalendarsToTransfer(v)} />
					<div style={{ marginTop: '16px' }}>
						<div className='fieldLabel'>{t('users_page.new_owner')}</div>
						<Autocomplete
							className='userForCalendars'
							multiple
							disabled={newUser}
							filterSelectedOptions
							id='tags-standard'
							options={usersForModals.filter(e => e.mail !== mail)}
							autoHighlight={false}
							filterOptions={(options, params) => {
								if (params.inputValue.length >= 1) {
									return options;
								}
								return [];
							}}
							noOptionsText={''}
							clearText={t('clear')}
							getOptionLabel={option => `${option.cn} ${option.sn} <${option.mail}>`}
							renderTags={(value, getTagProps) =>
								value.map((option, index) => <Chip key={index} label={`${option.cn} ${option.sn}`} {...getTagProps({ index })} disabled={false} />)
							}
							ListboxProps={{
								style: {
									maxHeight: '224px',
									//   maxWidth: "472px",
								},
							}}
							onChange={(e, value) => {
								setNewUser(value[0]);
							}}
							renderInput={params => (
								<TextField
									{...params}
									className='menuRow'
									style={{
										maxHeight: '263px',
										overflowX: 'hidden',
									}}
									disableUnderline
									onChange={e => {
										getUsers(1, e.target.value, t);
									}}
								/>
							)}
						/>
					</div>
				</DialogContent>
				<DialogActions className={classes.footer}>
					<Button className={classes.mainButton} onClick={transfSubmit} disabled={!newUser || !calendarsToTransfer.length}>
						{t('users_page.done')}
					</Button>
					<Button disableRipple className={classes.cancelButton} onClick={closeButtonAction}>
						{t('domains_page.cancel')}
					</Button>
				</DialogActions>
			</Dialog>
			{/* <AdminToast
				open={transf_status}
				mainTitle={t('users_page.transfered_many_calendars')}
			// setOpen={(v) => this.setState({ showCalendarManager: v })}
			/>  */}
		</>
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CalendarManager));

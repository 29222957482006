export const LANGUAGES = ['ru-RU', 'en-US', 'es-PA', 'fr-FR', 'pt-BR'];
export const DEFAULT_LANGUAGE = 'ru-RU';
export const LANGARR = [
	{
		label: 'Русский',
		value: 'ru-RU',
	},
	{
		label: 'English',
		value: 'en-US',
	},
	{
		label: 'Español',
		value: 'es-PA',
	},
	{
		label: 'Français',
		value: 'fr-FR',
	},
	{
		label: 'Português',
		value: 'pt-BR',
	},
	{
		label: 'Italiano',
		value: 'it-IT',
	},
	{
		label: 'Башҡорт',
		value: 'ba-RU',
	},
	{
		label: 'Татарча',
		value: 'tt-RU',
	},
];

import React from 'react';
import PersonAvatar from '../../assets/dummyAvatar.svg';
import Active from '../../assets/avatar_active.svg';
import Blocked from '../../assets/avatar_block.svg';
import base64url from 'base64url';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import DarkTooltip from '../GeneralElements/DarkTooltip.js';
import { withTranslation } from 'react-i18next';

class AvatarUploader extends React.Component {
	constructor(props) {
		super(props);
		let tenant = localStorage.getItem('tenant');
		let avatarsUrl = window.AVATARS_URL;
		let { email, displayName } = props;
		this.handleAvatarClick = this.handleAvatarClick.bind(this);
		this.handleUploadChange = this.handleUploadChange.bind(this);
		this.handleModalClose = this.handleModalClose.bind(this);
		this.onImageLoaded = this.onImageLoaded.bind(this);
		this.onCropChange = this.onCropChange.bind(this);
		this.onCropComplete = this.onCropComplete.bind(this);
		this.makeClientCrop = this.makeClientCrop.bind(this);
		this.handleCropSelection = this.handleCropSelection.bind(this);
		this.fileInput = React.createRef();

		this.state = {
			showCropper: false,
			crop: {
				unit: '%',
				aspect: 1,
			},
			avatarToPreview: null,
			croppedImageUrl: null,
			enableSaveAvatar: false,
			imageRef: null,
			avatarsUrl,
			tenant,
			link: '',
			email: email,
			displayName: displayName,
		};
	}

	componentDidUpdate(prevState) {
		let timeStamp = new Date();
		if (prevState.email !== this.props.email) {
			let { email, displayName, inetUserStatus } = this.props;
			if (this.props.email && this.props.displayName) {
				this.setState({ link: `${this.state.avatarsUrl}${this.state.tenant}/${base64url(email)}/${base64url(displayName)}?${timeStamp}` });
			}
		}
	}

	handleAvatarClick(e) {
		e.preventDefault();
		this.fileInput.current.click();
	}

	handleUploadChange(files) {
		if (files && files.length > 0) {
			const reader = new FileReader();
			reader.addEventListener('load', () => this.setState({ avatarSrc: reader.result, showCropper: true }));
			reader.readAsDataURL(files[0]);
		}
	}

	handleModalClose(e) {
		e.preventDefault();
		this.setState({ showCropper: false, enableSaveAvatar: false });
	}

	onImageLoaded(image) {
		this.setState({ imageRef: image });
	}

	onCropComplete(crop) {
		this.makeClientCrop(crop);
	}

	onCropChange(crop, percentCrop) {
		this.setState({ crop, enableSaveAvatar: false });
	}

	async makeClientCrop(crop) {
		if (this.state.imageRef && crop.width && crop.height) {
			const croppedImageUrl = await this.getCroppedImg(this.state.imageRef, crop, 'newAvatar.jpeg');
			this.setState({ croppedImageUrl, enableSaveAvatar: true });
		}
	}

	handleCropSelection(e) {
		e.preventDefault();
		this.fileInput.current.value = '';
		this.setState({ avatarToPreview: this.state.croppedImageUrl, showCropper: false, imageRef: null });
		if (this.props.onAvatarCrop) {
			this.props.onAvatarCrop(this.state.croppedImageUrl);
		}
	}

	getCroppedImg(image, crop, fileName) {
		const canvas = document.createElement('canvas');
		const scaleX = image.naturalWidth / image.width;
		const scaleY = image.naturalHeight / image.height;
		canvas.width = crop.width;
		canvas.height = crop.height;
		const ctx = canvas.getContext('2d');

		ctx.drawImage(image, crop.x * scaleX, crop.y * scaleY, crop.width * scaleX, crop.height * scaleY, 0, 0, crop.width, crop.height);

		return new Promise((resolve, reject) => {
			canvas.toBlob(blob => {
				if (!blob) {
					console.error('Canvas is empty');
					return;
				}
				blob.name = fileName;
				let fileUrl = window.URL.createObjectURL(blob);
				resolve(fileUrl);
			}, 'image/jpeg');
		});
	}

	render() {
		const { t } = this.props;
		return (
			<div className='ava'>
				<Modal
					style={{
						width: '500px',
						height: '300px',
						margin: '200px auto',
						border: 'none',
					}}
					open={this.state.showCropper}
					onClose={this.handleModalClose}
					aria-labelledby='simple-modal-title'
					aria-describedby='simple-modal-description'
				>
					<div>
						<ReactCrop
							src={this.state.avatarSrc}
							crop={this.state.crop}
							ruleOfThirds
							onImageLoaded={this.onImageLoaded}
							onComplete={this.onCropComplete}
							onChange={this.onCropChange}
						/>
						<Button disabled={!this.state.enableSaveAvatar} onClick={this.handleCropSelection} style={{ float: 'right' }} variant='contained'>
							Ok
						</Button>
					</div>
				</Modal>
				<DarkTooltip title={this.props.inetUserStatus === '0' ? <>{t('users_page.blocked')}</> : <>{t('users_page.active')}</>} placement='bottom-start'>
					<div style={{ display: 'flex', marginTop: '10px' }}>
						<img
							style={{ borderRadius: '50%', cursor: 'pointer' }}
							onClick={this.handleAvatarClick}
							src={this.state.avatarToPreview || (!this.state.link ? PersonAvatar : this.state.link)}
							alt={'Avatar'}
							className='avaImg'
						/>
						<img className='avaStatus' src={this.props.inetUserStatus === '0' ? Blocked : Active} alt={''} />
					</div>
				</DarkTooltip>
				<input ref={this.fileInput} onChange={e => this.handleUploadChange(e.target.files)} type='file' accept='image/*' style={{ visibility: 'hidden' }} />
			</div>
		);
	}
}

export default withTranslation()(AvatarUploader);

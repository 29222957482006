import React, { useEffect, useState } from 'react';
import { TextField, makeStyles, MenuItem, Select, Button, IconButton } from '@material-ui/core';
import { parser } from '../../../allFunctions/parser.js';
import DarkTooltip from '../../GeneralElements/DarkTooltip.js';
import Plus from '../../../assets/plus.svg';
import GreyPlus from '../../../assets/GreyPlus.svg';
import DeleteFilter from '../../../assets/deleteFilter.svg';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import '../../../styles/Maillists.css';

const Filters = ({ filters, setFilters, editingGroup, domains, t }) => {
	const useStyles = makeStyles(theme => ({
		addUserBtn: {
			width: '183px',
			height: '32px',
			paddingLeft: '0px',
			marginTop: '16px',
			marginBottom: '64px',
			color: '#19252E',
			// backgroundColor: "#F5F5F5",
			fontStyle: 'normal',
			fontWeight: '500',
			fontSize: '14px',
			lineHeight: '24px',
			letterSpacing: '0.35px',
			border: '1px solid rgba(25, 37, 46, 0.4)',
			textTransform: 'none',
			cursor: 'pointer',
			'&:hover': {
				backgroundColor: 'rgba(25, 37, 46, 0.08) !important',
				color: '#19252E',
				border: '1px solid rgba(25, 37, 46, 0.08)',
			},
			'&:disabled': {
				backgroundColor: 'rgba(25, 37, 46, 0.08) !important',
				color: '#A3A8AB',
				border: '1px solid rgba(25, 37, 46, 0.08)',
				cursor: 'pointer',
			},
		},
		RoundTooltip: {
			width: '32px',
			height: '32px',
			'&:hover': {
				backgroundColor: 'rgba(25, 37, 46, 0.08); !important',
			},
			'&:active': {
				backgroundColor: 'rgba(25, 37, 46, 0.16); !important',
			},
		},
	}));

	const classes = useStyles();
	const filtersTypes = [
		{ value: 'title', name: t('mailLists.title') },
		{ value: 'ou', name: t('mailLists.departament') },
		{ value: 'l', name: t('mailLists.city') },
		{ value: 'domain', name: t('mailLists.domain') },
		{ value: 'custom', name: t('mailLists.custom'), disabled: filters.find(el => el.type === 'custom') },
	];

	const handleAddFilter = () => {
		setFilters([
			...filters,
			{
				type: filtersTypes[0].value,
				value: '',
				id: Date.now(),
				isValid: true,
			},
		]);
	};

	const handleRemoveFilter = id => {
		setFilters(filters.filter(row => row.id !== id));
	};

	const handleFilterTypeChange = (e, index) => {
		e.preventDefault();
		let filterType = e.target.value;
		let currentFilters = filters;
		if (filterType === 'domain') currentFilters[index].value = domains[0].name
		if (filterType === 'custom') currentFilters[index].isValid = true;
		if (filterType !== 'custom') {
			const regex = /[()=\*\\&|]/g;
			if (regex.test(currentFilters[index].value)) currentFilters[index].isValid = false;
			else currentFilters[index].isValid = true;
		}
		currentFilters[index].type = filterType;
		setFilters(currentFilters);
	};

	const handleFilterValueChange = (e, index, type, id) => {
		e.preventDefault();
		let filterValue = e.target.value;
		let currentFilters = filters;

		const trimStartRegex = /^\s+/;
		if (type !== 'custom') {
			const regex = /[()=\*\\&/|]/g;
			if (regex.test(filterValue)) currentFilters[index].isValid = false;
			else currentFilters[index].isValid = true;
		}
		if (type === 'custom') {
			currentFilters[index].isValid = true;
		}
		if (!filterValue) currentFilters[index].isValid = true;

		currentFilters[index].value = filterValue.replace(trimStartRegex, '');
		setFilters(currentFilters);
	};

	const handleDomianValueChange = (e, index) => {		
		e.preventDefault();
		let filterValue = e.target.value;
		let currentFilters = filters;
		currentFilters[index].value = filterValue;
		setFilters(currentFilters);
	};

	return (
		<>
			{filters.map((el, index) => (
				<>
					<div className='staticRaw' style={{ marginBottom: '16px' }}>
						<div style={{ display: '' }}>
							<div style={{ display: 'flex' }}>
								<Select
									select
									variant='outlined'
									className={el.noTypeChange ? 'FilterSelectFieldDisabled' : 'FilterSelectField' }								
									MenuProps={{
										getContentAnchorEl: null,
										anchorOrigin: {
											vertical: 'bottom',
											horizontal: 'left',
										},
										transformOrigin: {
											vertical: 'top',
											horizontal: 'left',
										},
									}}									
									value={el.type}
									disabled={el.noTypeChange}
									onChange={e => {										
										handleFilterTypeChange(e, index);
									}}
								>
									{filtersTypes.map((option, key) => (
										<MenuItem className='options' key={'-1' + key} value={option.value} disabled={option.disabled}>
											{option.name}
										</MenuItem>
									))}
								</Select>
								<div style={{ marginLeft: '8px', display: el.type === 'custom' && filters.length !== 1 ? '' : 'none' }}>
									<DarkTooltip title={<>{t('mailLists.delete_condition')}</>} placement={'bottom-start'}>
										<IconButton aria-label='Remove' onClick={() => handleRemoveFilter(el.id)} className={classes.RoundTooltip}>
											<img src={DeleteFilter} alt={''} />
										</IconButton>
									</DarkTooltip>
								</div>
							</div>
							<div>
								<TextField
									style={{ display: el.type === 'custom' ? '' : 'none', marginTop: '16px' }}
									variant='outlined'
									className='customValueField'
									placeholder={t('mailLists.enter_LDAP')}
									inputProps={{
										autocomplete: 'disabled',
										type: 'text',
									}}
									multiline
									minRows={4}
									value={el.value}
									onChange={e => handleFilterValueChange(e, index, el.type, el.id)}
								/>
							</div>
						</div>
						<div style={{ marginLeft: '16px', display: el.type === 'custom' ? 'none' : '' }}>
							<TextField
								variant='outlined'
								className='filterMiddleField'
								inputProps={{
									autocomplete: 'disabled',
									type: 'text',
								}}
								disabled
								value={t('mailLists.matches')}
							/>
						</div>
						<div style={{ marginLeft: '16px', display: el.type === 'custom' ? 'none' : '' }} id={!el.isValid ? 'filterValueFieldError' : ''}>
							{el.type === 'domain' ? (
								<Select
									select
									variant='outlined'
									className='selectFieldDomainsFilter'
									MenuProps={{
										getContentAnchorEl: null,
										anchorOrigin: {
											vertical: 'bottom',
											horizontal: 'left',
										},
										transformOrigin: {
											vertical: 'top',
											horizontal: 'left',
										},
									}}
									value={el.value? el.value : domains[0].name}
									onChange={e => handleDomianValueChange(e, index)}									
								>
									{domains ? (
										domains.map((option, key) => (
											<MenuItem className='options' key={'-1' + key} value={option.name}>
												{option.name}
											</MenuItem>
										))
									) : (
										<div />
									)}
								</Select>
							) : (
								<TextField
									variant='outlined'
									className='filterValueField'									
									inputProps={{
										autocomplete: 'disabled',
										type: 'text',
									}}
									value={el.value}
									onChange={e => handleFilterValueChange(e, index, el.type, el.id)}
								/>
							)}
						</div>
						<div style={{ marginLeft: '8px', display: el.type === 'custom' || filters.length === 1 ? 'none' : '' }}>
							<DarkTooltip title={<>{t('mailLists.delete_condition')}</>} placement={'bottom-start'}>
								<IconButton aria-label='Remove' onClick={() => handleRemoveFilter(el.id)} className={classes.RoundTooltip}>
									<img src={DeleteFilter} alt={''} />
								</IconButton>
							</DarkTooltip>
						</div>
					</div>
					<div
						className='validFilter'
						style={{
							display: !el.isValid ? '' : 'none',
						}}
					>
						{t('mailLists.error_valid_filter')}
						{' ()*&=|/\\'}
					</div>
				</>
			))}

			{filters.length === 10 ? (
				<DarkTooltip title={<>{t('mailLists.max_filters')}</>} placement={'bottom-start'}>
					<div>
						<Button disableRipple className={classes.addUserBtn} disabled>
							<div style={{ paddingLeft: '0px', display: 'flex' }}>
								<img src={GreyPlus} alt={''} />
								<div style={{ marginLeft: '10px' }}>{t('mailLists.add_condition')}</div>
							</div>
						</Button>
					</div>
				</DarkTooltip>
			) : (
				<Button disableRipple className={classes.addUserBtn} onClick={handleAddFilter} disabled={filters.length === 10}>
					<div style={{ paddingLeft: '0px', display: 'flex' }}>
						<img src={Plus} alt={Plus} />
						<div style={{ marginLeft: '10px' }}>{t('mailLists.add_condition')}</div>
					</div>
				</Button>
			)}
		</>
	);
};

export default withTranslation()(Filters);

import React, { useEffect, useState } from 'react';
import { TextField, makeStyles } from '@material-ui/core';
import { parser } from '../../allFunctions/parser.js';
import DarkTooltip from '../GeneralElements/DarkTooltip.js';
import { withTranslation } from 'react-i18next';
import '../../styles/Users.css';

const useStyles = makeStyles(theme => ({
	passHidden: {
		fontSize: '14px !important',
		fontFamily: 'text-security-disc',
		'-webkit-text-security': 'disc',
		'-moz-text-security': 'disc',
		'text-security': 'disc',
	},
}));

const PassFields = ({ setUserPassword, setPasswordConfirm, setPasswordError, edit, t, ...props }) => {
	const classes = useStyles();
	const [userPasswordCur, setUserPasswordCur] = useState('');
	const [passwordConfirmCur, setPasswordConfirmCur] = useState('');
	const [passwordErrorCur, setPasswordErrorCur] = useState(false);

	const passChangeHandler = (pass, v) => {	
		if (v === 'userPasswordCur') {
			setUserPasswordCur(pass);
			setUserPassword(pass);	
			setPasswordErrorCur(false);
			setPasswordError(false)
		}
		else if (v === 'passwordConfirmCur') {
			setPasswordConfirmCur(pass);
			setPasswordConfirm(pass);
			setPasswordErrorCur(false);
			setPasswordError(false)
		}		
	};

	return (
		<>
			<div className='addNewUserForm' style={{ marginBottom: '8px' }}>
				{t('change_password')}
			</div>
			<div className='row'>
				<div>
					<div className={edit ? 'fieldLabel' : 'fieldLabel asterisk'}>{t('new_password')}</div>
					<DarkTooltip title={<>{t('users_page.tooltip_disabled_fields')}</>} placement='bottom-start' hidden={!window.pgs_integration}>
						<TextField
							disabled={window.pgs_integration}
							className='passField'
							variant='outlined'
							style={{ marginRight: '16px' }}
							InputProps={{
								classes: { input: classes.passHidden },
							}}
							value={userPasswordCur}
							error={passwordErrorCur}
							helperText={passwordErrorCur && t('password_mismatch')}
							onChange={e => {
								passChangeHandler(e.target.value, 'userPasswordCur');								
							}}
							onBlur={e => {
								if (userPasswordCur && passwordConfirmCur && (userPasswordCur !== passwordConfirmCur)) {
									setPasswordErrorCur(true)
									setPasswordError(true);
								} else {
									setPasswordErrorCur(false);
									setPasswordError(false)
								}
							}}
						/>
					</DarkTooltip>
				</div>
				<div>
					<div className={edit ? 'fieldLabel' : 'fieldLabel asterisk'}>{t('confirm_password')}</div>
					<DarkTooltip title={<>{t('users_page.tooltip_disabled_fields')}</>} placement='bottom-start' hidden={!window.pgs_integration}>
						<TextField
							disabled={window.pgs_integration}
							variant='outlined'
							className='passField'
							InputProps={{ classes: { input: classes.passHidden } }}
							error={passwordErrorCur}
							value={passwordConfirmCur}
							onChange={e => {
								passChangeHandler(e.target.value, 'passwordConfirmCur');
								
							}}
							onBlur={e => {
								if (userPasswordCur && passwordConfirmCur && (userPasswordCur !== passwordConfirmCur)) {
									setPasswordErrorCur(true)
									setPasswordError(true);
								} else {
									setPasswordErrorCur(false);
									setPasswordError(false)
								}
							}}
						/>
					</DarkTooltip>
				</div>
			</div>
		</>
	);
};

export default withTranslation()(PassFields);

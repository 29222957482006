import React from 'react';
import { LANGARR } from '../../constants/LanguageConstants';
import { TextField, Button, Tooltip, MenuItem, Select } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { addNewTenant, removeAddStatus } from '../../actions/TenantsActions.js';
import { connect } from 'react-redux';
import Info from '../../assets/info.svg';
import { createHashHistory } from 'history';
import InfoRed from '../../assets/info-red.svg';
import i18next from 'i18next';
import BackArrow from '../../assets/backArrow.svg';
import CircularProgress from '@material-ui/core/CircularProgress';
import { mailParser } from '../../allFunctions/parser.js';
import { showAlertAction } from '../../actions/AlertsActions';
import '../../styles/Domain.css';

const mapStateToProps = state => ({
	add_status: state.tenants.add_status,
	add_code: state.tenants.add_code,
	addingNewTenant: state.tenants.addingNewTenant,
});

const mapDispatchToProps = dispatch => ({
	addNewTenant: (name, domain, lang) => dispatch(addNewTenant(name, domain, lang)),
	removeAddStatus: () => dispatch(removeAddStatus()),
	showAlertAction: (message, type) => dispatch(showAlertAction(message, type)),
});

const styles = theme => ({
	infoForm: {
		marginBottom: '10px',
		'& > *': {
			margin: '10px',
		},
	},
	buttonsBlock: {
		'& > *': {
			margin: '10px',
		},
	},

	spinner: {
		position: 'absolute',
		left: '500px',
		top: '276px',
		color: '#FF962E !important',
	},
	descTextField: {
		maxHeight: '200px',
		overflowY: 'scroll !important',
		paddingTop: '0px !important',
	},
	// tenantTextField: {
	//   height: "40px",
	//   "&:disabled": {
	//   backgroundColor: "red"
	//   },
	// },
	addDomainBtn: {
		width: '105px',
		height: '32px',
		paddingLeft: '16px',
		marginRight: '12px',
		color: '#19252E',
		backgroundColor: 'rgba(68, 187, 255, 0.56)',
		// fontStyle: "normal",
		fontWeight: '500',
		fontSize: '14px',
		lineHeight: '24px',
		letterSpacing: '0.35px',
		border: '1px solid rgba(25, 37, 46, 0.4)',
		textTransform: 'none',
		cursor: 'pointer',
		'&:hover': {
			backgroundColor: 'rgba(68, 187, 255, 0.86) !important',
			color: '#19252E',
			border: '1px solid rgba(25, 37, 46, 0.08)',
		},
		'&:disabled': {
			backgroundColor: 'rgba(25, 37, 46, 0.08) !important',
			color: '#A3A8AB',
			border: '1px solid rgba(25, 37, 46, 0.08)',
			cursor: 'pointer !important',
		},
		// "&:selected": {
		//   backgroundColor: "#BFDDF3 !important",
		//   color: "#0079D0",
		//   // border: "1px solid rgba(25, 37, 46, 0.08)",
		// },
		// "&:active": {
		//   backgroundColor: "#BFDDF3 !important",
		//   color: "#0079D0",
		//   // border: "1px solid rgba(25, 37, 46, 0.08)",
		// },
	},

	cancelButton: {
		paddingLeft: '16px',
		color: '#19252E',
		backgroundColor: 'white !important',
		fontWeight: '525',
		width: '84px',
		height: '32px',
		textTransform: 'none',
		fontSize: '14px',
		lineHeight: '16px',
		letterSpacing: '0.35px',
		border: '1px solid rgba(25, 37, 46, 0.4)',
		'&:hover': {
			backgroundColor: 'rgba(25, 37, 46, 0.08) !important',
			color: '#19252E',
			border: '1px solid rgba(25, 37, 46, 0.4)',
		},
		// "&:active": {
		//   backgroundColor: "#BFDDF3 !important",
		//   color: "#0079D0",
		//   border: "1px solid #0079D0"
		// }
	},
	RoundTooltip: {
		width: '32px',
		height: '32px',
		marginTop: '16px',
		'&:hover': {
			backgroundColor: 'rgba(25, 37, 46, 0.08); !important',
		},
		'&:active': {
			backgroundColor: 'rgba(25, 37, 46, 0.16); !important',
		},
	},
});

class NewTenant extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			name: '',
			domain: '',
			lang: '',
			domainFieldError: false,
			nameFieldError: false,
			nameLimitError: false,
			langArr: LANGARR,
		};
		this.history = createHashHistory();
	}

	componentDidMount() {
		this.setState({ lang: this.state.langArr[0].label });
		this.props.removeAddStatus();
	}

	handleTenantSubmit = e => {
		e.preventDefault();
		const langValue = this.state.langArr.find(elem => elem.label === this.state.lang);
		this.props.addNewTenant(this.state.name.trim(), this.state.domain, langValue.value);	
		let message = `${i18next.t("tenants.tenant_added")}`;  
		if (navigator.onLine===true) this.props.showAlertAction(message, 'admin_toast_success');
	};

	handleClickOpen = () => {
		this.setState({ errorWindow: true });
	};

	handleClose = () => {
		this.setState({ errorWindow: false });
	};

	handleDomainNameBlur(e) {
		e.preventDefault();
		let value = e.target.value;
		if (value && (value.split('.').length < 2 || /\.$|-$|^\.|^-|(\W\W)/.test(value))) return this.setState({ domainFieldError: true });
		if (value.split('.').find(item => item.length < 2)) {
			this.setState({ domainFieldError: true });
		} else this.setState({ domainFieldError: false });
	}

	domainNameChangeHandler = (e, v) => {
		e.preventDefault();
		let value = e.target.value;
		const tester = /^[0-9A-Z-a-z.]+$/;
		if (!value.match(tester) & (value !== '') || value.length > 63) {
			this.setState({ domainFieldError: true });
		} else {
			this.setState({ domainFieldError: false });
		}
		this.setState({ domain: mailParser(value) });
	};

	tenantNameChangeHandler = e => {
		e.preventDefault();
		let value = e.target.value;
		const tester = /^[0-9 А-Яа-я_A-Z-a-z.]+$/;
		if (!value.match(tester) & (value !== '')) {
			this.setState({ nameFieldError: true });
		} else {
			this.setState({ nameFieldError: false });
		}
		if (value.length > 100) {
			this.setState({ nameLimitError: true });
		} else this.setState({ nameLimitError: false });
		this.setState({ name: value.replace(/ {2,}/g, ' ') });
	};

	render() {
		let classes = this.props.classes;
		const selectLang = this.state.langArr.map((option, key) => {
			return (
				<MenuItem
					// className="period_contaner_select"
					key={'-1' + key}
					value={option}
					MenuProps={{ id: 'type_selected_layer' }}
					classes={{ root: 'selected_period' }}
				>
					<span className='dropdown-title'>{option.label}</span>
				</MenuItem>
			);
		});

		return (
			<div className={this.props.addingNewDomain ? 'NewDomainRootLoading' : 'NewDomainRoot'}>
				<CircularProgress className={classes.spinner} style={{ display: this.props.addingNewDomain ? 'block' : 'none' }} />
				<div style={{ display: 'flex', cursor: 'pointer' }} onClick={e => this.history.push('/tenants')}>
					<div>
						<Tooltip title={i18next.t('back')}>
							<img src={BackArrow} alt={BackArrow} width='24px' />
						</Tooltip>
					</div>
					<div className='title' style={{ marginBottom: '32px', marginLeft: '12px' }}>
						{i18next.t('tenants.new_tenant')}
					</div>
				</div>
				<div
					className='DomainsAddError'
					style={{
						display: this.props?.add_status === 'unknown' ? 'flex' : 'none',
					}}
				>
					<img style={{ marginRight: '8px' }} src={InfoRed} alt={InfoRed} width='24px' height='24px' />
					{i18next.t('domains_page.delete_error')}
				</div>
				<div className='NewDomainTitle2'>{i18next.t('tenants.name')}</div>

				<div className='NewDomainField1'>
					<TextField
						className='NewDomainField1'
						variant='outlined'
						id='outlined-required'
						value={this.state.name}
						InputProps={{ classes: { input: classes.tenantTextField } }}
						onChange={e => {
							this.tenantNameChangeHandler(e);
						}}
					/>
				</div>
				<div
					className='underDomainFieldError'
					style={{
						display: this.state.nameFieldError ? 'block' : 'none',
					}}
				>
					{i18next.t('tenants.error_name')}
				</div>
				<div
					className='underDomainFieldError'
					style={{
						display: this.state.nameLimitError ? 'block' : 'none',
					}}
				>
					{i18next.t('tenants.error_name_limit')}
				</div>

				<div className='NewDomainTitle2' style={{ marginTop: '16px' }}>
					{i18next.t('domains_page.domain_name')}
				</div>
				<div className='NewDomainField1'>
					<TextField
						className='NewDomainField1'
						variant='outlined'
						id='outlined-required'
						placeholder={i18next.t('domains_page.place_holder')}
						value={this.state.domain}
						onChange={e => {
							this.domainNameChangeHandler(e, 'domain');
						}}
						onBlur={e => {
							this.handleDomainNameBlur(e);
						}}
					/>
				</div>
				<div
					className='underDomainFieldError'
					style={{
						display: this.state.domainFieldError || this.props.add_code === 307 ? 'block' : 'none',
					}}
				>
					{i18next.t('domains_page.error_format')}
				</div>

				<div
					className='underDomainFieldError'
					style={{
						display: this.props.add_status === 409 && this.props.add_code === 101 ? 'block' : 'none',
					}}
				>
					{i18next.t('domains_page.exist_name')}
				</div>

				<div className='NewDomainInfoName' style={{ marginBottom: '16px' }}>
					{i18next.t('domains_page.info_name')}
				</div>

				<div className='NewDomainTitle2'>{i18next.t('tenants.lang')}</div>

				<div className='NewDomainField2' style={{ marginBottom: '20px' }}>
					<Select
						disableUnderline
						className='lang_select'
						variant='outlined'
						MenuProps={{
							getContentAnchorEl: null,
							anchorOrigin: {
								vertical: 'bottom',
								horizontal: 'left',
							},
						}}
						onChange={e => this.setState({ lang: e.target.value.label })}
						value={this.state.lang}
						renderValue={option => <span className='dropdown-title'>{option}</span>}
						style={{ marginBottom: '8px' }}
					>
						{selectLang}
					</Select>
				</div>

				<div>
					<Button
						onClick={this.handleTenantSubmit}
						className={classes.addDomainBtn}
						variant='outlined'
						disabled={this.state.domainFieldError || !this.state.domain || !this.state.name || this.state.nameFieldError || this.state.nameLimitError}
					>
						{i18next.t('tenants.save')}
					</Button>
					<Button onClick={e => this.history.push('/tenants')} className={classes.cancelButton} variant='outlined'>
						{i18next.t('tenants.cancel')}
					</Button>
				</div>
			</div>
		);
	}
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(NewTenant));

import React, { useState, useEffect, useRef } from 'react';
import '../../styles/Login.css';
import { connect } from 'react-redux';
import { getAuthorized, getNewCaptcha } from '../../actions/LoginActions.js';
import { useTranslation } from 'react-i18next';

import { withStyles } from '@material-ui/core/styles';
import { AuthPanel } from '@nctstageoffice/psn-auth-panel';
import Logo from '../../assets/MyOffice-logo.svg';

const mapStateToProps = state => ({
	isAuthing: state.auth.isAuthing,
	captchaUriProps: state.auth.captchaUri,
	lastAuthFailed: state.auth.lastAuthFailed,
});

const mapDispatchToProps = dispatch => ({
	getAuthorized: (login, password, captcha) => dispatch(getAuthorized(login, password, captcha)),
	getNewCaptcha: () => dispatch(getNewCaptcha()),
});

const styles = theme => ({
	changeLang: {
		marginLeft: '85vw',
		display: 'flex',
		width: '300px',
		height: '400px',
	},
});

const LoginComponent = props => {
	const { t, i18n } = useTranslation();

	const { isAuthing, getAuthorized, captchaUriProps, lastAuthFailed, getNewCaptcha } = props;
	const [captchaUri, setCaptchaUri] = useState(null); //uri to img of cp
	const [captchaVError, setCaptchaVError] = useState(null); //only view var, shows underline cp_input error
	const isCaptchaUpdated = useRef(false);
	const authHandler = async (login, password, captcha) => {
		if (captchaUri && !captcha) {
			setCaptchaVError(t('captcha.fail_verify'));
			return;
		}
		setCaptchaVError('');

		await getAuthorized(login, password, captcha);
	};
	useEffect(() => {
		if (captchaUri !== captchaUriProps) {
			//if captcha changed
			if (captchaUri && captchaUriProps && !isCaptchaUpdated.current) {
				//both not null, so the previous one sent to server wasn't accepted
				setCaptchaVError(t('captcha.fail_verify'));
			}

			isCaptchaUpdated.current = false;
			setCaptchaUri(captchaUriProps);
		}
	}, [captchaUri, captchaUriProps, t]);

	return (
		<div className='auth-panel'>
			<AuthPanel
				configName='AdminConfig'
				context={{ ...props, i18n }}
				authIsUpdating={isAuthing && !isCaptchaUpdated.current} //&& !isCaptchaUpdated.current
				authHandler={authHandler}
				error={lastAuthFailed ? t('fail_auth') : ''}
				captchaError={!!captchaUri}
				captchaTextError={captchaVError}
				captchaSrc={'data:image/jpeg;base64,' + captchaUri}
				capthaUpdateHandler={async () => {
					getNewCaptcha();
					setCaptchaVError('');
					isCaptchaUpdated.current = true;
				}}
				customName={window.AdminConfig.pgs_integration ? t('auth.pgs_name') : t('auth.no-pgs_name')}
				customLogoSrc={Logo}
			/>
		</div>
	);
};

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(LoginComponent));

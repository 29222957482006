import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText, makeStyles } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import '../../styles/Users.css';

const useStyles = makeStyles(theme => ({
	dialog: {
		position: 'absolute',
		backgroundColor: 'white',
		top: '5px',
		width: '424px',
		borderRadius: '4px',
		boxShadow: '0px 0px 12px rgba(25, 37, 46, 0.24) !important',
		'&.MuiDialogContent-root': {
			paddingLeft: '50px',
		},
	},
	dialogBody: {
		display: 'flex',
		// alignItems: "center"
	},
	footer: {
		paddingLeft: '24px',
		justifyContent: 'flex-start',
		paddingBottom: '12px',
		paddingTop: '0px',
	},
	row: {
		width: '348px',
		marginLeft: '8px',
		fontStyle: 'normal',
		fontWeight: 'normal',
		fontSize: '14px',
		lineHeight: '24px',
		color: 'black',
	},

	refreshButton: {
		marginLeft: '16px',
		// marginTop: "4px",
		color: '#19252E',
		backgroundColor: 'rgba(68, 187, 255, 0.56)',
		fontWeight: '550',
		width: '99px',
		height: '32px',
		textTransform: 'none',
		fontSize: '14px',
		lineHeight: '24px',
		letterSpacing: '0.35px',
		'&:hover': {
			backgroundColor: 'rgba(68, 187, 255, 0.72)',
			color: '#19252E',
		},
		'&:active': {
			backgroundColor: 'red(68, 187, 255, 0.88)',
			color: '#19252E',
		},
	},
}));

const ErrorToast = ({ open, setOpen, body, button, mainButton, ...props }) => {
	const classes = useStyles();
	return (
		<Dialog
			open={open != ''}
			onClose={() => setOpen(false)}
			aria-labelledby='alert-dialog-title'
			aria-describedby='alert-dialog-description'
			maxWidth='false'
			classes={{ paper: classes.dialog }}
			className='errorToast'
		>
			<DialogContent className={classes.dialogBody}>
				<svg
					xmlns='http://www.w3.org/2000/svg'
					width='24'
					height='24'
					viewBox='0 0 24 24'
					fill='none'
					// style={{marginTop:"5px"}}
				>
					<path
						fill-rule='evenodd'
						clip-rule='evenodd'
						d='M12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM13 13V7H11V13H13ZM13 16.9972V15H11V16.9972H13Z'
						fill='#D43A3E'
					/>
				</svg>
				<DialogContentText className={classes.row}>{body}</DialogContentText>
				<svg
					width='24'
					height='24'
					viewBox='0 0 24 24'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
					style={{ position: 'absolute', top: 'opx', right: '4px', cursor: 'pointer' }}
					onClick={() => {
						setOpen('');
					}}
				>
					<path d='M7 8.4L8.4 7L12 10.6L15.6 7L17 8.4L13.4 12L17 15.6L15.6 17L12 13.4L8.4 17L7 15.6L10.6 12L7 8.4Z' fill='black' />
				</svg>
			</DialogContent>
			<DialogActions className={classes.footer}>
				<Button
					className={classes.refreshButton}
					onClick={() => {
						mainButton.action();
						setOpen('');
					}}
				>
					{button}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default withTranslation()(ErrorToast);

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText, makeStyles, TextField, IconButton, Switch } from '@material-ui/core';
import { RemoveTransfStatus, getUsers, RemoveUpdStatus } from '../../actions/UsersActions.js';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import InfoRed from '../../assets/info-red.svg';
import Chip from '@material-ui/core/Chip';
import Unlock from '../../assets/unlock.svg';
import SelectCalendar from './SelectCalendar.js';
import '../../styles/Users.css';

const mapStateToProps = state => ({
	usersForModals: state.users.usersForModals,
	calendars_status: state.users.calendars_status,
});

const mapDispatchToProps = dispatch => ({
	getUsers: (page, search, t) => dispatch(getUsers(page, search, t)),	
	RemoveUpdStatus: () => dispatch(RemoveUpdStatus()),
});

const useStyles = makeStyles(theme => ({
	dialog: {
		backgroundColor: 'white',
		width: '500px',
		height: '446px',
		paddingTop: '0px',
		paddingLeft: '0px',
		paddingBottom: '32px',
		borderRadius: '4px',
		boxShadow: '0px 0px 12px rgba(25, 37, 46, 0.24) !important',
	},
	roundTooltip: {
		width: '32px',
		height: '32px',
		position: 'absolute',
		top: '12px',
		right: '12px',
		cursor: 'pointer',
		'&:hover': {
			backgroundColor: 'rgba(25, 37, 46, 0.08); !important',
		},
		'&:active': {
			backgroundColor: 'rgba(25, 37, 46, 0.16); !important',
		},
	},
	spinner: {
		position: 'absolute',
		left: '234px',
		top: '40%',
		color: '#FF962E !important',
	},
	dialogCont: {
		backgroundColor: 'rgba(0, 0, 0, 0.40)',
		zIndex: '4000',
	},
	footer: {
		paddingLeft: '24px',
		justifyContent: 'flex-start',
		paddingBottom: '0px',
		position: 'absolute',
		bottom: '24px',
	},
	row: {
		width: '440px',
		height: '48px',
		paddingTop: '6px',
		fontStyle: 'normal',
		fontWeight: 'normal',
		fontSize: '16px',
		lineHeight: '24px',
		color: 'black',
	},
	blockButton: {
		backgroundColor: 'rgba(68, 187, 255, 0.56) !important',
		color: '#19252E',
		width: '138px',
		height: '32px',
		// marginRight: "4px",
		textTransform: 'none',
		fontWeight: '525',
		fontSize: '14px',
		lineHeight: '16px',
		letterSpacing: '0.35px',
		borderRadius: '4px',
		border: '1px solid rgba(25, 37, 46, 0.08)',
		'&:hover': {
			backgroundColor: 'rgba(68, 187, 255, 0.72) !important',
			color: '#19252E',
		},
		'&:disabled': {
			backgroundColor: 'rgba(25, 37, 46, 0.08) !important',
			color: '#A3A8AB',
			border: '1px solid rgba(25, 37, 46, 0.08)',
		},
	},
	unblockButton: {
		backgroundColor: 'rgba(68, 187, 255, 0.56) !important',
		color: '#19252E',
		width: '170px',
		height: '32px',
		// marginRight: "4px",
		textTransform: 'none',
		fontWeight: '525',
		fontSize: '14px',
		lineHeight: '16px',
		letterSpacing: '0.35px',
		borderRadius: '4px',
		border: '1px solid rgba(25, 37, 46, 0.08)',
		'&:hover': {
			backgroundColor: 'rgba(68, 187, 255, 0.72) !important',
			color: '#19252E',
		},
		'&:disabled': {
			backgroundColor: 'rgba(25, 37, 46, 0.08) !important',
			color: '#A3A8AB',
			border: '1px solid rgba(25, 37, 46, 0.08)',
		},
	},
	saveButton: {
		backgroundColor: 'rgba(68, 187, 255, 0.56) !important',
		color: '#19252E',
		fontWeight: '525',
		width: '105px',
		height: '32px',
		textTransform: 'none',
		fontSize: '14px',
		lineHeight: '16px',
		letterSpacing: '0.35px',
		border: '1px solid rgba(25, 37, 46, 0.08)',
		'&:hover': {
			backgroundColor: 'rgba(68, 187, 255, 0.72) !important',
			color: '#19252E',
		},
		'&:disabled': {
			backgroundColor: 'rgba(25, 37, 46, 0.08) !important',
			color: '#A3A8AB',
			border: '1px solid rgba(25, 37, 46, 0.08)',
		},
	},
	chipsUser: {
		fontFamily: 'PT Sans',
		fontStyle: 'normal',
		fontWeight: '400',
		fontSize: '16px',
		lineHeight: '24px',
		backgroundColor: 'red',
		color: 'rgba(22, 31, 41, 0.92)',
		marginRight: '8px',
		marginBottom: '12px',
		maxWidth: '472px',
		'text-overflow': 'ellipsis',
		overflowX: 'hidden',
		whiteSpace: 'nowrap',
		'&.MuiChip-outlined': {
			backgroundColor: 'rgba(25, 37, 46, 0.16)',
		},
	},
	dropdownStyle: {
		width: '452px',
	},
	rootSwitch: {
		width: 30,
		height: 20,
		padding: 0,
	},
	switchBase: {
		paddingLeft: '3px',
		paddingRight: '3px',
		paddingTop: '6px',
		color: '#596269',

		'&$checked': {
			transform: 'translateX(10px)',
			color: '#1486C8',
			'& + $track': {
				backgroundColor: 'rgba(68, 187, 255, 0.8)',
				border: '1px solid rgba(25, 37, 46, 0.08)',
			},
		},

		'&$focusVisible $thumb': {},
	},
	thumb: {
		width: 14,
		height: 14,
	},
	track: {
		borderRadius: 10,
		backgroundColor: 'white',
		border: '1px solid rgba(25, 37, 46, 0.4)',
		opacity: 1,
	},
	checked: {},
	focusVisible: {},
}));

const BlockUser = ({
	open,
	setOpen,
	cn,
	sn,
	blockButton,
	saveButton,
	cancelButton,
	mail,
	t,
	calendars_status,
	transf_status,
	transf_code,
	upd_status,
	upd_code,
	RemoveUpdStatus,
	removeCalendarStatus,
	updatingUser,
	calendarsFetching,
	calendarsTransfering,
	inetUserStatus,
	usersForModals,
	getUsers,
}) => {
	const dispatch = useDispatch();
	const [calendarsToTransfer, setCalendarsToTransfer] = useState([]);
	const [string, setString] = useState(false);
	const [newUser, setNewUser] = useState('');
	const [canTransf, setCanTransf] = useState(false);
	const classes = useStyles();

	useEffect(() => {
		RemoveTransfStatus();
		RemoveUpdStatus();
		setNewUser('');
		setCanTransf(false);
	}, [open]);

	const blockSubmit = () => {
		if (canTransf) {
			let replace = [];
			replace.push({ op: 'replace', path: `/new_owner`, value: `${newUser.mail}` });
			let i;
			for (i = 0; i < calendarsToTransfer.length; i++) {
				replace.push({ op: 'replace', path: `/calendar_ids`, value: calendarsToTransfer[i] });
			}
			blockButton.action(replace, `${newUser.cn} ${newUser.sn}`);
		} else blockButton.action('');
	};

	const saveSubmit = () => {
		if (canTransf) {
			let replace = [];
			replace.push({ op: 'replace', path: `/new_owner`, value: `${newUser.mail}` });
			let i;
			for (i = 0; i < calendarsToTransfer.length; i++) {
				replace.push({ op: 'replace', path: `/calendar_ids`, value: calendarsToTransfer[i] });
			}
			saveButton.action(replace, `${newUser.cn} ${newUser.sn}`);
		}
	};

	const closeButtonAction = () => {
		// removeCalendarStatus()
		setOpen('');
	};
	return (
		<Dialog open={open != ''} onClose={() => setOpen(false)} maxWidth='false' classes={{ paper: classes.dialog, container: classes.dialogCont }} className='calManager'>
			<div style={{ opacity: updatingUser ? '40%' : '100%' }}>
				<IconButton className={classes.roundTooltip} onClick={cancelButton.action}>
					<div style={{ width: '24px', height: '24px' }}>
						<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
							<path d='M5 6.4L6.4 5L12 10.6L17.6 5L19 6.4L13.4 12L19 17.6L17.6 19L12 13.4L6.4 19L5 17.6L10.6 12L5 6.4Z' fill='#19252E' />
						</svg>
					</div>
				</IconButton>
				<div
					className='serverErrorBlockUser'
					style={{
						display: upd_status === 500 || upd_status === 'unknown' ? 'flex' : 'none',
					}}
				>
					<img style={{ marginRight: '8px' }} src={InfoRed} alt={InfoRed} width='24px' height='24px' />
					{t('users_page.block_error')}
				</div>
				<DialogTitle>
					<div className='calendarsTitle'>{inetUserStatus !== '0' ? t('users_page.block_user') : t('users_page.unblock_settings')}</div>
				</DialogTitle>
				<DialogContent className={classes.dialogBody}>
					<CircularProgress className={classes.spinner} style={{ display: updatingUser ? 'block' : 'none' }} />
					<DialogContentText className='calendarsSmallTitle'>
						{inetUserStatus !== '0' ? t('users_page.block_user_info', { cn: cn, sn: sn }) : t('users_page.save_user_info')}
					</DialogContentText>					
					<div style={{ display: 'flex', alignItems: 'center', marginTop: '30px', marginBottom: '8px' }}>
						<Switch
							focusVisibleClassName={classes.focusVisible}
							disableRipple
							classes={{
								root: classes.rootSwitch,
								switchBase: classes.switchBase,
								thumb: classes.thumb,
								track: classes.track,
								checked: classes.checked,
							}}
							checked={canTransf}
							disabled={calendars_status !== 200}
							onChange={e => {
								canTransf === true ? setCanTransf(false) : setCanTransf(true);
							}}
							//   style={{display: localStorage.getItem('role') !== '3' ? "block" : "none" }}
						/>
						<div className='switchText'>{t('users_page.transfer_calendars')}</div>
					</div>
					<div className='calendarsSmallTitle' style={{ marginBottom: '16px' }}>
						{t('users_page.new_owner_info')}
					</div>
					<div style={{ display: canTransf ? 'block' : 'none' }}>
						<SelectCalendar t={t} mail={mail} open={open} calendarsToTransfer={calendarsToTransfer} setCalendarsToTransfer={v => setCalendarsToTransfer(v)} />
						<div style={{ marginTop: '16px' }}>
							<div className='fieldLabel'>{t('users_page.new_owner')}</div>
							<Autocomplete
								className='userForCalendars'
								multiple
								disabled={newUser}
								filterSelectedOptions
								id='tags-standard'
								options={usersForModals.filter(e => e.mail !== mail)}
								autoHighlight={false}
								filterOptions={(options, params) => {
									if (params.inputValue.length >= 1) {
										return options;
									}
									return [];
								}}
								noOptionsText={''}
								clearText={t('clear')}
								getOptionSelected={(option, value) => option === value}
								getOptionLabel={option => `${option.cn} ${option.sn} <${option.mail}>`}
								renderTags={(value, getTagProps) =>
									value.map((option, index) => <Chip key={index} label={`${option.cn} ${option.sn}`} {...getTagProps({ index })} disabled={false} />)
								}
								ListboxProps={{
									style: {
										maxHeight: '224px',
										//   maxWidth: "472px",
									},
								}}
								onChange={(e, value) => {
									setNewUser(value[0]);
								}}
								onClick={e => {
									e.stopPropagation();
								}}
								renderInput={params => (
									<TextField
										{...params}
										className='menuRow'
										style={{
											maxHeight: '263px',
											overflowX: 'hidden',
										}}
										disableUnderline
										onChange={e => {
											if (e.target.value) getUsers(1, e.target.value, t);
										}}
									/>
								)}
							/>
						</div>
					</div>
				</DialogContent>
				<DialogActions className={classes.footer}>
					{window.pgs_integration || (!window.pgs_integration && inetUserStatus === '0') ? (
						<Button
							// disableRipple
							disabled={!canTransf || (canTransf && !newUser) || (canTransf && calendarsToTransfer.length === 0)}
							className={classes.saveButton}
							onClick={saveSubmit}
						>
							{t('users_page.save')}
						</Button>
					) : null}
					{!window.pgs_integration ? (
						<Button
							className={inetUserStatus !== '0' ? classes.blockButton : classes.unblockButton}
							onClick={blockSubmit}
							disabled={(canTransf && !newUser) || (canTransf && calendarsToTransfer.length === 0)}
						>
							{inetUserStatus === '0' ? <img style={{ marginRight: '4px' }} src={Unlock} alt={''} /> : null}
							{inetUserStatus !== '0' ? t('users_page.block') : t('users_page.unblock')}
						</Button>
					) : null}
				</DialogActions>
			</div>
		</Dialog>
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(BlockUser));

import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import PsnCheckbox from '../../GeneralElements/PsnCheckbox';
import { withTranslation } from 'react-i18next';
import '../../../styles/Users.css';

const ColumnsSwitcher = ({ columns, switchColumn, isColumnsVisible, doRender, setDoRender, t }) => {
	const enabledColumns = localStorage.getItem('enabledColumns');
	const [renderPage, setRenderPage] = useState('false');
	const changeColumnEnabled = (header, status) => {
		if (status) {
			localStorage.setItem('enabledColumns', `${header.bdName}${header.columnOrder} ${enabledColumns ? enabledColumns : ''}`);
		} else {
			localStorage.setItem('enabledColumns', enabledColumns.replace(`${header.bdName}${header.columnOrder}`, ''));
		}		
		columns.forEach(el => {
			if (el.title === header.title) el.enabled = status;
		});
		switchColumn(columns);
		setRenderPage(!renderPage);
		setDoRender(!doRender)
	};

	const boxSX = {
		cursor: 'pointer',
		'&:hover': {
			backgroundColor: 'rgba(25, 37, 46, 0.06)',
		},
	};

	if (isColumnsVisible) {
		return (
			<div className='columnsList'>
				{columns.map((header, index) =>
					index === 0 || index === 2 || index === 3 || !header.bdName ? null : (
						<Box
							height={40}
							width={234}
							display='flex'
							alignItems='center'
							onClick={() => {
								if (header.enabled) {
									changeColumnEnabled(header, false);
								} else {
									changeColumnEnabled(header, true);
								}
							}}
							sx={boxSX}
						>
							<div className='columnsList. checkBox'>
								<PsnCheckbox checked={header.enabled} />
							</div>
							<div>{header.columnOrder === "11" ? t('license_agreement') : header.title}</div>
						</Box>
					)
				)}
			</div>
		);
	}
	return null;
};

export default withTranslation()(ColumnsSwitcher);

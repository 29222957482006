import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { connect } from 'react-redux';
import { makeStyles, Select, MenuItem } from '@material-ui/core';
import { getCalendars, RemoveTransfStatus, getUsers } from '../../actions/UsersActions.js';
import '../../styles/Users.css';
import PsnCheckbox from './../GeneralElements/PsnCheckbox';
import { withTranslation } from 'react-i18next';
import '../../styles/Users.css';

const mapStateToProps = state => ({
	calendars: state.users.calendars,
});

const mapDispatchToProps = dispatch => ({
	getCalendars: (mail, t) => dispatch(getCalendars(mail, t)),
});

const useStyles = makeStyles(theme => ({}));

const SelectCalendar = ({ mail, calendarsToTransfer, setCalendarsToTransfer, open, calendars, getCalendars, t }) => {
	const dispatch = useDispatch();
	const [calendArrToDisplay, setCalendArrToDisplay] = useState([]);
	const [arrOfNames, setArrOfNames] = useState([]);
	const [calendArr, setCalendArr] = useState([]);
	const classes = useStyles();

	useEffect(() => {
		RemoveTransfStatus();
		// if (navigator.onLine) getCalendars(mail, t);
		if (calendars) {
			extractingUri(calendars);
			setCalendArrToDisplay([t('users_page.all')]);
		}
	}, [open]);

	const extractingUri = calendars => {
		let arr = [];
		let arrChecked = [];
		let arrNames = [];
		calendars.map(el => arr.push(el.calendarUri));
		setCalendarsToTransfer(arr);
		arrChecked = calendars.map(el => {
			el.checked = true;
			return el;
		});
		setCalendArr([{ displayname: t('users_page.all'), checked: true }].concat(arrChecked));
		arrNames = calendars.map(el => el.displayname + ', ');
		setArrOfNames(arrNames);
	};

	const handleCheck = (e, option) => {
		e.stopPropagation();
		if (option.displayname === t('users_page.all')) {
			if (option.checked) {
				setCalendArrToDisplay([]);
				setCalendarsToTransfer([]);
				calendArr.forEach(el => {
					el.checked = false;
				});
				return;
			}
			if (!option.checked) {
				setCalendArrToDisplay([t('users_page.all')]);
				extractingUri(calendars);
				calendArr.forEach(el => {
					el.checked = true;
				});
				return;
			}
		}
		if (option.displayname !== t('users_page.all') && !calendArrToDisplay.includes(t('users_page.all'))) {
			if (!option.checked) {
				setCalendarsToTransfer([...calendarsToTransfer, option.calendarUri]);				
				setCalendArrToDisplay([...calendArrToDisplay, option.displayname + ', ']);
				if (calendArrToDisplay.length + 1 === calendars.length) {
					setCalendArrToDisplay([t('users_page.all')]);
				}
			} else {
				setCalendArrToDisplay(calendArrToDisplay.filter(val => !val.includes(option.displayname)));
				setCalendarsToTransfer(calendarsToTransfer.filter(val => val !== option.calendarUri));
			}
			option.checked = !option.checked;
		}
		if (option.displayname !== t('users_page.all') && calendArrToDisplay.includes(t('users_page.all'))) {
			calendArr.forEach(el => {
				if (el.calendarUri === option.calendarUri || el.displayname === t('users_page.all')) {
					el.checked = false;
				}
			});
			setCalendArrToDisplay(arrOfNames.filter(el => el !== option.displayname + ', '));
			setCalendarsToTransfer(calendarsToTransfer.filter(val => val !== option.calendarUri));
		}
	};

	let selectCalendar = calendArr.map((option, key) => {
		return (
			<MenuItem
				className='menu-item'
				classes={{
					root: 'calendar_select',
					//  selected: selectMenuItemClasses,
				}}
				key={'-1' + key}
				value={option.displayname}
			>
				<div
					style={{ display: 'flex', width: '100%' }}
					onClick={e => {
						handleCheck(e, option);
					}}
				>
					<div
						style={{
							width: '17px',
							height: '17px',
							marginTop: '3px',
						}}
					>
						<PsnCheckbox checked={option.checked || calendarsToTransfer.length === calendars.length} />
					</div>
					<div className='selectCalendarName'>{option.displayname}</div>
				</div>
			</MenuItem>
		);
	});

	return (
		<>
			<Select
				value={calendArrToDisplay}
				className='selectCalendField'
				select
				multiple
				onChange={e => e.stopPropagation()}
				renderValue={option => <span className='dropdown-title'>{option}</span>}
				style={{ marginBottom: '8px', height: '32px', marginTop: '8px' }}
				MenuProps={{
					classes: { paper: classes.dropdownStyle },
					getContentAnchorEl: null,
					anchorOrigin: {
						vertical: 'bottom',
						horizontal: 'left',
					},
					transformOrigin: {
						vertical: 'top',
						horizontal: 'left',
					},
					PaperProps: {
						style: {
							maxHeight: '288px',
						},
					},
				}}
			>
				{selectCalendar}
			</Select>
		</>
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SelectCalendar));

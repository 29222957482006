import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { createHashHistory } from "history";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import {
  Button,
  TextField,
  CircularProgress,
  withStyles,
  MenuItem,
  Chip,
  IconButton,
  Radio, RadioGroup, FormControlLabel, FormControl
} from "@material-ui/core";
import PsnCheckbox from "./../GeneralElements/PsnCheckbox";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  getMaillists,
  getSelectedMaillist,
  updateMaillist,
  deleteMaillist,
  removeUpdStatus
} from "../../actions/MaillistsActions";
import { getUsers} from "../../actions/UsersActions";
import { getDomains } from "../../actions/DomainsActions";
import "../../styles/Maillists.css";
import { parser } from "../../allFunctions/parser.js";
import { mailParser } from "../../allFunctions/parser.js";
import Pencil from "../../assets/pencil.svg";
import CopyIcon from "../../assets/copyIcon.svg";
import DarkTooltip from "../../components/GeneralElements/DarkTooltip"
import { showAlertAction } from "../../actions/AlertsActions";
import StaticFields from './Subs/StaticFields.js';
import UserFields from './Subs/UsersField.js';
import DeleteGroup from '../../components/Users/DeleteAlias.js';
import Filters from './Subs/Filters.js';
import { BorderColor } from "@material-ui/icons";

const mapStateToProps = (state) => ({
  maillistsFetching: state.maillists.maillistsFetching,
  maillists: state.maillists.maillists,
  usersFetching: state.users.usersFetching,
  usersForModals: state.users.usersForModals, 
  updatingMaillist: state.maillists.updatingMaillist,
  deletingMaillist: state.maillists.deletingMaillist,
  domains: state.domains.domains,
  domainsFetching: state.domains.domainsFetching,
  upd_status: state.maillists.upd_status,
  selectedMaillist: state.maillists.selectedMaillist,
});

const mapDispatchToProps = (dispatch) => ({
  showAlert: (message, type) => dispatch(showAlertAction(message, type)),
  getMaillists: (t) => dispatch(getMaillists(t)),
  getSelectedMaillist: (displayName) =>
    dispatch(getSelectedMaillist(displayName)),
  getUsers: (page, search, t) => dispatch(getUsers(page, search, t)),
  updateMaillist: (mail, newMail, displayName, add, replace, remove, t) =>
    dispatch(updateMaillist(mail, newMail, displayName, add, replace, remove, t)),
  deleteMaillist: (mail, alias, t) => dispatch(deleteMaillist(mail, alias, t)),
  getDomains: () => dispatch(getDomains()),
  removeUpdStatus: () => dispatch(removeUpdStatus())
});

const styles = (theme) => ({
  '@global': {
    '*::-webkit-scrollbar': {
      width: '4px'      
    },
    '*::-webkit-scrollbar-track': {
      // backgroundColor: "red",
      // width:"4px"
    },
    '*::-webkit-scrollbar-thumb': {
      backgroundColor: "rgba(25, 37, 46, 0.16)",
      // width:"14px",
      // paddingTop: "28px",
      borderRadius: "2px",      
    }
  },  
  root: {
    width: "100%",
    height: "100%",
    backgroundColor: "white",
    minHeight: "100vh",
    boxShadow:
      "0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)",
  },
  infoForm: {
    height: "100%",
    marginBottom: "10px",
    padding: "10px",
    maxWidth: "850px",
    "& > *": {
      margin: "10px",
    },
  },
  iconButton: {
    width: "32px",
    height: "32px",
      '&:hover': {
        backgroundColor: "rgba(25, 37, 46, 0.08)",
        
      }
    
  },
  mainButton: {
    backgroundColor: "rgba(68, 187, 255, 0.56) !important",
    color: "rgba(25, 37, 46, 1)",
    width: "98px",
    height: "32px",
    marginRight: "4px",
    textTransform: "none",
    fontWeight: "525",
    fontSize: "14px",
    lineHeight: "16px",
    letterSpacing: "0.35px",
    border: "1px solid rgba(25, 37, 46, 0.08)",
    "&:hover": {
      backgroundColor: "rgba(68, 187, 255, 0.72) !important",
      color: "black",
      border: "1px solid rgba(25, 37, 46, 0.08)",
    },
    "&:disabled": {
      backgroundColor: "rgba(25, 37, 46, 0.08) !important",
      color: "rgba(163, 168, 171, 1)",
      border: "1px solid rgba(25, 37, 46, 0.08)",
    },
  },
  cancelButton: {
    color: "black",
    fontWeight: "525",
    width: "98px",
    height: "32px",
    textTransform: "none",
    fontSize: "14px",
    lineHeight: "16px",
    letterSpacing: "0.35px",
    border: "1px solid #C0C0C0",
    "&:hover": {
      color: "black",
      backgroundColor: "rgba(25, 37, 46, 0.08) !important",
    },
  },
});

class MaillistForm extends React.Component {
  // static propTypes = {
  //   match: PropTypes.object.isRequired,
  //   location: PropTypes.object.isRequired,
  //   history: PropTypes.object.isRequired,
  // };
  constructor(props) {
    super(props);
    this.history = createHashHistory();
    this.state = {
      displayName: "",
      newmail: "",
      alias: [],
      maillistGroupName: "",
      maillistDomainEmail: "",
      maillistAlias: [],
      maillistAliasPrev: [],
      aliasToDel: [],
      mail: "",
      mailPrev: "",
      domain: "",       
      edit: false,     
      editingGroup: true,
      errorMail: false,
      emailFieldError: false,
      noChangeField: false,
      noOpen: true,
      trashWindow: false,
      typeMailList: '',
      description: '',
      filterInfo: [],
      filterInfoPrev: [],
      generationQualifier: '',		
      uniqNum: 0,
      disabled: false
    };   
    this.filterInfoRec = [];
    this.RadioIcon = (
			<svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
				<rect x='1' y='1' width='16' height='16' rx='8' stroke='#19252E' strokeOpacity='0.56' strokeWidth='2' />
			</svg>
		);
		this.RadioIconChecked = (
			<svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
				<rect width='18' height='18' rx='9' fill='#44BBFF' fillOpacity='0.56' />
				<circle cx='9' cy='9' r='4' fill='#2B363F' />
				<rect x='0.5' y='0.5' width='17' height='17' rx='8.5' stroke='#19252E' strokeOpacity='0.08' />
			</svg>
		);
  }
  componentDidMount(t) {    
    this.props.getUsers(1, t);
    let maillistMail = this.props.match.params.maillistMail;
    this.setState({ mail: maillistMail });
    this.props.getDomains();
    this.props.maillists
      .filter((index) => index.mail === maillistMail) 
      .map((item) => {        
        return this.setState({
          maillistGroupName: item.displayName,
          maillistDomainEmail: item.mail.split("@")[0],
          domain: item.mail.split("@")[1],
          maillistAlias: item.alias,         
          maillistGroupNamePrev: item.displayName,
          maillistDomainEmailPrev: item.mail.split("@")[0],
          domainPrev: item.mail.split("@")[1],  
          maillistAliasPrev: item.alias,   
          typeMailList: item.typeMailList,
          generationQualifier: item.generationQualifier,
          filterInfo: item.filterInfo ?
              item.filterInfo.map((obj, index) => ({
                ...obj,
                id: index,
                noTypeChange: true,
                isValid: true,
              })) : [], 
            filterInfoPrev: item.filterInfo ?
              item.filterInfo.map((obj, index) => ({
                ...obj,
                id: index,
                noTypeChange: true,
                isValid: true,
              })) : [],   
          generationQualifier: item.generationQualifier,                
        });        
      });
  }
 
  componentDidUpdate(prevProps, prevState) {    
    if ((this.props !== prevProps) &&
      this.props.usersForModals === prevProps.usersForModals &&
      !this.state.noChangeField) {         
      this.props.maillists
        
        .filter((index) => index.mail === this.props.match.params.maillistMail)
        .map((item) => {         
          return this.setState({
            mailPrev: this.props.match.params.maillistMail,
            mail: this.props.match.params.maillistMail,
            maillistGroupName: item.displayName,
            maillistDomainEmail: item.mail.split("@")[0],
            domain: item.mail.split("@")[1],
            maillistAlias: item.alias,
            maillistGroupNamePrev: item.displayName,
            maillistDomainEmailPrev: item.mail.split("@")[0],
            domainPrev: item.mail.split("@")[1],
            maillistAliasPrev: item.alias,            
            errorMail: false,
            typeMailList: item.typeMailList,
            filterInfo: item.filterInfo ?
            item.filterInfo.map((obj, index) => ({
              ...obj,
              id: index,
              noTypeChange: true,
              isValid: true,
            })) : [],   
            filterInfoPrev: item.filterInfo ?
            item.filterInfo.map((obj, index) => ({
              ...obj,
              id: index,
              noTypeChange: true,
              isValid: true,
            })) : [], 
            generationQualifier: item.generationQualifier,          
          });
        });
      this.props.removeUpdStatus();
      if (`${this.state.maillistDomainEmail}@${this.state.domain}` !== `${prevState.maillistDomainEmail}@${this.state.domain}`) {
        this.setState({ edit: false });
      }
    }    
  }

  handleCancel = (e) => {
    e.preventDefault();
    this.history.push("/maillists/");
  }

  handleMaillistDelete = (e, t) => {
		e.preventDefault();
		this.setState({ trashWindow: false });
    let mail = this.state.maillistDomainEmail + '@' + this.state.domain
		this.props.deleteMaillist(mail, t);
  }

  checkIfDataChanged = () => {
    if (this.state.typeMailList === 'dynamic') {
      let isChanged = false
      if (this.state.filterInfo.length !== this.state.filterInfoPrev.length) isChanged = true
      if (this.state.filterInfo.length === this.state.filterInfoPrev.length) {
        for (let i = 0; i < this.state.filterInfo.length; i++) {
          if (this.state.filterInfo[i].value !== this.state.filterInfoPrev[i].value) isChanged = true
        }
      }
      return isChanged
    }   
  }

  compareToUpd = async(e, t) => {
    e.preventDefault();
    this.setState({ noChangeField: true })
    const recData = {}
    const add = [];
		const replace = [];
    const remove = [];
    this.props.maillists
      .filter((index) => index.mail === this.props.match.params.maillistMail)
      .map((item) => {
        recData.mail = item.mail;
        recData.displayName = item.displayName; 
        if (this.state.typeMailList === 'static') {
          recData.alias = item.alias
        }
        if (this.state.typeMailList === 'dynamic') {      
          recData.filterInfo = item.filterInfo.map((obj, index) => ({
            ...obj,
            id: index 
          })) 
          recData.generationQualifier = item.generationQualifier           
        }
      });

    let curData = {};
		curData.mail = this.state.maillistDomainEmail + '@' + this.state.domain;
		curData.displayName = this.state.maillistGroupName;		
    if (this.state.typeMailList === 'static') {
      curData.alias = this.state.maillistAlias; 
    }	
    if (this.state.typeMailList === 'dynamic') {
      curData.filterInfo = this.state.filterInfo
      curData.generationQualifier = this.state.generationQualifier   
    }      
  
  //  ------------------FOR STATIC GROUPS-------------------------
    if (this.state.typeMailList === 'static') {
      for (let j = 0; j < curData.alias.length; j++) {
        if (!recData.alias.includes(curData.alias[j])) {
          let dataObj = {};
          dataObj[`/alias`] = curData.alias[j];
          add.push(dataObj);
        }
    } 
    for (let i = 0; i < recData.alias.length; i++) {
        if (!curData.alias.includes(recData.alias[i])) {         
          remove.push(`/alias/${recData.alias[i]}`);
        }
      } 
    }

 //  ------------------FOR DYNAMIC GROUPS-------------------------
    if (this.state.typeMailList === 'dynamic') {
      const curDataFilersIds = curData.filterInfo.map(obj => (obj.id))
      const recDataFilersIds = recData.filterInfo.map(obj => (obj.id))    
      for (let j = 0; j < curDataFilersIds.length; j++) {
        if (!recDataFilersIds.includes(curDataFilersIds[j])) {
          let dataObj = {};
          dataObj[`/filterInfo`] = `{'type':'${curData.filterInfo[j].type}','value':'${curData.filterInfo[j].value}'}`
          add.push(dataObj);         
        }
        if (recDataFilersIds.includes(curDataFilersIds[j]) && curData.filterInfo[j].id === recData.filterInfo[j].id
          && curData.filterInfo[j].value !== recData.filterInfo[j].value) {
          let dataObj = {};
          let encoded = encodeURI(recData.filterInfo[j].value)
          dataObj[`/filterInfo/${curData.filterInfo[j].type}/${encoded}`] = `${curData.filterInfo[j].value}`
          replace.push(dataObj);         
        }
      }
      for (let i = 0; i < recDataFilersIds.length; i++) {
        if (!curDataFilersIds.includes(recDataFilersIds[i]) && recData.filterInfo[i].type !== 'custom') {
          let encoded = encodeURI(recData.filterInfo[i].value)
          remove.push(`/filterInfo/${recData.filterInfo[i].type}/${encoded}`);          
        }
        if (!curDataFilersIds.includes(recDataFilersIds[i])&& recData.filterInfo[i].type === 'custom') {
          remove.push(`/filterInfo/custom`);          
        }
      }      
      if (curData.generationQualifier !== recData.generationQualifier) {
        let dataObj = {};
        dataObj[`/generationQualifier`] = `${curData.generationQualifier}`
        replace.push(dataObj);       
      }
    }
//  ------------------FOR ALL GROUPS------------------------------   
			for (let key2 in curData) {
				for (let key1 in recData) {
          if (key1 === key2 && !key1.includes('alias') && !key2.includes('alias')
            && !key1.includes('filterInfo') && !key2.includes('filterInfo')
            && !key1.includes('generationQualifier') && !key2.includes('generationQualifier')
          ) {           	            
              if (recData[key1] && curData[key2] && recData[key1] !== curData[key2] ) {
                let dataObj = {};
                dataObj[`/${key2}`] = `${curData[key2]}`;
                replace.push(dataObj);
              }  
            if (!recData[key1] && curData[key2] && recData[key1] !== curData[key2]) {
							let dataObj = {};
							dataObj[`/${key2}`] = `${curData[key2]}`;
              add.push(dataObj);             
            }
            if (recData[key1] && !curData[key2] && recData[key1] !== curData[key2]) {
							let dataObj = {};
							dataObj[`/${key1}`] = `${recData[key1]}`;             
              remove.push(`/${key1}`);
						}
          }          
				}
    }   
   
     await this.props.updateMaillist(
        this.state.mail, 
        (curData.mail ? curData.mail : recData.mail),
        (curData.displayName ),
        add, replace, remove,
        t
       );   
    this.setState({ noChangeField: false })    
  }

  render() {
    const { classes, t } = this.props;
    return this.props.maillistsFetching ? (
			<div className={classes.root}>
				<div className='loading'>
					<CircularProgress />
				</div>
			</div>
		) : (
			<div className='root'>
        <form className='infoForm' noValidate autoComplete='off'>
            <div style={{display: 'flex', width: "817px"}}>
            <div className='newListTitle' style={{ marginBottom: '24px' }}>
					    {t('mailLists.edit_maiList')}
            </div> 
              <div style={{ display: 'flex', marginLeft: 'auto'}}>
              <div
                  className='delButton'
                  onClick={() => this.setState({ trashWindow: true }) }
							>
										<div className='blockButtonText'>{t('users_page.delete')}</div>
									</div>
              </div>
          </div>
					           
            <StaticFields
						  t={t}
						  nameChange={v => this.setState({ maillistGroupName: v })}
						  emailChange={v => this.setState({ maillistDomainEmail: v })}
						  domainChange={v => this.setState({ domain: v })}
						  typeChange={v => this.setState({ typeMailList: v })}
						  maillistEmail={this.state.maillistDomainEmail}
						  maillistName={this.state.maillistGroupName}
						  domain={this.state.domain}
						  typeMailList={this.state.typeMailList}
						  domains={this.props.domains}
						  upd_status={this.props.upd_status}
						  emailFieldError={this.state.emailFieldError}
              setEmailFieldError={v => this.setState({ emailFieldError: v })}
              editingGroup={this.state.editingGroup}              
					  />
					<div style={{ flexDirection: 'column' }}>
						<div className='membersTitle' style={{ display: 'flex', alignItems: 'center' }}>
							{t('mailLists.members')}
              <div style={{ display: this.state.typeMailList === 'static' ? 'block' : 'none' }}>
							<DarkTooltip title={<>{t('mailLists.edit')}</>}>
								<IconButton className={classes.iconButton} style={{ marginLeft: '24px' }} onClick={() => this.setState({ edit: !this.state.edit })}>
									<img className='trashImgHover' src={Pencil} alt={Pencil} />
								</IconButton>
							</DarkTooltip>
              </div>
							<div style={{ marginLeft: '4px', display: this.state.typeMailList === 'static' ? 'block' : 'none'}}>
								<DarkTooltip
									title={<>{t('mailLists.email_copy_tooltip')}</>}									
								>
									<IconButton
										aria-label='Remove'
										onClick={e => {
											if (this.state.maillistAlias) {
                        let toCopy = this.state.maillistAlias.join(' ');                        
												navigator.clipboard.writeText(toCopy);
											}
											this.props.showAlert(t('mailLists.emails_copied'), 'admin_toast_success');
										}}
										className={classes.iconButton}
									>
										<img src={CopyIcon} alt={CopyIcon} />
									</IconButton>
								</DarkTooltip>
							</div>
              </div>
            <div style={{ display: this.state.typeMailList === 'static' ? 'block' : 'none' }}>  
            <UserFields
							users={this.props.usersForModals}
							getUsers={v => this.props.getUsers(1, v, t)}
							maillistAlias={this.state.maillistAlias? this.state.maillistAlias : ''}
              setMaillistAlias={v => this.setState({ maillistAlias: v })}              
              edit={this.state.edit}
                />
                </div>
            	<div style={{ display: this.state.typeMailList === 'dynamic' ? '' : 'none', flexDirection: 'column' }}>
						<div className='dynamicInfo' style={{ marginTop: '6px', marginBottom: '24px' }}>
							{t('mailLists.dynamic_info')}
						</div>
						<div className='titleSmall' style={{ marginBottom: '16px' }}>
							{t('mailLists.add_to_group_if')}
						</div>
						<div style={{ display: this.state.filterInfo.length > 1 ? '' : 'none'}}>
							<FormControl className='radioRoot'>
								<RadioGroup row value={this.state.generationQualifier} onChange={e => this.setState({ generationQualifier: e.target.value })}>
									<FormControlLabel
										value='OR'
										control={<Radio icon={this.RadioIcon} checkedIcon={this.RadioIconChecked} />}
										label={t('mailLists.one_condition')}
										className='custom-label'
									/>
									<FormControlLabel
										value='AND'
										control={<Radio icon={this.RadioIcon} checkedIcon={this.RadioIconChecked} />}
										label={t('mailLists.all_conditions')}
										style={{ marginLeft: '0px' }}
										className='custom-label'
									/>
								</RadioGroup>
							</FormControl>
						</div>
                <Filters
                  filters={this.state.filterInfo ? this.state.filterInfo : []}                 
                  setFilters={v => this.setState({filterInfo: v})}
                  editingGroup={this.state.editingGroup}
                  domains={this.props.domains}                 
                />
					</div>
            
            <DeleteGroup
							open={this.state.trashWindow}
							setOpen={v => this.setState({ trashWindow: v })}
							title={t('mailLists.delete_distrGr')}
							body={t('mailLists.delete_distrGr_info')}
							t={t}
							mainButton={{
								action: e => {
									this.handleMaillistDelete(e);
									this.setState({ trashWindow: false });
								},
							}}
						/>
					</div>					
				</form>
				<div className="maillistButtons">
					<Button
						disabled={
							(this.state.maillistGroupName === this.state.maillistGroupNamePrev) &&
								(this.state.maillistDomainEmail === this.state.maillistDomainEmailPrev) &&
                (this.state.domain === this.state.domainPrev) &&
                (
                  (this.state.typeMailList === 'static' && this.state.maillistAlias.toString() == this.state.maillistAliasPrev.toString()) ||
                  this.state.typeMailList === 'static' && this.state.maillistAlias.length === 0 ||
                  (this.state.typeMailList === 'dynamic' && this.state.filterInfo.length === 0) ||                 
                  !this.state.maillistGroupName ||
                  this.state.maillistGroupName.length > 255 ||
                  !this.state.maillistDomainEmail ||
                  !this.state.domain ||
                  this.state.emailFieldError ||
                  this.state.filterInfo.find(el => !el.isValid) ||
                  this.state.filterInfo.find(el => !el.value) ||
                  (this.state.typeMailList === 'dynamic' && !this.checkIfDataChanged())                  
                )					
              }
						className={classes.mainButton}
						type='submit'
						onClick={(e) => {
							this.compareToUpd(e, t);
						}}
					>
						{t('save')}
					</Button>
					<Button className={classes.cancelButton} onClick={this.handleCancel} style={{ marginLeft: '12px' }}>
						{t('cancel')}
					</Button>
				</div>
			</div>
		);
  }
}

export default withRouter(
  withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(MaillistForm)))
);

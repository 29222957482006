import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { createHashHistory } from 'history';
import { withTranslation } from 'react-i18next';
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	Toolbar,	
	Button,	
	CircularProgress,
	Slide,
	withStyles,
} from '@material-ui/core';
import { getMaillists, cleanSelectedMaillist, getSelectedMaillist } from '../../actions/MaillistsActions';
import Plus from '../../assets/tenants_plus.svg';
import '../../styles/Maillists.css';
import { getUsers } from '../../actions/UsersActions';
import { getDomains } from '../../actions/DomainsActions';

const mapStateToProps = state => ({
	maillistsFetching: state.maillists.maillistsFetching,
	maillists: state.maillists.maillists,
	selectedMaillist: state.maillists.selectedMaillist,
	usersFetching: state.users.usersFetching,
	users: state.users.users,
});

const mapDispatchToProps = dispatch => ({
	getMaillists: t => dispatch(getMaillists(t)),
	cleanSelectedMaillist: () => dispatch(cleanSelectedMaillist()),	
	getSelectedMaillist: displayName => dispatch(getSelectedMaillist(displayName)),
	getUsers: (page, search, t) => dispatch(getUsers(page, search, t)),
	getDomains: () => dispatch(getDomains()),
});

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction='up' ref={ref} {...props} />;
});

const styles = theme => ({
	container: {
		maxHeight: 440,
	},	
	newGroupButton: {
		color: '#19252E',
		backgroundColor: 'rgba(68, 187, 255, 0.56)',
		fontWeight: '500',
		width: '223px',
		height: '32px',
		textTransform: 'none',
		fontSize: '14px',
		lineHeight: '24px',
		letterSpacing: '0.35px',
		'&:hover': {
			backgroundColor: 'rgba(68, 187, 255, 0.72)',
			color: '#19252E',
		},
		'&:active': {
			backgroundColor: 'red(68, 187, 255, 0.88)',
			color: '#19252E',
		},
	},
});

class Maillists extends React.Component {
	constructor(props) {
		super(props);
		this.state = {			
			selectedMaillistDisplayName: '',
			selectedMaillistEmail: '',
		};
		this.history = createHashHistory();
		this.handleNewMaillistClick = this.handleNewMaillistClick.bind(this);
		this.selectMaillist = this.selectMaillist.bind(this);
		this.handleMaillistEdit = this.handleMaillistEdit.bind(this);
	}
	componentDidMount(t) {
		this.props.getMaillists(t);
		// this.props.cleanSelectedMaillist();
		this.props.getUsers(1, t);
	}

	handleNewMaillistClick(e) {
		e.preventDefault();
		this.props.cleanSelectedMaillist();
		this.history.push('/maillists/maillist/new');
	}
	selectMaillist = async (e, name, mail) => {
		this.props.getSelectedMaillist(name);
		e.preventDefault();
		this.setState({
			selectedMaillistDisplayName: name,
			selectedMaillistEmail: mail,
		});
		await this.props.getDomains();
		this.history.push(`/maillists/${mail}`);
	};
	handleMaillistEdit(e, mail) {
		e.preventDefault();
		this.props.history.push(`/maillists/${mail}`);
	}
	
	render() {
		const { t } = this.props;
		return this.props.maillistsFetching ? (
			<div className='maillistsLoading'>
				<CircularProgress />
			</div>
		) : (
			<div className='maillistTableMain'>
				<Toolbar
					// style={{ minHeight: "55px", height: "3vh" }}
					className='toolbar'
				>
					<Button className={this.props.classes.newGroupButton} color='default' onClick={this.handleNewMaillistClick}>
						<img width='24px' height='24px' src={Plus} alt={''} style={{ marginRight: '4px' }} />
						{t('new_mailing_group')}
					</Button>
				</Toolbar>

				<TableContainer className='maillistsMain'component={Paper}>
					<Table aria-label='sticky table'>
						<TableHead>
							<TableRow>
								<TableCell className='mailListHeader' align='left' colSpan='2'>
									{t('name')}
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{this.props.maillists.length > 0
								? this.props.maillists.map((row, index) => (
										<TableRow selected={this.props.selectedMaillist === row.displayName} key={index} className='mailListsRow'>
											<TableCell
												className='maillistCells'
												align='left'
												onClick={event => {
													this.handleMaillistEdit(event, row.mail);
													this.selectMaillist(event, row.displayName, row.mail);
												}}
											>
												{row.displayName}
											</TableCell>											
										</TableRow>
								  ))
								: null}
						</TableBody>
					</Table>
				</TableContainer>			
			</div>
		);
	}
}

export default withRouter(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Maillists))));

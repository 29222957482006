import React from 'react';
import { LinearProgress } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import '../../../styles/Users.css';

const QuotaBar = ({ quota, t, ...props }) => {
	const quotaSizeLimit = () => {
		if (isNaN(quota)) return 0;
		else return `${parseFloat((quota / 1048576).toFixed(2))}`;
	};

	return (
		<div>
			<div className='linearQuotaBarText'>
				{quotaSizeLimit()}
				{' MB'}
			</div>
		</div>
	);
};

export default withTranslation()(QuotaBar);

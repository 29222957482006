import React, { useState, useEffect } from "react";
import { getSelectedTenantMenu, cleanSelectedTenantMenu } from "../../actions/LoginActions";
import { useDispatch } from "react-redux";
import { NavLink as RouterLink } from "react-router-dom";
import { ListItem, ListItemIcon, ListItemText,  List, Collapse, IconButton} from "@material-ui/core";
import PropTypes from "prop-types";
import "../../styles/Sidebar.css";
import SidebarLink from "./SidebarLink";
import Up from "../../assets/up.svg";
import { withTranslation } from "react-i18next";
import DarkTooltip from "../GeneralElements/DarkTooltip"

const TenantLine = ({ t, ...props }) => {
  const { icon, primary, to, selectedTenantMenuItem, selectedTenantMenuTenant, tenant, tenantName } = props;
  const [open, setOpen] = useState(localStorage.getItem(`${tenant[0]}`) || localStorage.getItem(`${tenant[0]}`) !== null ? true : false)
  const dispatch = useDispatch()
  const getSidebarItems = t => ([
    {
      title: t("domains_page.mail_domains"),
      path: `/domains`,      
      // icon: <img src={UsersIcon} alt={UsersIcon} />,
    },
    {
      title: t("users"),
      path: "/users",
      // icon: <img src={UsersIcon} alt={UsersIcon} />,
    },
    {
      title: t("resource_groups"),
      path: "/resource_groups",
      // icon: <img src={ResourceGroupsIcon} alt={ResourceGroupsIcon} />,
    },
    {
      title: t("mailLists.mailGroups"),
      path: "/maillists",
      // icon: <img src={MaillistsIcon} alt={MaillistsIcon} />,
    },
  ]);
  
  const handleArrow = () => {
    setOpen(!open);
    if (!open) {      
      localStorage.setItem(`${tenant[0]}`, tenant[0])
    } 
    else localStorage.removeItem(`${tenant[0]}`)   
  };
  return (
		<div>
			<DarkTooltip title={<>{tenantName ? tenantName : tenant}</>}>
				<div className='tenantRow'>
					<div className='tenantName' style={{ marginRight: '16px' }}>
						{tenantName ? tenantName : tenant}
					</div>
					<IconButton className='icon-button' onClick={handleArrow}>
						<img className={open ? 'upArrow' : 'upArrow-rotate'} src={Up} alt={Up} width='24px' />
					</IconButton>
				</div>
			</DarkTooltip>
			<Collapse in={open} timeout='auto' unmountOnExit>
				<List component='div' disablePadding>
					{getSidebarItems(t).map((sidebarItem, index) => (
						<SidebarLink
							to={sidebarItem.path}
							primary={sidebarItem.title}
							selectedTenantMenuItem={selectedTenantMenuItem}
							selectedTenantMenuTenant={selectedTenantMenuTenant}
							onClick={() => dispatch(getSelectedTenantMenu(localStorage.getItem('tenant'), sidebarItem.title))}
							tenant={tenant[0]}
							t={t}
						/>
					))}
				</List>
			</Collapse>
		</div>
	);
};

SidebarLink.propTypes = {
  icon: PropTypes.element,
  primary: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};

export default withTranslation()(TenantLine);
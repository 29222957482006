import {
	BEGIN_MAILLISTS_FETCH,
	SUCCESS_MAILLISTS_FETCH,
	ADDING_NEW_MAILLIST,
	ADDED_NEW_MAILLIST,
	CLEAN_SELECTED_MAILLIST,
	DELETED_MAILLIST,
	DELETING_MAILLIST,
	SELECTED_MAILLIST,
	UPDATED_MAILLIST,
	UPDATING_MAILLIST,
	STOP_LOADING,
} from '../constants/MaillistsConstants';
import { SUCCESS_USERS_FETCH } from '../constants/UsersConstants';
import axios from 'axios';
import { Config } from '../configs';
import { createHashHistory } from 'history';
import { showAlertAction } from './AlertsActions';
import { validate } from '../components/Validations/EmailValidator';

export const getMaillists = t => {
	return function (dispatch) {
		dispatch(beginMaillistsFetch());
		return axios
			.get(Config.maillistsUrl())
			.then(response => {
				if (parseInt(response.status / 200) === 1) {
					dispatch(successMaillistsFetch(response.data.results));
				}
			})
			.catch(response => {
				if (response.status === 404) {
					dispatch(successMaillistsFetch([]));
				} else {
					// dispatch(showAlertAction(t('mailLists.error_group_getting'), 'error'));
					return dispatch({ type: STOP_LOADING });
				}
			});
	};
};

export const beginMaillistsFetch = displayName => ({
	type: BEGIN_MAILLISTS_FETCH,
	payload: { maillistsFetching: true },
});

export const getSelectedMaillist = displayName => {
	return {
		type: SELECTED_MAILLIST,
		payload: displayName,
	};
};

export const successMaillistsFetch = maillists => ({
	type: SUCCESS_MAILLISTS_FETCH,
	payload: { maillistsFetching: false, maillists },
});

export const addNewMaillist = (mail, alias, displayName, typeMailList, t, generationQualifier, filters) => {
	return function (dispatch) {
		dispatch(addingNewMaillist());
		const formData = new FormData();
		formData.append('tenant-id', localStorage.getItem('tenant'));
		formData.append('mail', mail);
		if (typeMailList === 'static') {
			let preAliases = alias.map(al => `"${al}"`);
			let aliases = `[ ${preAliases.join(',')} ]`;
			formData.set('alias', aliases);
		}
		if (typeMailList === 'dynamic') {
			let filterPars = `[${filters.join(',')}]`;			
			formData.append('filterInfo', filterPars);
			formData.append('generationQualifier', generationQualifier);
		}
		formData.append('displayName', displayName);
		if (
			!validate(`${mail}`) ||
			mail.match(/(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/g)
		) {
			dispatch(showAlertAction(t('mailLists.invalid_mail_value'), 'error'));
			return;
		}
		if (`${mail}`.length > 255) {
			dispatch(showAlertAction(t('mailLists.invalid_mail_limit'), 'error'));
			return;
		}

		return axios
			.put(Config.newMaillistsUrl(mail), formData)
			.then(response => {
				if (parseInt(response.status / 200) === 1) {
					dispatch(addedNewMaillist());
					dispatch(getMaillists(t));
					createHashHistory().push(`/maillists/${mail}`);
				}
			})
			.catch(response => {
				if (response.status === 400) {
					dispatch(showAlertAction(t('users_page.required_params'), 'error'));
					return dispatch({ type: STOP_LOADING });
				} else if (response.status === 401) {
					dispatch(showAlertAction(t('users_page.old_token'), 'error'));
					return dispatch({ type: STOP_LOADING });
				} else if (response.status === 403) {
					dispatch(showAlertAction(t('users_page.no_permission'), 'error'));
					return dispatch({ type: STOP_LOADING });
				} else if (response.status === 409) {
					if (response.data.error?.code === 307 && response.data.extend?.filterInfo === 'format custom is wrong') {
						dispatch(addedNewMaillist(response.data.error.code));
						dispatch(showAlertAction(t('mailLists.error_LDAP'), 'error'));
						return dispatch({ type: STOP_LOADING });
					}
					dispatch(addedNewMaillist(response.data.error.code));
				} else {
					dispatch(showAlertAction('Error', 'error'));
					dispatch({ type: STOP_LOADING });
				}
			});
	};
};

export const addingNewMaillist = () => ({
	type: ADDING_NEW_MAILLIST,
	payload: { addingNewMaillist: true },
});

export const addedNewMaillist = result => {
	return {
		type: ADDED_NEW_MAILLIST,
		payload: { addingNewMaillist: false, add_status: result },
	};
};

export const removeAddStatus = () => {
	return {
		type: ADDED_NEW_MAILLIST,
		payload: { add_status: '' },
	};
};

export const cleanSelectedMaillist = () => ({
	type: CLEAN_SELECTED_MAILLIST,
	payload: { selectedMaillist: '' },
});

export const deleteMaillist = (maillistEmail, aliasTodel, t) => {
	const formData = new FormData();
	if (aliasTodel) {
		formData.append('mail', maillistEmail);
		formData.append('aliases', aliasTodel);
	} else {
		formData.append('mail', maillistEmail);
	}
	return function (dispatch) {
		dispatch(deletingMaillist());
		axios
			.delete(Config.newMaillistsUrl(maillistEmail))
			.then(response => {
				dispatch(deletedMaillist(maillistEmail));
				dispatch(getMaillists(t));
				let history = createHashHistory();
				history.push('/maillists');
			})
			.catch(response => {
				dispatch(showAlertAction('Error', 'error'));
				dispatch({ type: STOP_LOADING });
			});
	};
};

export const deletingMaillist = () => ({
	type: DELETING_MAILLIST,
	payload: { deletingMaillist: true },
});

export const deletedMaillist = maillistEmail => ({
	type: DELETED_MAILLIST,
	payload: { deletingMaillist: false, maillistEmail },
});

export const successUsersFetch = (users, usersPagesCount, page) => ({
	type: SUCCESS_USERS_FETCH,
	payload: { usersFetching: false, users, usersPagesCount, page },
});

export const updateMaillist = (mail, newMail, displayName, add, replace, remove, t) => {
	return function (dispatch) {
		let updArrow = [];
		if (replace.length > 0) {
			for (let i = 0; i < replace.length; i++) {
				let path = Object.keys(replace[i])[0];
				let pathFullValue = replace[i][path];
				updArrow.push({ op: 'replace', path: path, value: pathFullValue.trim() });
			}
		}
		if (add.length > 0) {
			for (let i = 0; i < add.length; i++) {
				let path = Object.keys(add[i])[0];
				updArrow.push({ op: 'add', path: path, value: add[i][Object.keys(add[i])].trim() });
			}
		}
		if (remove.length > 0) {
			for (let i = 0; i < remove.length; i++) {
				updArrow.push({ op: 'remove', path: remove[i] });
			}
		}

		dispatch(updatingMaillist());
		return axios
			.patch(Config.newMaillistsUrl(mail), updArrow, {
				headers: {
					'Content-Type': 'application/json-patch+json',
				},
			})
			.then(() => {
				dispatch(getMaillists(t));
				dispatch(updatedMaillist());
				dispatch(getSelectedMaillist(displayName));
				if (newMail) createHashHistory().push(`/maillists/${newMail}`);
				else createHashHistory().push(`/maillists/${mail}`);
			})

			.catch(response => {
				if (response.status === 400) {
					dispatch(showAlertAction(t('users_page.required_params'), 'error'));
					return dispatch({ type: STOP_LOADING });
				} else if (response.status === 401) {
					dispatch(showAlertAction(t('users_page.old_token'), 'error'));
					return dispatch({ type: STOP_LOADING });
				} else if (response.status === 403) {
					dispatch(showAlertAction(t('users_page.no_permission'), 'error'));
					return dispatch({ type: STOP_LOADING });
				} else if (response.status === 409 && response.data.error.code && response.data.error.code === 102) {
					dispatch(updatedMaillist(response.data.error.code));
					return dispatch({ type: STOP_LOADING });
				} else if (response.status === 409) {
					if (response.data.error?.code === 307 && response.data.extend?.filterInfo === 'format custom is wrong') {
						dispatch(updatedMaillist(response.data.error.code));
						dispatch(showAlertAction(t('mailLists.error_LDAP'), 'error'));
						return dispatch({ type: STOP_LOADING });
					}
					dispatch(updatedMaillist(response.data.error.code));
				} else {
					dispatch(showAlertAction('Error', 'error'));
					dispatch({ type: STOP_LOADING });
				}
			});
	};
};

export const updatingMaillist = () => ({
	type: UPDATING_MAILLIST,
	payload: { updatingMaillist: true },
});
export const updatedMaillist = result => {
	return {
		type: UPDATED_MAILLIST,
		payload: { updatingMaillist: false, upd_status: result },
	};
};

export const removeUpdStatus = () => {
	return {
		type: UPDATED_MAILLIST,
		payload: { upd_status: '' },
	};
};

import {
	AUTHORIZE,
	NO_LOGIN_OR_PASSWORD_AUTH,
	BEGIN_LOGIN,
	SUCCESS_LOGIN,
	UNSUCCESS_LOGIN,
	SELECT_LANGUAGE,
	CLEAN_SELECTED_TENANTMENU,
	SELECTED_TENANTMENU,
	NEW_CAPTCHA,
	SAVE_LOGIN
} from '../constants/LoginConstants';

const initialState = {
	noLogin: false,
	noPassword: false,
	isAuthing: false,
	selectedLanguage: localStorage.getItem('language'),
	selectedTenantMenu: {},
	captchaUri: null,
	lastAuthFailed: null,
	login: "",
};

export const auth = (state = initialState, action = {}) => {
	switch (action.type) {
		case AUTHORIZE:
			return Object.assign({}, state, {});
		case NO_LOGIN_OR_PASSWORD_AUTH:
			return Object.assign({}, state, {
				isAuthing: false,
				noLogin: action.payload.noLogin,
				noPassword: action.payload.noPassword,
			});
		case BEGIN_LOGIN:
			return Object.assign({}, state, {
				noLogin: false,
				noPassword: false,
				isAuthing: true,
				lastAuthFailed: false,
			});
		case SUCCESS_LOGIN:
			return Object.assign({}, state, {
				isAuthing: false,
				token: action.payload.token,
				captchaUri: null,
				lastAuthFailed: false,
			});
		case UNSUCCESS_LOGIN:
			return Object.assign({}, state, {
				isAuthing: false,
				captchaUri: action.payload?.captcha || null,
				lastAuthFailed: true,
			});
		case SELECT_LANGUAGE:
			return Object.assign({}, state, { selectedLanguage: action.payload });
		case SELECTED_TENANTMENU:
			return Object.assign({}, state, {
				selectedTenantMenuItem: action.payload.menuItem,
				selectedTenantMenuTenant: action.payload.tenant,
			});
		case CLEAN_SELECTED_TENANTMENU:
			return Object.assign({}, state, {
				selectedTenantMenu: action.payload.selectedMaillist,
			});
		case SAVE_LOGIN:
			return Object.assign({}, state, {
				login: action.payload,
			});
		case NEW_CAPTCHA:
			return Object.assign({}, state, {
				captchaUri: action.payload?.captcha || null,
			});

		default:
			return state;
	}
};

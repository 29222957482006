import React from 'react';
import QuotaBar from '../Subs/QuotaBar.js';
import { withTranslation } from 'react-i18next';
import userActive from '../../../assets/user_active.svg';
import userBlocked from '../../../assets/user_blocked.svg';
import adminActiveIcon from '../../../assets/admin_active.svg';
import adminBlockedIcon from '../../../assets/admin_blocked.svg';
import CheckedMark from '../../../assets/checkmark.svg';
import CrossMark from '../../../assets/not_active.svg';
import DarkTooltip from '../../GeneralElements/DarkTooltip.js';
import '../../../styles/Users.css';

const dataInColumn = ({ header, value, inetUserStatus, t }) => {
	const toDisplayInColumn = () => {
		if (header.title === t('status') && value !== '0') {
			return (
				<DarkTooltip title={<>{t('users_page.active')}</>} placement='bottom-start'>
					<img width='24px' height='24px' src={CheckedMark} alt={''} style={{}} />
				</DarkTooltip>
			);
		}
		if (header.title === t('status') && value === '0') {
			return (
				<DarkTooltip title={<>{t('users_page.blocked')}</>} placement='bottom-start'>
					<img width='24px' height='24px' src={CrossMark} alt={''} style={{}} />
				</DarkTooltip>
			);
		}
		if (header.title === t('license_agreement') && (value === '2')) {
			return (
				<DarkTooltip title={<>{t('users_page.not_accepted')}</>} placement='bottom-start'>
					<img width='24px' height='24px' src={CrossMark} alt={''} style={{}} />
				</DarkTooltip>
			);
		}
		if (header.title === t('license_agreement') && value !== '2') {
			return (
				<DarkTooltip title={<>{t('users_page.accepted')}</>} placement='bottom-start'>
					<img width='24px' height='24px' src={CheckedMark} alt={''} style={{}} />
				</DarkTooltip>
			);
		}
		if (header.title === t('role') && (value === '1' || value === '3') && inetUserStatus === '1') {
			return (
				<DarkTooltip title={<>{t('admin')}</>} placement='bottom-start'>
					<img width='24px' height='24px' src={adminActiveIcon} alt={''} style={{}} />
				</DarkTooltip>
			);
		}
		if (header.title === t('role') && (value === '1' || value === '3') && inetUserStatus !== '1') {
			return (
				<DarkTooltip title={<>{t('admin')}</>} placement='bottom-start'>
					<img width='24px' height='24px' src={adminBlockedIcon} alt={''} style={{}} />
				</DarkTooltip>
			);
		}
		if (header.title === t('role') && value === '2' && inetUserStatus === '1') {
			return (
				<DarkTooltip title={<>{t('user')}</>} placement='bottom-start'>
					<img width='24px' height='24px' src={userActive} alt={''} style={{}} />
				</DarkTooltip>
			);
		}
		if (header.title === t('role') && value === '2' && inetUserStatus !== '1') {
			return (
				<DarkTooltip title={<>{t('user')}</>} placement='bottom-start'>
					<img width='24px' height='24px' src={userBlocked} alt={''} style={{}} />
				</DarkTooltip>
			);
		}
		// case for old users with wrong role 0, which is not used. They all displays like users 
		if (header.title === t('role') && value === '0' && inetUserStatus === '1') {
			return (
				<DarkTooltip title={<>{t('user')}</>} placement='bottom-start'>
					<img width='24px' height='24px' src={userActive} alt={''} style={{}} />
				</DarkTooltip>
			);
		}
		if (header.title === t('role') && value === '0' && inetUserStatus !== '1') {
			return (
				<DarkTooltip title={<>{t('user')}</>} placement='bottom-start'>
					<img width='24px' height='24px' src={userBlocked} alt={''} style={{}} />
				</DarkTooltip>
			);
		}
		if (header.title === t('quota')) {
			return <QuotaBar quota={value} />;
		}
		return <div style={{ opacity: inetUserStatus === '0' ? '0.5' : '' }}>{value}</div>;
	};
	return toDisplayInColumn();
};

export default withTranslation()(dataInColumn);

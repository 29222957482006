import React from 'react';
import { LANGARR } from '../../constants/LanguageConstants';
import { TextField, Button, Tooltip, MenuItem, Select } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { getTenant, updateTenant } from '../../actions/TenantsActions.js';
import { connect } from 'react-redux';
import Info from '../../assets/info.svg';
import { createHashHistory } from 'history';
import InfoRed from '../../assets/info-red.svg';
import i18next from 'i18next';
import BackArrow from '../../assets/backArrow.svg';
import CircularProgress from '@material-ui/core/CircularProgress';
import { mailParser } from '../../allFunctions/parser.js';
import '../../styles/Domain.css';

const mapStateToProps = state => ({
	tenant: state.tenants.tenant,
	upd_status: state.tenants.add_status,
	upd_code: state.tenants.add_code,
	updatingTenant: state.tenants.addingNewTenant,
	tenantFetching: state.tenants.tenantFetching,
});

const mapDispatchToProps = dispatch => ({
	getTenant: id => dispatch(getTenant(id)),
	updateTenant: (id, add, replace, remove, t) => dispatch(updateTenant(id, add, replace, remove, t)),
});

const styles = theme => ({
	infoForm: {
		marginBottom: '10px',
		'& > *': {
			margin: '10px',
		},
	},
	buttonsBlock: {
		'& > *': {
			margin: '10px',
		},
	},

	spinner: {
		position: 'absolute',
		left: '500px',
		top: '276px',
		color: 'rgba(20, 134, 200, 1) !important',
	},
	descTextField: {
		maxHeight: '200px',
		overflowY: 'scroll !important',
		paddingTop: '0px !important',
	},
	// tenantTextField: {
	//   height: "40px",
	//   "&:disabled": {
	//   backgroundColor: "red"
	//   },
	// },
	addDomainBtn: {
		width: '105px',
		height: '32px',
		paddingLeft: '16px',
		marginRight: '12px',
		color: '#19252E',
		backgroundColor: 'rgba(68, 187, 255, 0.56)',
		// fontStyle: "normal",
		fontWeight: '500',
		fontSize: '14px',
		lineHeight: '24px',
		letterSpacing: '0.35px',
		border: '1px solid rgba(25, 37, 46, 0.4)',
		textTransform: 'none',
		cursor: 'pointer',
		'&:hover': {
			backgroundColor: 'rgba(68, 187, 255, 0.86) !important',
			color: '#19252E',
			border: '1px solid rgba(25, 37, 46, 0.08)',
		},
		'&:disabled': {
			backgroundColor: 'rgba(25, 37, 46, 0.08) !important',
			color: '#A3A8AB',
			border: '1px solid rgba(25, 37, 46, 0.08)',
			cursor: 'pointer !important',
		},
		// "&:selected": {
		//   backgroundColor: "#BFDDF3 !important",
		//   color: "#0079D0",
		//   // border: "1px solid rgba(25, 37, 46, 0.08)",
		// },
		// "&:active": {
		//   backgroundColor: "#BFDDF3 !important",
		//   color: "#0079D0",
		//   // border: "1px solid rgba(25, 37, 46, 0.08)",
		// },
	},

	cancelButton: {
		paddingLeft: '16px',
		color: '#19252E',
		backgroundColor: 'white !important',
		fontWeight: '525',
		width: '84px',
		height: '32px',
		textTransform: 'none',
		fontSize: '14px',
		lineHeight: '16px',
		letterSpacing: '0.35px',
		border: '1px solid rgba(25, 37, 46, 0.4)',
		'&:hover': {
			backgroundColor: 'rgba(25, 37, 46, 0.08) !important',
			color: '#19252E',
			border: '1px solid rgba(25, 37, 46, 0.4)',
		},
		// "&:active": {
		//   backgroundColor: "#BFDDF3 !important",
		//   color: "#0079D0",
		//   border: "1px solid #0079D0"
		// }
	},
	RoundTooltip: {
		width: '32px',
		height: '32px',
		marginTop: '16px',
		'&:hover': {
			backgroundColor: 'rgba(25, 37, 46, 0.08); !important',
		},
		'&:active': {
			backgroundColor: 'rgba(25, 37, 46, 0.16); !important',
		},
	},
});

class TenantForm extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			name: '',
			domain: '',
			id: '',
			lang: '',
			langShort: '',
			domainFieldError: false,
			nameFieldError: false,
			disabled: true,
			langArr: LANGARR,
		};
		this.history = createHashHistory();
	}

	async componentDidMount() {
		if (localStorage.getItem('role') === '3') {
			let findLang = '';
			await this.props.getTenant(localStorage.getItem('tenantCur'));
			if (this.props.tenant.lang) findLang = this.state.langArr.find(e => e.value === this.props.tenant.lang);
			this.setState({
				name: this.props.tenant.name ? this.props.tenant.name : '',
				id: this.props.tenant.tenant_id ? this.props.tenant.tenant_id : localStorage.getItem('tenantCur'),
				lang: this.props.tenant.lang ? findLang.label : '',
			});
		} else {
			await this.props.getTenant(localStorage.getItem('tenant'));
			let findLang = '';
			if (this.props.tenant.lang) findLang = this.state.langArr.find(e => e.value === this.props.tenant.lang);
			this.setState({
				name: this.props.tenant.name ? this.props.tenant.name : '',
				id: this.props.tenant.tenant_id ? this.props.tenant.tenant_id : localStorage.getItem('tenant'),
				lang: this.props.tenant.lang ? findLang.label : '',
			});
		}
	}

	handleTenantSubmit = (e, t) => {
		e.preventDefault();
		const add = [];
		const replace = [];
		const remove = [];
		const recData = {
			name: this.props.tenant.name,
			lang: this.props.tenant.lang,
		};
		const curData = {
			name: this.state.name.trim(),
			lang: this.state.langArr.find(e => e.label === this.state.lang).value,
		};

		for (let key2 in curData) {
			if (!curData[key2] && recData[key2]) {
				let dataObj = {};
				dataObj[`/${key2}`] = '';
				remove.push(dataObj);
			}
			for (let key1 in recData) {
				if (key1 === key2) {
					if ((recData[key1] || recData[key1] === null) && curData[key2] && recData[key1] !== curData[key2]) {
						let dataObj = {};
						dataObj[`/${key2}`] = `${curData[key2]}`;
						replace.push(dataObj);
					}
				}
			}
		}
		this.props.updateTenant(this.state.id, add, replace, remove, t);
	};

	handleClickOpen = () => {
		this.setState({ errorWindow: true });
	};

	handleClose = () => {
		this.setState({ errorWindow: false });
	};

	handleDomainNameBlur(e) {
		e.preventDefault();
		let value = e.target.value;
		if (value && (value.split('.').length < 2 || /\.$|-$|^\.|^-|(\W\W)/.test(value))) return this.setState({ domainFieldError: true });
		if (value.split('.').find(item => item.length < 2)) {
			this.setState({ domainFieldError: true });
		} else this.setState({ domainFieldError: false });
	}

	domainNameChangeHandler = (e, v) => {
		e.preventDefault();
		let value = e.target.value;
		const tester = /^[0-9A-Z-a-z.]+$/;
		if ((!value.match(tester) && value !== '') || value.length > 63) {
			this.setState({ domainFieldError: true });
		}
		this.setState({ domain: mailParser(value) });
	};

	tenantNameChangeHandler = e => {
		e.preventDefault();
		let value = e.target.value;
		const tester = /^[0-9 А-Яа-я_A-Z-a-z.]+$/;
		if (!value.match(tester) & (value !== '')) {
			this.setState({ nameFieldError: true });
		} else {
			this.setState({ nameFieldError: false });
		}
		if (value.length > 100) {
			this.setState({ nameLimitError: true });
		} else this.setState({ nameLimitError: false });
		this.setState({ name: value.replace(/ {2,}/g, ' ') });
		if (value === this.props.tenant.name) this.setState({ disabled: true });
		else this.setState({ disabled: false });
	};

	render() {
		let classes = this.props.classes;
		const selectLang = this.state.langArr.map((option, key) => {
			return (
				<MenuItem
					classes={{
						root: 'period_contaner_select',
						// selected: selectMenuItemClasses,
					}}
					key={'-1' + key}
					value={option}
				>
					<span className='dropdown-title'>{option.label}</span>
				</MenuItem>
			);
		});

		return (
			<div className={'NewDomainRoot'}>
				{localStorage.getItem('role') === '3' ? (
					<div style={{ display: 'flex', cursor: 'pointer' }} onClick={e => this.history.push('/tenants')}>
						<div>
							<Tooltip title={i18next.t('back')}>
								<img src={BackArrow} alt={BackArrow} width='24px' />
							</Tooltip>
						</div>
						<div className='title' style={{ marginBottom: '32px', marginLeft: '12px' }}>
							{i18next.t('tenants.edit_tenant')}
						</div>
					</div>
				) : (
					<div style={{ display: 'flex', cursor: 'pointer' }}>
						<div className='title' style={{ marginBottom: '32px' }}>
							{i18next.t('tenants.settings')}
						</div>
					</div>
				)}

				{this.props.tenantFetching ? (
					<CircularProgress className={classes.spinner} />
				) : (
					<>
						<div
							className='DomainsAddError'
							style={{
								display: this.props?.add_status === 'unknown' ? 'flex' : 'none',
							}}
						>
							<img style={{ marginRight: '8px' }} src={InfoRed} alt={InfoRed} width='24px' height='24px' />
							{i18next.t('domains_page.delete_error')}
						</div>
						<div className='NewDomainTitle2'>{i18next.t('tenants.name')}</div>
						<div className='NewDomainField1'>
							<TextField
								className='NewDomainField1'
								variant='outlined'
								id='outlined-required'
								value={this.state.name}
								InputProps={{ classes: { input: classes.tenantTextField } }}
								onChange={e => {
									this.tenantNameChangeHandler(e);
								}}
							/>
						</div>
						<div
							className='underDomainFieldError'
							style={{
								display: this.state.nameFieldError ? 'block' : 'none',
							}}
						>
							{i18next.t('tenants.error_name')}
						</div>
						<div
							className='underDomainFieldError'
							style={{
								display: this.state.nameLimitError ? 'block' : 'none',
							}}
						>
							{i18next.t('tenants.error_name_limit')}
						</div>

						<div className='NewDomainTitle2' style={{ marginTop: '16px' }}>
							{i18next.t('tenants.id')}
						</div>
						<div className='NewDomainField1' style={{ marginBottom: '16px' }}>
							<TextField
								disabled
								className='NewDomainField1'
								variant='outlined'
								id='outlined-required'
								// placeholder={i18next.t('domains_page.place_holder')}
								value={this.state.id}
							/>
						</div>
						<div className='NewDomainTitle2'>{i18next.t('tenants.lang')}</div>
						<div className='NewDomainField2' style={{ marginBottom: '20px' }}>
							<Select
								disableUnderline
								variant='outlined'
								className='lang_select'
								MenuProps={{
									getContentAnchorEl: null,
									anchorOrigin: {
										vertical: 'bottom',
										horizontal: 'left',
									},
								}}
								onChange={e => {
									this.setState({ lang: e.target.value.label });
									if (e.target.value.value === this.props.tenant.lang) this.setState({ disabled: true });
									else this.setState({ disabled: false });
								}}
								value={this.state.lang}
								renderValue={option => <span className='dropdown-title'>{option}</span>}
								style={{ marginBottom: '8px', width: '392px', height: '40px' }}
							>
								{selectLang}
							</Select>
						</div>

						<div>
							<Button
								onClick={e => this.handleTenantSubmit(e)}
								className={classes.addDomainBtn}
								variant='outlined'
								disabled={this.state.disabled || this.state.nameLimitError || this.state.nameFieldError}
							>
								{i18next.t('tenants.save')}
							</Button>
							<Button
								onClick={e => this.history.push('/tenants')}
								className={classes.cancelButton}
								variant='outlined'
								style={{ display: localStorage.getItem('role') === '3' ? 'flex-box' : 'none' }}
							>
								{i18next.t('tenants.cancel')}
							</Button>
						</div>
					</>
				)}
			</div>
		);
	}
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(TenantForm));

import React from 'react';
import { connect } from 'react-redux';
import { hideAlertAction, closeAlertAction } from '../../actions/AlertsActions.js';
import BasicAlert from '@material-ui/lab/Alert';
import Grow from '@material-ui/core/Grow';
import '../../styles/Alert.css';

const mapStateToProps = state => ({
	showAlert: state.alert.showAlert,
	message: state.alert.message,
	alertType: state.alert.alertType,
});

const mapDispatchToProps = dispatch => ({
	hideAlertAction: () => dispatch(hideAlertAction()),
});

class Alert extends React.Component {
	render() {
		const showAlert = this.props.showAlert;
		const textParts = this.props.message.split('.');
		textParts.map((el, index) => {
			if (!el) textParts.splice(index, 1);
		});
		if (this.props.alertType === 'admin_toast_success') {
			return (
				<>
					<Grow in={showAlert} style={{ transformOrigin: '0 0 0' }} {...(showAlert ? { timeout: 1000 } : {})}>
						<div className='success_toast'>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='24'
								height='24'
								viewBox='0 0 24 24'
								fill='none'
								style={{ position: 'absolute', top: 'opx', right: '4px', cursor: 'pointer' }}
								onClick={() => this.props.hideAlertAction()}
							>
								<path fill-rule='evenodd' clip-rule='evenodd' d='M8.4 7L7 8.4L10.6 12L7 15.6L8.4 17L12 13.4L15.6 17L17 15.6L13.4 12L17 8.4L15.6 7L12 10.6L8.4 7Z' fill='#19252E' />
							</svg>
							<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
								<path
									fill-rule='evenodd'
									clip-rule='evenodd'
									d='M20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12ZM7 12.5L11.5 17L16.5721 8.00001L14.935 7.00002L11.2 13.7L8.5 11L7 12.5Z'
									fill='#00884F'
								/>
							</svg>
							<div className='toast_main_info' style={{ marginLeft: '8px' }}>
								{textParts.map(el => (
									<div>{`${el}.`}</div>
								))}
							</div>
						</div>
					</Grow>
				</>
			);
		} else {
			return (
				<Grow in={showAlert} style={{ transformOrigin: '0 0 0' }} {...(showAlert ? { timeout: 1000 } : {})}>
					<BasicAlert
						className='alert'
						style={{
							alignItems: 'center',
							textAlign: 'center',
							position: 'fixed',
							minWidth: '300px',
							margin: '20px 400px',
							// left: 0,
							display: showAlert ? 'flex' : 'none',
							// right: 0,
							zIndex: 10000,
						}}
						onClose={this.props.hideAlert}
						variant='filled'
						severity={this.props.alertType}
					>
						<div>{this.props.message}</div>
					</BasicAlert>
				</Grow>
			);
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Alert);

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
// import { getCookie } from '~/services/cookie/cookie.js';
import ba_RU from './ba-RU.json';
import en_US from './en-US.json';
import es_PA from './es-PA.json';
import fr_FR from './fr-FR.json';
import it_IT from './it-IT.json';
import pt_BR from './pt-BR.json';
import ru_RU from './ru-RU.json';
import tt_RU from './tt-RU.json';

function initiateLang(value) {
	localStorage.setItem('lang', value);
	return value;
}

function userLang() {
	let userLang = navigator.language;
	if (/^ru\b/.test(userLang)) {
		return 'ru-RU';
	} else if (/^es\b/.test(userLang)) {
		return 'es-PA';
	} else if (/^en\b/.test(userLang)) {
		return 'en-US';
	} else if (/^fr\b/.test(userLang)) {
		return 'fr-FR';
	} else if (/^pt\b/.test(userLang)) {
		return 'pt-BR';
	} else if (/^it\b/.test(userLang)) {
		return 'it-IT';
	} else if (/^tt\b/.test(userLang)) {
		return 'tt-RU';
	} else {
		return 'en-US';
	}
}

export function convertLangName(lang) {
	switch (lang) {
		case 'Russian':
		case 'ru-RU':
			return 'ru-RU';
		case 'Spanish':
		case 'es-PA':
			return 'es-PA';
		case 'French':
		case 'fr-FR':
			return 'fr-FR';
		case 'Portuguese-Brazil':
		case 'pt-BR':
		case 'Portuguese':
			return 'pt-BR';
		case 'Tatar':
		case 'tt-RU':
			return 'tt-RU';
		case 'Bashkir':
		case 'ba-RU':
			return 'ba-RU';
		case 'Italian':
		case 'it-IT':
			return 'it-IT';
		case 'English':
		case 'en-US':
		case 'German':
			return 'en-US';
		default:
			return userLang();
	}
}

const resources = {
	'en-US': { translation: { ...en_US } },
	'ru-RU': { translation: { ...ru_RU } },
	'es-PA': { translation: { ...es_PA } },
	'fr-FR': { translation: { ...fr_FR } },
	'pt-BR': { translation: { ...pt_BR } },
	'tt-RU': { translation: { ...tt_RU } },
	'ba-RU': { translation: { ...ba_RU } },
	'it-IT': { translation: { ...it_IT } },
};

i18n.use(initReactI18next).init({
	lng: initiateLang(convertLangName(localStorage.getItem('lang'))),
	fallbackLng: 'en-US',
	resources,
	interpolation: {
		escapeValue: false,
	},
});

export default i18n;

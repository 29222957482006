import React, { useEffect, useState } from 'react';
import { TextField, makeStyles, MenuItem, Select } from '@material-ui/core';
import { parser } from '../../../allFunctions/parser.js';
import DarkTooltip from '../../GeneralElements/DarkTooltip.js';
import { withTranslation } from 'react-i18next';
import '../../../styles/Maillists.css';

const useStyles = makeStyles(theme => ({}));

const StaticFields = ({
	nameChange,
	emailChange,
	domainChange,
	typeChange,
	maillistName,
	maillistEmail,
	domain,
	typeMailList,
	domains,
	upd_status,
	emailFieldError,
	setEmailFieldError,
	editingGroup,
	t,
}) => {
	const classes = useStyles();

	const types = [
		{ value: 'static', name: t('mailLists.static') },
		{ value: 'dynamic', name: t('mailLists.dynamic') },
	];

	const handleDomainNameChange = e => {
		e.preventDefault();
		if (e.target.value.match(/[^/a-zA-ZА-Яа-я0-9_.-\s+]/g) || e.target.value.length > 255) {
			return;
		} else {
			nameChange(parser(e.target.value));
		}
	};

	const handleListEmailChange = e => {
		e.preventDefault();
		let value = e.target.value;
		const tester = /^[_0-9A-Za-z-.]+$/;
		if (!value.match(tester) & (value !== '')) {
			setEmailFieldError(true);
		} else {
			setEmailFieldError(false);
		}
		emailChange(value);
	};

	const handleListEmailBlur = e => {
		e.preventDefault();
		let value = e.target.value;
		if (value && /\.$|-$|^\.|^-|(\W\W)/.test(value)) {
			return setEmailFieldError(true);
		}
		if (value.length < 1 || value.split('.').find(item => item.length < 2)) {
			setEmailFieldError(true);
		} else setEmailFieldError(false);
	};

	return (
		<>
			{/* ----------------------1st raw GROUPNAME and GROUPTYPE -------------------- */}
			<div className='staticRaw' style={{ marginBottom: '16px' }}>
				<div>
					<div className='fieldLabel'>{t('mailLists.group_name')}</div>
					<TextField
						variant='outlined'
						className='infoField'
						inputProps={{
							autocomplete: 'disabled',
							type: 'text',
						}}
						value={maillistName}
						onChange={e => handleDomainNameChange(e)}
					/>
				</div>
				<div style={{ marginLeft: '16px' }}>
					<div className='fieldLabel'>{t('mailLists.group_type')}</div>
					<Select
						select
						variant='outlined'
						className={editingGroup ? 'selectFieldDisabled' : 'selectField'}
						disabled={editingGroup}
						MenuProps={{
							getContentAnchorEl: null,
							anchorOrigin: {
								vertical: 'bottom',
								horizontal: 'left',
							},
							transformOrigin: {
								vertical: 'top',
								horizontal: 'left',
							},
						}}
						value={typeMailList}
						// disabled={this.props.domainsFetching}
						onChange={e => {
							typeChange(e.target.value);
						}}
					>
						{types.map((option, key) => (
							<MenuItem className='options' key={'-1' + key} value={option.value}>
								{option.name}
							</MenuItem>
						))}
					</Select>
				</div>
			</div>
			{/* ----------------------2st raw GROUPEMAIL and GROUPDOMAIN ----------------------*/}
			<div className='staticRaw' style={{ marginBottom: '4px' }}>
				<div>
					<div className='fieldLabel'>{t('mailLists.group_email')}</div>
					<TextField
						variant='outlined'
						className='infoField'
						inputProps={{
							autocomplete: 'disabled',
							type: 'text',
						}}
						value={maillistEmail}
						onChange={e => handleListEmailChange(e)}
						onBlur={e => handleListEmailBlur(e)}
					/>
				</div>
				<div style={{ marginLeft: '16px' }}>
					<div className='fieldLabel'></div>
					<Select
						variant='outlined'
						className='selectField'
						MenuProps={{
							getContentAnchorEl: null,
							anchorOrigin: {
								vertical: 'bottom',
								horizontal: 'left',
							},
							transformOrigin: {
								vertical: 'top',
								horizontal: 'left',
							},
						}}
						value={domain}
						// disabled={this.props.domainsFetching}
						onChange={e => domainChange(e.target.value)}
					>
						{domains ? (
							domains.map((option, key) => (
								<MenuItem className='options' key={'-1' + key} value={option.name}>
									{option.name}
								</MenuItem>
							))
						) : (
							<div />
						)}
					</Select>
				</div>
			</div>
			<div
				className='underMailboxFieldError'
				style={{
					display: upd_status === 101 || upd_status === 102 ? 'block' : 'none',
				}}
			>
				{t('mailLists.error_exist_mail')}
			</div>
			<div
				className='underMailboxFieldError'
				style={{
					display: emailFieldError ? 'block' : 'none',
				}}
			>
				{t('mailLists.invalid_mail_value')}
			</div>
		</>
	);
};

export default withTranslation()(StaticFields);

import React from 'react';
import { Button, TextField, MenuItem, DialogActions, DialogContentText, Dialog, DialogTitle, DialogContent, Slide, TableRow } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { createHashHistory } from 'history';
import { addResourceToGroup, getResource, updateResource } from '../../actions/ResourceGroupsActions';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import Capacity from './Capacity';
import ResourcesAvatar from '../../assets/resourcesAvatar.svg';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getDomains } from '../../actions/DomainsActions';
import { withTranslation } from 'react-i18next';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction='up' ref={ref} {...props} />;
});

const mapStateToProps = state => ({
	resourceFetching: state.resourceGroups.resourceFetching,
	resource: state.resourceGroups.resource,
	addingResourceToGroup: state.resourceGroups.addingResourceToGroup,
	updatingResource: state.resourceGroups.updatingResource,
	domains: state.users.domains,
});

const mapDispatchToProps = dispatch => ({
	addResourceToGroup: (groupName, formData) => dispatch(addResourceToGroup(groupName, formData)),
	getResource: (groupName, resourceId, t) => dispatch(getResource(groupName, resourceId, t)),
	updateResource: (groupName, mail, add, replace, remove, t) => dispatch(updateResource(groupName, mail, add, replace, remove, t)),
	getDomains: () => dispatch(getDomains()),
});

const styles = theme => ({
	root: {
		// minWidth: "600px",
		width: '100%',
		backgroundColor: 'white',
		minHeight: '100vh',
		boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)',
	},
	infoForm: {
		marginBottom: '10px',
		'& > *': {
			margin: '10px',
		},
	},
	buttonsBlock: {
		'& > *': {
			margin: '10px',
		},
	},
	fields: {
		width: '300px',
	},
	mainButton: {
		marginLeft: '16px',
		// marginTop: "4px",
		color: '#19252E',
		backgroundColor: 'rgba(68, 187, 255, 0.56)',
		fontWeight: '550',
		width: '100px',
		height: '32px',
		textTransform: 'none',
		fontSize: '14px',
		lineHeight: '24px',
		letterSpacing: '0.35px',
		'&:hover': {
			backgroundColor: 'rgba(68, 187, 255, 0.72)',
			color: '#19252E',
		},
		'&:active': {
			backgroundColor: 'red(68, 187, 255, 0.88)',
			color: '#19252E',
		},
		'&:disabled': {
			backgroundColor: 'rgba(25, 37, 46, 0.08) !important',
			color: '#A3A8AB',
			border: '1px solid rgba(25, 37, 46, 0.08)',
		},
	},
	cancelButton: {
		fontWeight: '550',
		width: '84px',
		height: '32px',
		textTransform: 'none',
		fontSize: '14px',
		lineHeight: '16px',
		letterSpacing: '0.35px',
		border: '1px solid #C0C0C0',
		'&:hover': {
			backgroundColor: '#EDEEEF !important',
			color: '#19252E',
		},
	},
});

class EditResource extends React.Component {
	static propTypes = {
		match: PropTypes.object.isRequired,
		location: PropTypes.object.isRequired,
		history: PropTypes.object.isRequired,
	};
	constructor(props) {
		super(props);
		this.history = createHashHistory();
		this.state = {
			displayName: '',
			domain: '',
			mail: '',
			description: '',
			l: '',
			resource_capacity: '',
			openUploadWindow: false,
			capacityError: false,
			resExtraDot: false,
			resMaxSymb: false,
			resWrongSymb: false,
		};

		this.handleCancel = this.handleCancel.bind(this);
		this.backToGroups = this.backToGroups.bind(this);
		this.handleUpdateResource = this.handleUpdateResource.bind(this);
	}

	componentDidUpdate(prevProps, t) {
		if (this.props.resource !== prevProps.resource) {
			let mail = this.props.resource.email.split('@')[0];
			let domain = this.props.resource.email.split('@')[1];
			let { displayName, l, description } = this.props.resource;
			let resource_capacity = this.props.resource.resource_capacity && !isNaN(this.props.resource.resource_capacity) ? (this.props.resource.resource_capacity) : '';
			this.setState({ mail, domain, displayName, l, description, resource_capacity });			
		}
		if (this.props.location !== prevProps.location) {
			let groupName = this.props.match.params.groupName;
			let resourceId = this.props.match.params.resourceId;
			this.props.getResource(groupName, resourceId, t);
			this.props.getDomains();
		}
	}

	componentDidMount(t) {
		let groupName = this.props.match.params.groupName;
		let resourceId = this.props.match.params.resourceId;

		this.props.getResource(groupName, resourceId, t);
		this.props.getDomains();
	}

	handleUpdateResource(e, t) {
		let groupName = this.props.match.params.groupName;
		const add = [];
		const replace = [];
		const remove = [];
		let recData = {
			displayName: this.props.resource.displayName,				
		}
		if (this.props.resource.l) {
			recData.l = this.props.resource.l
		}
		if (this.props.resource.description ) {
			recData.description = this.props.resource.description
		}
		if (this.props.resource.resource_capacity && this.props.resource.resource_capacity !== 0) {
			recData.resource_capacity = this.props.resource.resource_capacity
		}
		let curData = {
			displayName: this.state.displayName,
			l: this.state.l,
			description: this.state.description,
			resource_capacity: this.state.resource_capacity,
		};			
		for (let key2 in curData) {
			if (!Object.keys(recData).includes(key2) && curData[key2]) {				
				let dataObj = {};
				dataObj[`/${key2}`] = curData[key2];
				add.push(dataObj);				
			}

			for (let key1 in recData) {
				if (key1 === key2 && (key1 || key2) !== 'email') {
					if (recData[key1] && curData[key2] && recData[key1] !== curData[key2]) {
						let dataObj = {};
						dataObj[`/${key2}`] = curData[key2];
						replace.push(dataObj);
					}
					if (recData[key1] && !curData[key2]) {
						let dataObj = {};
						dataObj[`/${key2}`] = curData[key2];
						remove.push(dataObj);
					}
				}
			}
		}
		this.props.updateResource(groupName, `${this.state.mail}@${this.state.domain}`, add, replace, remove, t);
	}

	handleCancel(e) {
		e.preventDefault();
		this.history.goBack();
	}
	backToGroups(e) {
		e.preventDefault();
		let groupName = this.props.match.params.groupName;
		this.props.history.push(`/resource_groups/${groupName}`);
	}
	render() {
		const { classes, t } = this.props;
		const { imagePreviewUrl, active } = this.state;

		const ImgUpload = ({ onChange, src }) => (
			<label htmlFor='photo-upload' className='custom-file-upload fas'>
				<div className='img-wrap img-upload'>
					<img alt='uploadphoto' className='photoUpload' htmlFor='photo-upload' src={src} />
				</div>
				<input id='photo-upload' type='file' onChange={onChange} />
			</label>
		);

		const Profile = ({ onSubmit, src }) => (
			<div className='card'>
				<form className='ava' onSubmit={onSubmit}>
					<label className='custom-file-upload fas'>
						<div className='img-wrap'>
							<img alt='profilephoto' className='photoUpload' htmlFor='photo-upload' src={src} />
						</div>
					</label>
				</form>
			</div>
		);

		const Edit = ({ onSubmit, children }) => (
			<div className='card'>
				<form className='ava' onSubmit={onSubmit}>
					{children}
					<button type='submit' className='save'>
						{t('save')}
					</button>
				</form>
			</div>
		);
		return (
			<div className={classes.root}>
				{this.props.resourceFetching || this.props.updatingResource ? (
					<div className='loading'>
						<CircularProgress />
					</div>
				) : (
					<div className='newResource'>
						<div className='newUserForm'>
							<Button className='backBtnIcon' startIcon={<ArrowBackIcon color='primary' />} onClick={this.backToGroups}>
								{t('back')}
							</Button>
						</div>
						<div className='newResourceForm'>
							<div className='existingResourceAva'>
								<img className='resourcesAvatar' src={ResourcesAvatar} alt={''} />
							</div>
							<Dialog
								className='dialogUpload'
								open={this.state.openUploadWindow}
								TransitionComponent={Transition}
								keepMounted
								onClose={() => this.setState({ openUploadWindow: false })}
								aria-labelledby='alert-dialog-slide-title'
								aria-describedby='alert-dialog-slide-description'
							>
								<DialogTitle id='alert-dialog-slide-title'>{'Upload avatar'}</DialogTitle>
								<DialogContent>
									<DialogContentText id='alert-dialog-slide-description'>Image up to 10 MB. Formats: PNG, JPEG, GIF, SVG.</DialogContentText>
									<DialogContentText component='div' id='alert-dialog-slide-description'>
										<div>
											{active === 'edit' ? (
												<Edit onSubmit={this.handleSubmit}>
													<ImgUpload onChange={this.photoUpload} src={imagePreviewUrl} />
												</Edit>
											) : (
												<Profile onSubmit={this.handleSubmit} src={imagePreviewUrl} />
											)}
										</div>
									</DialogContentText>
								</DialogContent>
								<DialogActions>
									<Button onClick={() => this.setState({ openUploadWindow: false })} className='cancelUpload'>
										{t('cancel')}
									</Button>
									<Button onClick={() => this.setState({ openUploadWindow: false })} className='okUpload'>
										Ok
									</Button>
								</DialogActions>
							</Dialog>

							<TableRow>
								<div className='fieldLabel' style={{ marginTop: '40px' }}>
									{t('name')}
								</div>
								<TextField
									variant='outlined'
									className='userInfoField'
									id='resourceName'
									inputProps={{
										autoComplete: 'off',
										type: 'text',
									}}									
									value={this.state.displayName}	
									onChange={e => this.setState({ displayName: e.target.value })}								
								/>
							</TableRow>
							<TableRow>
								<div style={{ display: 'flex', alignItems: 'center', marginTop: '12px' }}>
									<div>
										<div className='fieldLabel'>{t('resourses.res_mail')}</div>
										<TextField
											className='userInfoField'
											variant='outlined'
											id='resourceAddress'
											disableUnderline
											value={this.state.mail + '@' + this.state.domain}
											disabled
											InputProps={{
												autoComplete: 'off',
												type: 'text',
												classes: {
													root: classes.inputResEmail,
												},
											}}
										/>
									</div>
								</div>
							</TableRow>

							<TableRow>
								<div className='fieldLabel' style={{ marginTop: '12px' }}>
									{t('city')}
								</div>
								<TextField
									variant='outlined'
									className='userInfoField'
									id='resourceCity'
									inputProps={{
										autoComplete: 'off',
										type: 'text',
									}}
									value={this.state.l}
									onChange={e => {
										this.setState({ l: e.target.value });
									}}
								/>
							</TableRow>
							<TableRow>
								<div className='fieldLabel' style={{ marginTop: '12px' }}>
									{t('description')}
								</div>
								<TextField
									variant='outlined'
									className='userInfoField'
									id='resourceDescription'
									inputProps={{
										autoComplete: 'off',
										type: 'text',
									}}
									value={this.state.description}
									onChange={e => this.setState({ description: e.target.value })}
								/>
							</TableRow>
							<TableRow>
								<Capacity
									capacityValue={this.state.resource_capacity}
									setCapacityValue={v => {
										this.setState({ resource_capacity: v });
									}}
									setCapacityError={v => {
										this.setState({ capacityError: v });
									}}
									capacityError={this.state.capacityError}
									t={t}
								/>
							</TableRow>

							<div style={{ marginTop: '40px' }}>
								<Button className={classes.cancelButton} onClick={this.handleCancel}>
									{t('cancel')}
								</Button>
								<Button className={classes.mainButton} type='submit' onClick={e => this.handleUpdateResource(e, t)} disabled={this.state.capacityError || !this.state.displayName}>
									{t('refresh')}
								</Button>
							</div>
						</div>
					</div>
				)}
			</div>
		);
	}
}

export default withRouter(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(EditResource))));

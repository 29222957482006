import React, { useEffect, useState } from 'react';
import { TextField, makeStyles } from '@material-ui/core';
import { withTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
	inputResEmailError: {
		fontSize: '14px !important',
		lineHeight: '24px',
		color: '#E2333A',
	},
	inputResEmail: {
		fontSize: '14px !important',
		lineHeight: '24px',
	},
}));

const Capacity = ({ capacityValue, setCapacityValue, setCapacityError, capacityError, t }) => {
	const classes = useStyles();

	const capacityChangeHandler = e => {
		let parsedValue = e.target.value.replace(/^0/g, '').trim();
		if (parsedValue.length > 6) return;

		if (/^\d+$/.test(parsedValue)) {
			setCapacityValue(parsedValue);
			setCapacityError(false);
		} else {
			setCapacityValue(parsedValue);
			setCapacityError(true);
		}
		if (parsedValue.length === 0) setCapacityError(false);
	};

	return (
		<>
			<div className='fieldLabel' style={{ marginTop: '12px' }}>
				{t('resource_capacity')}
			</div>
			<TextField
				variant='outlined'
				className='userInfoField'
				id='resource_capacity'				
				value={capacityValue}
				inputProps={{
					autoComplete: 'off',
					type: 'text',
				}}
				onChange={e => {
					capacityChangeHandler(e);
				}}
			/>

			<div
				className='underMailFieldError'
				style={{
					display: capacityError ? 'block' : 'none',
				}}
			>
				{t('resourses.invalid_symbols_resource_capacity')}
			</div>
		</>
	);
};
export default withTranslation()(Capacity);

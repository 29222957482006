import {
	AUTHORIZE,
	NO_LOGIN_OR_PASSWORD_AUTH,
	BEGIN_LOGIN,
	SUCCESS_LOGIN,
	UNSUCCESS_LOGIN,
	LOGOUT,
	SELECT_LANGUAGE,
	CLEAN_SELECTED_TENANTMENU,
	SELECTED_TENANTMENU,
	NEW_CAPTCHA,
	SAVE_LOGIN,
} from '../constants/LoginConstants';
import { Config } from '../configs';
import { createHashHistory } from 'history';
import { showAlertAction } from './AlertsActions';
import axios from 'axios';
import i18next from 'i18next';

export const getAuthorized = (login, password, captcha) => ({
	type: AUTHORIZE,
	payload: { login: login, password: password, captcha: captcha },
});

export const getNewCaptcha = () => {
	return function (dispatch) {
		return axios.get(Config.captchaUrl()).then(response => {
			if (response.status === 200) {
				dispatch(getNewCaptchaAction(response.data.results.result));
			}
		});
	};
};
export const getNewCaptchaAction = payload => ({
	type: NEW_CAPTCHA,
	payload: payload,
});

export const noLoginOrPasswordAuth = (noLogin, noPassword) => ({
	type: NO_LOGIN_OR_PASSWORD_AUTH,
	payload: { noLogin, noPassword },
});

export const beginLogin = (login, password, captcha) => {
	return function (dispatch) {
		let history = createHashHistory();
		dispatch({ type: BEGIN_LOGIN });
		const formData = new FormData();
		formData.append('login', login);
		formData.append('password', password);

		return Promise.resolve()
			.then(response => {
				if (captcha) {
					return axios.post(Config.captchaUrl(), { captcha });
				}
				return response;
			})
			.then(resp => {
				return axios.post(Config.loginUrl(), formData, { withCredentials: true, nctPublic: true });
			})
			.then(response => {
				if (response.data.result.role !== 1 && response.data.result.role !== 3) {
					dispatch(unsuccessLogin());
					// dispatch(showAlertAction(i18next.t("fail_auth"), "warning"));
					return;
				}
				if (parseInt(response.status / 200) === 1) {
					localStorage.setItem('refresh_token', response.data.result['refresh_token']);
					localStorage.setItem('access_token', response.data.result['access_token']);
					localStorage.setItem('expire', response.data.result['expire']);
					localStorage.setItem('tenant', response.data.result['tenant-id']);
					localStorage.setItem('role', response.data.result['role']);
					localStorage.setItem('currentAcc', login);
					dispatch(successLogin(response.data.result.token));
					dispatch(saveLogin(login));
					if (window.pgs_integration) {
						history.push('/users');
					}
					if (!window.pgs_integration) {
						if (localStorage.getItem('role') === '3' && !window.pgs_integration) {
							history.push('/tenants');
							dispatch(getSelectedTenantMenu(localStorage.getItem('tenant'), i18next.t('tenants.tenants_list')));
						} else {
							history.push('/users');
							dispatch(getSelectedTenantMenu(localStorage.getItem('tenant'), i18next.t('users')));
						}
					}
				}
			})
			.catch(response => {				
				if (response.data.error?.code === 205 || response.data.error?.code === 210) {
					dispatch(unsuccessLogin(response.data.extend));
					return;
				}
				if (Math.floor(response.status / 100) === 4) {
					dispatch(unsuccessLogin());
				}
				if (Math.floor(response.status / 100) === 5) {
					dispatch(unsuccessLogin());
					dispatch(showAlertAction('Error', 'error'));
				}
			});
	};
};

export const successLogin = token => ({
	type: SUCCESS_LOGIN,
	payload: { token },
});

export const unsuccessLogin = payload => ({
	type: UNSUCCESS_LOGIN,
	payload: payload,
});

export const logout = () => {
	const history = createHashHistory();
	localStorage.removeItem('tenant');
	localStorage.removeItem('token');
	localStorage.removeItem('refresh_token');
	localStorage.removeItem('access_token');
	localStorage.removeItem('expire');
	//add tokens
	history.push('/login');
	return {
		type: LOGOUT,
	};
};
export const getLanguage = data => ({
	type: SELECT_LANGUAGE,
	payload: data,
});

export const saveLogin = login => ({
	type: SAVE_LOGIN,
	payload: login,
});

export const getSelectedTenantMenu = (tenant, menuItem) => {
	return {
		type: SELECTED_TENANTMENU,
		payload: { tenant: tenant, menuItem: menuItem },
	};
};

export const cleanSelectedTenantMenu = () => ({
	type: SELECTED_TENANTMENU,
	payload: '',
});

export const BEGIN_USERS_FETCH = "BEGIN_USERS_FETCH";
export const SUCCESS_USERS_FETCH = "SUCCESS_USERS_FETCH";
export const SUCCESS_USERSFORMODALS_FETCH = "SUCCESS_USERSFORMODALS_FETCH";
export const BEGIN_USER_FETCH = "BEGIN_USER_FETCH";
export const SUCCESS_USER_FETCH = "SUCCESS_USER_FETCH";
export const ADDING_NEW_USER = "ADDING_NEW_USER";
export const ADDED_NEW_USER = "ADDED_NEW_USER";
export const PREVENT_USER_ADD = "PREVENT_USER_ADD";
export const CLEAN_SELECTED_USER = "CLEAN_SELECTED_USER";
export const UPDATING_USER = "UPDATING_USER";
export const UPDATED_USER = "UPDATED_USER";
export const DELETED_USER = "DELETED_USER";
export const DELETING_USER = "DELETING_USER";
export const MIN_LOGIN_LEN = 2;
export const MIN_PASSWORD_LEN = 5;
export const USERS_PER_PAGE = 10;
export const ADDING_NEW_DOMAIN = "ADDING_NEW_DOMAIN";
export const ADDED_NEW_DOMAIN = "ADDED_NEW_DOMAIN"; 
export const PREVENT_DOMAIN_ADD = "ADDED_NEW_DOMAIN";
// export const CLEAN_SELECTED_USER = "";
export const BEGIN_DOMAINS_FETCH = "BEGIN_DOMAINS_FETCH";
export const SUCCESS_DOMAINS_FETCH = "SUCCESS_DOMAINS_FETCH";
export const UPDATING_DOMAIN = "UPDATING_DOMAIN";
export const UPDATED_DOMAIN = "UPDATED_DOMAIN";
export const DELETED_DOMAIN = "DELETED_DOMAIN";
export const DELETING_DOMAIN = "DELETING_DOMAIN";
export const BEGIN_CALENDARS_FETCH = "BEGIN_CALENDARS_FETCH";
export const SUCCESS_CALENDARS_FETCH = "SUCCESS_CALENDARS_FETCH";
export const BEGIN_TRANSF_CALENDARS = "BEGIN_TRANSF_CALENDARS";
export const SUCCESS_TRANSF_CALENDARS = "SUCCESS_TRANSF_CALENDARS";
export const REMOVE_CALENDARS_STATUS = "REMOVE_CALENDARS_STATUS";
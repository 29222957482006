import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	DialogContentText,
	makeStyles,
	TextField,
	Switch,
	IconButton,
} from '@material-ui/core';
import { getCalendars, RemoveTransfStatus, getUsers, removeCalendarStatus } from '../../actions/UsersActions.js';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InfoRed from '../../assets/info-red.svg';
import Chip from '@material-ui/core/Chip';
import SelectCalendar from './SelectCalendar.js';
import '../../styles/Users.css';

const mapStateToProps = state => ({
	usersForModals: state.users.usersForModals,
	calendars_status: state.users.calendars_status,
});

const mapDispatchToProps = dispatch => ({
	getUsers: (page, search, t) => dispatch(getUsers(page, search, t)),
	removeCalendarStatus: () => dispatch(removeCalendarStatus()),
});

const useStyles = makeStyles(theme => ({
	dialog: {
		backgroundColor: 'white',
		width: '500px',
		height: '446px',
		paddingTop: '0px',
		paddingLeft: '0px',
		paddingBottom: '32px',
		borderRadius: '4px',
		boxShadow: '0px 0px 12px rgba(25, 37, 46, 0.24) !important',
	},
	dialogCont: {
		backgroundColor: 'rgba(0, 0, 0, 0.40)',
		zIndex: '4000',
	},
	footer: {
		paddingLeft: '24px',
		justifyContent: 'flex-start',
		paddingBottom: '0px',
		position: 'absolute',
		bottom: '24px',
	},
	row: {
		width: '440px',
		height: '48px',
		paddingTop: '6px',
		fontStyle: 'normal',
		fontWeight: 'normal',
		fontSize: '16px',
		lineHeight: '24px',
		color: 'black',
	},
	mainButton: {
		backgroundColor: '#E2333A !important',
		color: 'white',
		width: '78px',
		height: '32px',
		// marginRight: "4px",
		textTransform: 'none',
		fontWeight: '525',
		fontSize: '14px',
		lineHeight: '16px',
		letterSpacing: '0.35px',
		'&:hover': {
			backgroundColor: '#BB1B1F !important',
			color: 'white',
		},
		'&:disabled': {
			backgroundColor: 'rgba(25, 37, 46, 0.08) !important',
			color: '#A3A8AB',
			border: '1px solid rgba(25, 37, 46, 0.08)',
		},
	},
	cancelButton: {
		fontWeight: '525',
		width: '75px',
		height: '32px',
		textTransform: 'none',
		fontSize: '14px',
		lineHeight: '16px',
		letterSpacing: '0.35px',
		border: '1px solid #C0C0C0',
		'&:hover': {
			backgroundColor: '#EDEEEF !important',
			color: '#19252E',
		},
	},
	roundTooltip: {
		width: '32px',
		height: '32px',
		position: 'absolute',
		top: '12px',
		right: '12px',
		cursor: 'pointer',
		'&:hover': {
			backgroundColor: 'rgba(25, 37, 46, 0.08); !important',
		},
		'&:active': {
			backgroundColor: 'rgba(25, 37, 46, 0.16); !important',
		},
	},
	chipsUser: {
		fontFamily: 'PT Sans',
		fontStyle: 'normal',
		fontWeight: '400',
		fontSize: '16px',
		lineHeight: '24px',
		backgroundColor: 'red',
		color: 'rgba(22, 31, 41, 0.92)',
		marginRight: '8px',
		marginBottom: '12px',
		maxWidth: '472px',
		'text-overflow': 'ellipsis',
		overflowX: 'hidden',
		whiteSpace: 'nowrap',
		'&.MuiChip-outlined': {
			backgroundColor: 'rgba(25, 37, 46, 0.16)',
		},
	},
	dropdownStyle: {
		width: '452px',
	},
	rootSwitch: {
		width: 30,
		height: 20,
		padding: 0,
	},
	switchBase: {
		paddingLeft: '3px',
		paddingRight: '3px',
		paddingTop: '6px',
		color: '#596269',

		'&$checked': {
			transform: 'translateX(10px)',
			color: '#1486C8',
			'& + $track': {
				backgroundColor: 'rgba(68, 187, 255, 0.8)',
				border: '1px solid rgba(25, 37, 46, 0.08)',
			},
		},

		'&$focusVisible $thumb': {},
	},
	thumb: {
		width: 14,
		height: 14,
	},
	track: {
		borderRadius: 10,
		backgroundColor: 'white',
		border: '1px solid rgba(25, 37, 46, 0.4)',
		opacity: 1,
	},
	checked: {},
	focusVisible: {},
}));

const DeleteUser = ({
	open,
	setOpen,
	cn,
	sn,
	mainButton,
	mail,
	t,
	calendars,
	calendars_status,
	transf_status,
	transf_code,
	calendarsFetching,
	calendarsTransfering,
	usersForModals,
	getUsers,
}) => {
	const dispatch = useDispatch();
	const [string, setString] = useState(false);
	const [newUser, setNewUser] = useState('');
	const [calendarsToTransfer, setCalendarsToTransfer] = useState([]);
	const [canTransf, setCanTransf] = useState(false);
	const classes = useStyles();

	useEffect(() => {
		RemoveTransfStatus();
		setNewUser('');
		// removeCalendarStatus()
		if (calendars_status === 200 || !calendars_status) setCanTransf(true);
	}, [open]);

	const transfSubmit = () => {
		if (canTransf) {
			let replace = [];
			replace.push({ op: 'replace', path: `/new_owner`, value: `${newUser.mail}` });
			let i;
			for (i = 0; i < calendarsToTransfer.length; i++) {
				replace.push({ op: 'replace', path: `/calendar_ids`, value: calendarsToTransfer[i] });
			}
			mainButton.action(replace, `${newUser.cn} ${newUser.sn}`);
		} else {
			let replace = [];
			mainButton.action(replace, `${newUser.cn} ${newUser.sn}`);
		}
	};
	const closeButtonAction = () => {
		setOpen('');
		// props.removeCalendarStatus()
	};
	return (
		<Dialog
			open={open != ''}
			onClose={() => setOpen(false)}
			maxWidth='false'
			classes={{ paper: classes.dialog, container: classes.dialogCont }}
			className='calManager'
		>
			<IconButton className={classes.roundTooltip} onClick={() => setOpen('')}>
				<div style={{ width: '24px', height: '24px' }}>
					<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
						<path d='M5 6.4L6.4 5L12 10.6L17.6 5L19 6.4L13.4 12L19 17.6L17.6 19L12 13.4L6.4 19L5 17.6L10.6 12L5 6.4Z' fill='#19252E' />
					</svg>
				</div>
			</IconButton>
			<div
				className='serverError'
				style={{
					display: transf_status === 500 || transf_status === 'unknown' ? 'flex' : 'none',
				}}
			>
				<img style={{ marginRight: '8px' }} src={InfoRed} alt={InfoRed} width='24px' height='24px' />
				{t('domains_page.delete_error')}
			</div>
			<DialogTitle>
				<div className='calendarsTitle'>{t('users_page.delete_user')}</div>
			</DialogTitle>
			<DialogContent className={classes.dialogBody}>
				<DialogContentText className='calendarsSmallTitle'>{t('users_page.lost_access', { cn: cn, sn: sn })}</DialogContentText>
				<div style={{ display: 'flex', alignItems: 'center', marginTop: '30px', marginBottom: '8px' }}>
					<Switch
						focusVisibleClassName={classes.focusVisible}
						disableRipple
						classes={{
							root: classes.rootSwitch,
							switchBase: classes.switchBase,
							thumb: classes.thumb,
							track: classes.track,
							checked: classes.checked,
						}}
						checked={canTransf && calendars_status ===200}
						disabled={calendars_status !== 200}
						onChange={e => {
							canTransf === true ? setCanTransf(false) : setCanTransf(true);
						}}
						//   style={{display: localStorage.getItem('role') !== '3' ? "block" : "none" }}
					/>
					<div className='switchText'>{t('users_page.transfer_calendars')}</div>
				</div>
				<div className='calendarsSmallTitle' style={{ marginBottom: '16px' }}>
					{t('users_page.new_owner_info')}
				</div>
				<div style={{ display: canTransf && calendars_status ===200? 'block' : 'none' }}>
					<SelectCalendar
						t={t}
						mail={mail}
						open={open}
						calendarsToTransfer={calendarsToTransfer}
						setCalendarsToTransfer={v => setCalendarsToTransfer(v)}
					/>
					<div style={{ marginTop: '16px' }}>
						<div className='fieldLabel'>{t('users_page.new_owner')}</div>
						<Autocomplete
							className='userForCalendars'
							multiple
							disabled={newUser}
							filterSelectedOptions
							id='tags-standard'
							options={usersForModals.filter(e => e.mail !== mail)}
							autoHighlight={false}
							filterOptions={(options, params) => {
								if (params.inputValue.length >= 1) {
									return options;
								}
								return [];
							}}
							noOptionsText={''}
							clearText={t('clear')}
							getOptionLabel={option => `${option.cn} ${option.sn} <${option.mail}>`}
							renderTags={(value, getTagProps) =>
								value.map((option, index) => (
									<Chip key={index} label={`${option.cn} ${option.sn}`} {...getTagProps({ index })} disabled={false} />
								))
							}
							ListboxProps={{
								style: {
									maxHeight: '224px',
									//   maxWidth: "472px",
								},
							}}
							onChange={(e, value) => {
								setNewUser(value[0]);
							}}
							onClick={e => {
								e.stopPropagation();
							}}
							renderInput={params => (
								<TextField
									{...params}
									className='menuRow'
									style={{
										maxHeight: '263px',
										overflowX: 'hidden',
									}}
									disableUnderline
									onChange={e => {
										getUsers(1, e.target.value, t);
									}}
								/>
							)}
						/>
					</div>
				</div>
			</DialogContent>
			<DialogActions className={classes.footer}>
				<Button className={classes.mainButton} onClick={transfSubmit} disabled={canTransf && (!newUser || !calendarsToTransfer.length)}>
					{t('alias.remove')}
				</Button>
				<Button
					// disableRipple
					// variant="outlined"
					className={classes.cancelButton}
					onClick={closeButtonAction}
				>
					{t('alias.cancel')}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DeleteUser));

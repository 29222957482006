import React from "react";
import { connect } from "react-redux";
import {
  getGroups,
  cleanSelectedGroup,
} from "../../actions/ResourceGroupsActions";
import {
  Button,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  TableContainer,
  TableHead,
  Tooltip,
  IconButton,
  Toolbar,
} from "@material-ui/core"
import { createHashHistory } from "history";
import CircularProgress from "@material-ui/core/CircularProgress";
import "../../styles/ResourceGroups.css";
import { withTranslation } from "react-i18next";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'

const mapStateToProps = (state) => ({
  groupsFetching: state.resourceGroups.groupsFetching,
  groups: state.resourceGroups.groups,
  selectedGroup: state.resourceGroups.selectedGroup,
});

const mapDispatchToProps = (dispatch) => ({
  getGroups: (t) => dispatch(getGroups(t)),
  cleanSelectedGroup: () => dispatch(cleanSelectedGroup()),
});

class ResourceGroups extends React.Component {
  constructor(props) {
    super(props);
    this.history = createHashHistory();
    this.showNewGroupForm = this.showNewGroupForm.bind(this);
    this.selectGroup = this.selectGroup.bind(this);
  }
  componentDidMount(t) {
    this.props.cleanSelectedGroup();
    this.props.getGroups(t);
  }
  showNewGroupForm(e) {
    e.preventDefault();
    this.history.push("/resource_groups/group/new");
  }
  selectGroup(e, groupName) {
    e.preventDefault();
    this.history.push(`/resource_groups/${groupName}`);
    localStorage.setItem("groupName", groupName)
  }
  render() {
    const { t } = this.props;
    return (
      <TableContainer
        style={{ maxWidth: "300px", minHeight: "100vh", width: "100%" }}
        component={Paper}
        className="resourceGroupsMain"
      >
        <Toolbar style={{ minHeight: "55px", position: "relative" }} className="resourceGroupsToolbar">
          <Tooltip title={t("add")}>
          <Tooltip title={t("add_new_resource_group")}>
                      <Button
                          className="newUserBtn"
                          color="default"
                          startIcon={<AddCircleOutlineIcon color="primary"/>}
                          onClick={this.showNewGroupForm}
                       >
                      {t("new_resource_group")}
                      </Button>     
                      </Tooltip>
          </Tooltip>
         
        </Toolbar>

        {this.props.groupsFetching ? (
          <Table>
         <TableBody>
            <div style={{marginLeft: "130px", marginTop: "300px"}}>
              <CircularProgress />
            </div>
            </TableBody>
            </Table>
        ) : (
          <Table stickyHeader aria-label="sticky table" className="resourceGroupTable">
            <TableHead>
              <TableRow>
                <TableCell className="resourceGroupsHeader" align="left">
                {t("group_name")}
                </TableCell>
              </TableRow>
            </TableHead>
              <TableBody>
                

                {this.props.groups.map ? this.props.groups.map((row, index) => {
                  return (
                    <TableRow
                      className="resourceGroupsHeaderRow"
                      selected={this.props.selectedGroup === row.ou}
                      onClick={(event) => this.selectGroup(event, row.ou)}
                      hover
                      key={index}                      
                    >
                      <TableCell className="resourceGroupsListCell" align="left">
                        {row.ou}
                      </TableCell>
                    </TableRow>
                  )
                }
                ) : null}
            </TableBody>
          </Table>
        )}
      </TableContainer>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ResourceGroups));

import React, { useEffect, useState } from 'react';
import { TextField, makeStyles } from '@material-ui/core';
import { phoneParser } from '../../allFunctions/parser.js';
import DarkTooltip from '../GeneralElements/DarkTooltip.js';
import { showAlertAction } from '../../actions/AlertsActions';
import { withTranslation } from 'react-i18next';
import '../../styles/Users.css';

const useStyles = makeStyles(theme => ({}));

const Contacts = ({ telephoneNumber, setTelephoneNumber, homePhone, setHomePhone, setTelephoneError, t, ...props }) => {
	const classes = useStyles();
	const [telephoneNumberCur, setTelephoneNumberCur] = useState(telephoneNumber);
	const [homePhoneCur, setHomePhoneCur] = useState(homePhone);
	const [telephoneNumberError, setTelephoneNumberError] = useState(false);
	const [homePhoneError, setHomePhoneError] = useState(false);

	const phoneChangeHandler = (e, v) => {
		let parsedValue = phoneParser(e.target.value).trim();
		if (parsedValue.match(/[^/\+/0-9-()\s+]/g) || parsedValue.length > 255) return;
		else {
			if (v === 'telephoneNumber') {
				setTelephoneNumberCur(parsedValue);
				setTelephoneNumber(parsedValue);
				setTelephoneNumberError(false);
				setTelephoneError(false);
			}
		}
		if (v === 'homePhone') {
			setHomePhoneCur(parsedValue);
			setHomePhone(parsedValue);
			setHomePhoneError(false);
			setTelephoneError(false);
		}
	};

	const phoneBlurHandler = (e, t, v) => {
		if (v === 'telephoneNumber') {
			if (!/\d{2,3}/g.test(e.target.value) && telephoneNumber) {
				setTelephoneNumberError(true);
				setTelephoneError(true);
			} else {
				setTelephoneNumberError(false);
				setTelephoneError(false);
			}
		}
		if (v === 'homePhone') {
			if (!/\d{2,3}/g.test(e.target.value) && homePhone) {
				setHomePhoneError(true);
				setTelephoneError(true);
			} else {
				setHomePhoneError(false);
				setTelephoneError(false);
			}
		}
		if (!e.target.value) {
			setTelephoneNumberError(false);
			setHomePhoneError(false);
			setTelephoneError(false);
		}
	};

	return (
		<>
			<div className='addNewUserForm' style={{ marginBottom: '8px' }}>
				{t('contacts')}
			</div>
			<div className='allContacts'>
				<div className='contactBlock'>
					<div className='loginRaw' style={{ marginBottom: '16px' }}>
						<div>
							<div className='fieldLabel'>{t('work_phone')}</div>
							<DarkTooltip title={<>{t('users_page.tooltip_disabled_fields')}</>} placement='bottom-start' hidden={!window.pgs_integration}>
								<TextField
									disabled={window.pgs_integration}
									className='userInfoField'
									variant='outlined'
									value={telephoneNumberCur}
									inputProps={{
										autocomplete: 'disabled',
										type: 'text',
									}}
									onChange={e => {
										phoneChangeHandler(e, 'telephoneNumber');
									}}
									onBlur={e => phoneBlurHandler(e, t, 'telephoneNumber')}
								/>
							</DarkTooltip>
							<div
								className='underLoginFieldError'
								style={{
									display: telephoneNumberError ? 'block' : 'none',
								}}
							>
								{t('telephone_wrong_type')}
							</div>
						</div>
					</div>
					<div className='loginRaw' style={{ marginBottom: '16px' }}>
						<div>
							<div className='fieldLabel'>{t('home_phone')}</div>
							<DarkTooltip title={<>{t('users_page.tooltip_disabled_fields')}</>} placement='bottom-start' hidden={!window.pgs_integration}>
								<TextField
									disabled={window.pgs_integration}
									className='userInfoField'
									variant='outlined'
									value={homePhoneCur}
									inputProps={{
										autocomplete: 'disabled',
										type: 'text',
									}}
									onChange={e => {
										phoneChangeHandler(e, 'homePhone');
									}}
									onBlur={e => phoneBlurHandler(e, t, 'homePhone')}
								/>
							</DarkTooltip>
							<div
								className='underLoginFieldError'
								style={{
									display: homePhoneError ? 'block' : 'none',
								}}
							>
								{t('telephone_wrong_type')}
							</div>
						</div>
					</div>
					<div className='loginRaw' style={{ marginBottom: '16px' }}></div>
				</div>
			</div>
		</>
	);
};

export default withTranslation()(Contacts);

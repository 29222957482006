import {
	BEGIN_GROUPS_FETCH,
	SUCCESS_GROUPS_FETCH,
	ADDING_NEW_GROUP,
	ADDED_NEW_GROUP,
	BEGIN_RESOURCES_FETCH,
	SUCCESS_RESOURCES_FETCH,
	ADDING_RESOURCE_TO_GROUP,
	ADDED_RESOURCE_TO_GROUP,
	CLEAN_SELECTED_GROUP,
	DELETING_RESOURCE,
	DELETED_RESOURCE,
	DELETING_GROUP,
	DELETED_GROUP,
	BEGIN_RESOURCE_FETCH,
	SUCCESS_RESOURCE_FETCH,
	UPDATING_RESOURCE,
	UPDATED_RESOURCE,
	STOP_LOADING,
} from '../constants/ResourceGroupsConstants.js';
import axios from 'axios';
import { Config } from '../configs';
import { createHashHistory } from 'history';
import { showAlertAction } from './AlertsActions';

export const getGroups = t => {
	return function (dispatch) {
		dispatch(beginGroupsFetch());
		return axios
			.get(Config.groupsUrl())
			.then(response => {
				if (parseInt(response.status / 200) === 1) {
					dispatch(successGroupsFetch(response.data.results));
				}
			})

			.catch(response => {
				dispatch(showAlertAction('Error', 'error'));
				return dispatch({ type: STOP_LOADING });
			});
	};
};

export const beginGroupsFetch = () => ({
	type: BEGIN_GROUPS_FETCH,
	payload: { groupsFetching: true },
});

export const successGroupsFetch = groups => ({
	type: SUCCESS_GROUPS_FETCH,
	payload: { groupsFetching: false, groups },
});

export const addNewGroup = (name, t) => {
	return function (dispatch) {
		dispatch(addingNewGroup());

		return axios
			.put(
				Config.addGroupUrl(name),
				{}
				// , {
				//   headers: {AUTHORIZATION: localStorage.getItem("token")}
				// }
			)
			.then(response => {
				if (parseInt(response.status / 200) === 1) {
					createHashHistory().push('/resource_groups');
					dispatch(successNewGroup());
				}
			})
			.catch(response => {
				if (response.status === 409) {
					dispatch(showAlertAction(t('resourses.exist_name'), 'error'));
					dispatch({ type: STOP_LOADING });
				} else {
					dispatch(showAlertAction('Error', 'error'));
					dispatch({ type: STOP_LOADING });
				}
			});
	};
};

export const addingNewGroup = () => ({
	type: ADDING_NEW_GROUP,
});

export const successNewGroup = () => {
	return function (dispatch) {
		dispatch(getGroups());
		dispatch(addedNewGroup());
	};
};

export const addedNewGroup = () => ({
	type: ADDED_NEW_GROUP,
});

export const getResource = (groupName, resourceId, t) => {
	return function (dispatch) {
		dispatch(beginResourceFetch(groupName));
		return axios
			.get(Config.resourcesUrl(groupName))
			.then(response => {
				if (parseInt(response.status / 200) === 1) {
					response.data = response.data.find(resource => resource.email === resourceId);
					dispatch(successResourceFetch(resourceId, response.data));
				}
			})
			.catch(() => {
				dispatch(showAlertAction('Error', 'error'));
				dispatch({ type: STOP_LOADING });
			});
	};
};

export const beginResourceFetch = () => ({
	type: BEGIN_RESOURCE_FETCH,
	payload: { resourceFetching: true },
});

export const successResourceFetch = (resourceId, resource) => ({
	type: SUCCESS_RESOURCE_FETCH,
	payload: { resourceFetching: false, resourceId, resource },
});

export const getResources = (groupName, t) => {
	return function (dispatch) {
		dispatch(beginResourcesFetch(groupName));
		return axios
			.get(Config.resourcesUrl(groupName))
			.then(response => {
				if (parseInt(response.status / 200) === 1) {
					dispatch(successResourcesFetch(groupName, response.data));
				}
			})
			.catch(() => {
				dispatch(showAlertAction('Error', 'error'));
				dispatch({ type: STOP_LOADING });
			});
	};
};

export const beginResourcesFetch = groupName => ({
	type: BEGIN_RESOURCES_FETCH,
	payload: { selectedGroup: groupName, resourcesFetching: true },
});

export const successResourcesFetch = (groupName, array) => {
	return {
		type: SUCCESS_RESOURCES_FETCH,
		payload: { resourcesFetching: false, resources: array, groupName: groupName },
	};
};

export const updateResource = (groupName, mail, add, replace, remove, t) => {
	let updArrow = [];
	if (replace.length > 0) {
		for (let i = 0; i < replace.length; i++) {
			updArrow.push({ op: 'replace', path: Object.keys(replace[i])[0], value: replace[i][Object.keys(replace[i])] });
		}
	}
	if (add.length > 0) {
		for (let i = 0; i < add.length; i++) {
			updArrow.push({ op: 'add', path: Object.keys(add[i])[0], value: add[i][Object.keys(add[i])] });
		}
	}
	if (remove.length > 0) {
		for (let i = 0; i < remove.length; i++) {
			updArrow.push({ op: 'remove', path: Object.keys(remove[i])[0] });
		}
	}
	return function (dispatch) {
		dispatch(updatingResource());
		return axios
			.patch(Config.updateResourceUrl(groupName, mail), updArrow, { headers: { 'Content-Type': 'application/json-patch+json' } })
			.then(response => {
				if (parseInt(response.status / 200) === 1) {
					createHashHistory().push(`/resource_groups/${groupName}`);
					dispatch(updatedResource());
				}
			})
			.catch(() => {
				dispatch(showAlertAction('Error', 'error'));
				dispatch({ type: STOP_LOADING });
			});
	};
};

export const updatingResource = () => ({
	type: UPDATING_RESOURCE,
});

export const updatedResource = () => ({
	type: UPDATED_RESOURCE,
});

export const addResourceToGroup = (groupName, displayName, mail, domain, l, description, resource_capacity, t) => {
	let email = `${mail}@${domain}`;
	const formData = new FormData();
	if (l !== '') formData.set('l', l);
	if (description !== '') formData.set('description', description);
	formData.set('displayName', displayName);
	formData.set('email', email);
	if (resource_capacity !== '') formData.set('resource_capacity', resource_capacity);
	return function (dispatch) {
		return axios
			.put(Config.groupResourcesUrl(groupName, email), formData)
			.then(response => {
				if (parseInt(response.status / 200) === 1) {
					createHashHistory().push(`/resource_groups/${groupName}`);
					dispatch(addedResourceToGroup());
				}
			})
			.catch(response => {
				if (response.status === 409 && response.data.error.code && response.data.error.code === 102) {
					dispatch(showAlertAction(t('users_page.email_already_exists'), 'error'));
					return dispatch({ type: STOP_LOADING });
				} else if (response.status === 400) {
					dispatch(showAlertAction(t('users_page.required_params'), 'error'));
					return dispatch({ type: STOP_LOADING });
				} else if (response.status === 401) {
					dispatch(showAlertAction(t('users_page.old_token'), 'error'));
					return dispatch({ type: STOP_LOADING });
				} else if (response.status === 403) {
					dispatch(showAlertAction(t('users_page.no_permission'), 'error'));
					return dispatch({ type: STOP_LOADING });
				} else {
					dispatch(showAlertAction('Error', 'error'));
					dispatch({ type: STOP_LOADING });
				}
			});
	};
};

export const addingResourceToGroup = () => ({
	type: ADDING_RESOURCE_TO_GROUP,
	payload: { addingResourceToGroup: true },
});

export const addedResourceToGroup = () => ({
	type: ADDED_RESOURCE_TO_GROUP,
	payload: { addingResourceToGroup: false },
});

export const cleanSelectedGroup = () => ({
	type: CLEAN_SELECTED_GROUP,
	payload: { selectedGroup: '' },
});

export const deleteResource = (resourceMail, groupName, t) => {
	return function (dispatch) {
		dispatch(deletingResource());
		return axios
			.delete(Config.editDeleteResourceUrl(resourceMail, groupName))
			.then(response => {
				dispatch(getResources(groupName, t));
				dispatch(deletedResource(resourceMail));
			})
			.catch(response => {
				dispatch(showAlertAction('Error', 'error'));
				dispatch({ type: STOP_LOADING });
			});
	};
};

export const deletingResource = () => ({
	type: DELETING_RESOURCE,
	payload: { deletingResource: true },
});

export const deletedResource = resourceMail => ({
	type: DELETED_RESOURCE,
	payload: { deletingResource: false, resourceMail },
});

export const deleteGroup = (groupName, t) => {
	return function (dispatch) {
		dispatch(deletingGroup());
		return axios
			.delete(Config.deleteGroupUrl(groupName), {})
			.then(response => {
				createHashHistory().push('/resource_groups');
				dispatch(successDeleteGroup(groupName));
			})
			.catch(response => {
				dispatch(showAlertAction('Error', 'error'));
				dispatch({ type: STOP_LOADING });
			});
	};
};

export const successDeleteGroup = groupName => {
	return function (dispatch) {
		dispatch(getGroups());
		dispatch(deletedGroup(groupName));
	};
};

export const deletingGroup = () => ({
	type: DELETING_GROUP,
	payload: { deletingGroup: true },
});

export const deletedGroup = groupName => ({
	type: DELETED_GROUP,
	payload: { deletingGroup: false, groupName },
});

export const renameGroup = (groupName, replace, t) => {
	let updArrow = [];
	return function (dispatch) {
		if (replace.length > 0) {
			for (let i = 0; i < replace.length; i++) {
				updArrow.push({ op: 'replace', path: Object.keys(replace[i])[0], value: replace[i][Object.keys(replace[i])] });
			}
		}
		return axios
			.patch(Config.renameGroupUrl(groupName), updArrow, { headers: { 'Content-Type': 'application/json-patch+json' } })
			.then(response => {
				if (parseInt(response.status / 200) === 1) {
					getGroups();
					createHashHistory().push('/resource_groups');
					dispatch(getGroups(t));
				}
			})
			.catch(response => {
				if (response.status === 400) {
					dispatch(showAlertAction(t('users_page.required_params'), 'error'));
					return dispatch({ type: STOP_LOADING });
				} else if (response.status === 401) {
					dispatch(showAlertAction(t('users_page.old_token'), 'error'));
					return dispatch({ type: STOP_LOADING });
				} else if (response.status === 403) {
					dispatch(showAlertAction(t('users_page.no_permission'), 'error'));
					return dispatch({ type: STOP_LOADING });
				} else if (response.status === 409) {
					dispatch(showAlertAction(t('resourses.exist_name'), 'error'));
					return dispatch({ type: STOP_LOADING });
				} else {
					dispatch(showAlertAction('Error', 'error'));
					dispatch({ type: STOP_LOADING });
				}
			});
	};
};

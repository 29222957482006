export const AUTHORIZE = "AUTHORIZE";
export const NO_LOGIN_OR_PASSWORD_AUTH = "NO_LOGIN_OR_PASSWORD_AUTH";
export const BEGIN_LOGIN = "BEGIN_LOGIN";
export const LOGGING = "LOGGING";
export const SUCCESS_LOGIN = "SUCCESS_LOGIN";
export const UNSUCCESS_LOGIN = "UNSUCCESS_LOGIN";
export const LOGOUT = "LOGOUT";
export const SELECT_LANGUAGE = "SELECT_LANGUAGE";
export const CLEAN_SELECTED_TENANTMENU = "CLEAN_SELECTED_TENANTMENU";
export const SELECTED_TENANTMENU = "SELECTED_TENANTMENU";
export const NEW_CAPTCHA = "NEW_CAPTCHA";
export const SAVE_LOGIN = "SAVE_LOGIN"
export const BEGIN_TENANTS_FETCH = "BEGIN_TENANTS_FETCH";
export const SUCCESS_TENANTS_FETCH = "SUCCESS_TENANTS_FETCH"
export const BEGIN_TENANT_FETCH = "BEGIN_TENANT_FETCH";
export const SUCCESS_TENANT_FETCH = "SUCCESS_TENANT_FETCH";
export const ADDING_NEW_TENANT = "ADDING_NEW_TENANT";
export const ADDED_NEW_TENANT = "ADDED_NEW_TENANT"; 
export const PREVENT_TENANT_ADD = "PREVENT_TENANT_ADD";
// export const CLEAN_SELECTED_USER = "";
export const UPDATING_TENANT = "UPDATING_TENANT";
export const UPDATED_TENANT = "UPDATED_TENANT";

import React, { useState, useEffect} from "react";
import { NavLink as RouterLink } from "react-router-dom";
import { ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { getSelectedTenantMenu, cleanSelectedTenantMenu } from "../../actions/LoginActions";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux";
import { withTranslation } from "react-i18next";

const SidebarLink = ({
  icon,
  primary,
  to,
  selectedTenantMenuItem,
  selectedTenantMenuTenant,
  tenant,
  t,
  ...props
}) => {
	const history = useHistory();
	const dispatch = useDispatch();
	// const renderLink = React.useMemo(
	//   () =>
	//     React.forwardRef((itemProps, ref) => (
	//       <RouterLink
	//         activeClassName="Mui-selected active"
	//         to={to}
	//         ref={ref}
	//         {...itemProps}
	//       />
	//     ))
	//   ,
	//   [to]
	// );
	// let yyy = t("tenants.tenants_list")

	if (window.pgs_integration & primary ===  t('tenants.settings') ) return null;
	else {
		return (
			<div
				onClick={async () => {
					dispatch(getSelectedTenantMenu(tenant, primary));					
					if (to) {
						if (history.location.pathname === to) {
							await history.push(``);							
							history.push(to);
						} else history.push(to);
					} else {
						props.onClick();
					}
				}}
				className={props.className}
			>
				<ListItem button disableRipple className='menuListItemSideBar' selected={(selectedTenantMenuItem === primary) & (selectedTenantMenuTenant === tenant)}>
					{/* {icon ? <ListItemIcon>{icon}</ListItemIcon> : null} */}
					<ListItemText primary={primary} className='menuListItem.text' />
					{icon && <ListItemIcon>{icon}</ListItemIcon>}
				</ListItem>
			</div>
		);
	}
}


// SidebarLink.propTypes = {
//   icon: PropTypes.element,
//   primary: PropTypes.string.isRequired,
//   to: PropTypes.string.isRequired,
// };

export default SidebarLink;

import React, { useState, useEffect } from "react";
import { getSelectedTenantMenu, cleanSelectedTenantMenu } from "../../actions/LoginActions";
import { useDispatch } from "react-redux";
import { NavLink as RouterLink } from "react-router-dom";
import { ListItem, ListItemIcon, ListItemText,  List, Collapse, IconButton} from "@material-ui/core";
import PropTypes from "prop-types";
import "../../styles/Sidebar.css";
import SidebarLink from "./SidebarLink";
import Up from "../../assets/up.svg";
import { withTranslation } from "react-i18next";

const TenantSettingsLine = ({ ...props }) => {
  const { icon, primary, to, selectedTenantMenuItem, selectedTenantMenuTenant, tenant, t } = props;
  const [open, setOpen] = useState("")
  const dispatch = useDispatch()
  const sidebarItems = [
    {
      title: t("tenants.tenants_list"),
      path: `/tenants`     
    },
    
  ];
  
  const handleArrow = () => {
    setOpen(!open);
    // if (!open) {      
    //   localStorage.setItem(`${tenant[0]}`, tenant[0])
    // } 
    // else localStorage.removeItem(`${tenant[0]}`)   
  };

  return (
		<div>
			<div className='tenantRow'>
				<div className='tenantName' style={{ marginRight: '16px' }}>
					{t('tenants.settings')}
				</div>
				<IconButton className='icon-button' onClick={handleArrow}>
					<img className={open ? 'upArrow' : 'upArrow-rotate'} src={Up} alt={Up} width='24px' />
				</IconButton>
			</div>
			<Collapse in={!open} timeout='auto' unmountOnExit>
				<List component='div' disablePadding>
					{sidebarItems.map((sidebarItem, index) => (
						<SidebarLink
							to={sidebarItem.path}
							primary={sidebarItem.title}
							t={t}
							selectedTenantMenuItem={selectedTenantMenuItem}
							selectedTenantMenuTenant={selectedTenantMenuTenant}
							onClick={() => dispatch(getSelectedTenantMenu(localStorage.getItem('tenant'), sidebarItem.title))}
							tenant={tenant}
						/>
					))}
				</List>
			</Collapse>
		</div>
	);
};

// SidebarLink.propTypes = {
//   icon: PropTypes.element,
//   primary: PropTypes.string.isRequired,
//   to: PropTypes.string.isRequired,
// };

export default TenantSettingsLine;
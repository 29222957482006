import React from 'react';
import {
	TextField,
	Button,
	MenuItem,
	Tooltip,
	IconButton,
	Dialog,
	DialogContent,
	DialogActions,
	DialogContentText,
	DialogTitle,
	Slide,
	Table,
	TableBody,
	TableRow,
	TableCell,
	InputAdornment,
	Avatar,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { updateUser, getUser, deleteUser, getCalendars, transfCalendars, removeCalendarStatus } from '../../actions/UsersActions.js';
import { getDomains } from '../../actions/DomainsActions';
import { blue, green, orange, purple, red, pink, deepPurple, indigo, cyan, lightGreen } from '@material-ui/core/colors';
import { connect } from 'react-redux';
import copy from 'copy-to-clipboard';
import CopyIcon from '../../assets/copyIcon.svg';
import GoBack from '../../assets/back.svg';
import Trash from '../../assets/trashGreyDark.svg';
import TrashRed from '../../assets/trashRed.svg';
import Plus from '../../assets/plus.svg';
import GreyPlus from '../../assets/GreyPlus.svg';
import DarkTooltip from '../GeneralElements/DarkTooltip.js';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { createHashHistory } from 'history';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import AvatarUploader from '../Avatars/AvatarUploader.js';
import { withTranslation } from 'react-i18next';
import { showAlertAction } from '../../actions/AlertsActions';
import PersonInfo from './PersonInfo.js';
import Contacts from './Contacts.js';
import PassFields from './PassFields.js';
import DeleteAlias from './DeleteAlias.js';
import DeleteUser from './DeleteUser.js';
import BlockUser from './BlockUser.js';
import ErrorToast from './ErrorToast.js';
import CalendarManager from './CalendarManager.js';
import BackArrow from '../../assets/backArrow.svg';
import axios from 'axios';
import { Config } from '../../configs';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction='up' ref={ref} {...props} />;
});

const styles = theme => ({
	root: {
		width: '100%',
		backgroundColor: 'white',
		minHeight: '100vh',
		boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)',
	},
	passHidden: {
		fontSize: '14px !important',
		fontFamily: 'text-security-disc',
		'-webkit-text-security': 'disc',
		'-moz-text-security': 'disc',
		'text-security': 'disc',
	},
	addUserBtn: {
		width: '168px',
		height: '32px',
		paddingLeft: '0px',
		marginTop: '5px',
		marginLeft: '20px',
		color: '#19252E',
		// backgroundColor: "#F5F5F5",
		fontStyle: 'normal',
		fontWeight: '500',
		fontSize: '14px',
		lineHeight: '24px',
		letterSpacing: '0.35px',
		border: '1px solid rgba(25, 37, 46, 0.4)',
		textTransform: 'none',
		cursor: 'pointer',
		'&:hover': {
			backgroundColor: 'rgba(25, 37, 46, 0.08) !important',
			color: '#19252E',
			border: '1px solid rgba(25, 37, 46, 0.08)',
		},
		'&:disabled': {
			backgroundColor: 'rgba(25, 37, 46, 0.08) !important',
			color: '#A3A8AB',
			border: '1px solid rgba(25, 37, 46, 0.08)',
			cursor: 'pointer',
		},
		// "&:selected": {
		//   backgroundColor: "#BFDDF3 !important",
		//   color: "#0079D0",
		//   // border: "1px solid rgba(25, 37, 46, 0.08)",
		// },
		// "&:active": {
		//   backgroundColor: "#BFDDF3 !important",
		//   color: "#0079D0",
		//   // border: "1px solid rgba(25, 37, 46, 0.08)",
		// },
	},
	transButton: {
		width: '99px',
		height: '32px',
		paddingLeft: '0px',
		marginBottom: '62px',
		// marginLeft: "40px",
		color: '#19252E',
		// backgroundColor: "#F5F5F5",
		fontStyle: 'normal',
		fontWeight: '500',
		fontSize: '14px',
		lineHeight: '24px',
		letterSpacing: '0.35px',
		border: '1px solid rgba(25, 37, 46, 0.4)',
		textTransform: 'none',
		cursor: 'pointer',
		'&:hover': {
			backgroundColor: 'rgba(25, 37, 46, 0.08) !important',
			color: '#19252E',
			border: '1px solid rgba(25, 37, 46, 0.08)',
		},
		'&:disabled': {
			backgroundColor: 'rgba(25, 37, 46, 0.08) !important',
			color: '#A3A8AB',
			border: '1px solid rgba(25, 37, 46, 0.08)',
			cursor: 'pointer',
		},
	},
	refreshButton: {
		marginLeft: '47px',
		marginTop: '8px',
		color: '#19252E',
		backgroundColor: 'rgba(68, 187, 255, 0.56)',
		fontWeight: '550',
		width: '99px',
		height: '32px',
		textTransform: 'none',
		fontSize: '14px',
		lineHeight: '24px',
		letterSpacing: '0.35px',
		'&:hover': {
			backgroundColor: 'rgba(68, 187, 255, 0.72)',
			color: '#19252E',
		},
		'&:active': {
			backgroundColor: 'red(68, 187, 255, 0.88)',
			color: '#19252E',
		},
	},
	RoundTooltip: {
		width: '32px',
		height: '32px',
		marginTop: '16px',
		'&:hover': {
			backgroundColor: 'rgba(25, 37, 46, 0.08); !important',
		},
		'&:active': {
			backgroundColor: 'rgba(25, 37, 46, 0.16); !important',
		},
	},
	inputAliasEmail: {
		fontSize: '14px !important',
		lineHeight: '24px',
	},
	inputAliasEmailError: {
		fontSize: '14px !important',
		lineHeight: '24px',
		color: '#E2333A',
	},
});

const mapStateToProps = state => ({
	calendars: state.users.calendars,
	calendars_status: state.users.calendars_status,
	transf_status: state.users.transf_status,
	transf_code: state.users.transf_code,
	calendarsFetching: state.users.calendarsFetching,
	calendarsTransfering: state.users.calendarsTransfering,
	domains: state.domains.domains,
	domainsFetching: state.domains.domainsFetching,
	user: state.users.user,
	updatingUser: state.users.updatingUser,
	upd_status: state.users.upd_status,
	upd_code: state.users.upd_code,
	userFetching: state.users.userFetching,
	deletingUser: state.resourceGroups.deletingUser,
});

const mapDispatchToProps = dispatch => ({
	getDomains: () => dispatch(getDomains()),
	getCalendars: mail => dispatch(getCalendars(mail)),
	transfCalendars: (mail, replace, t) => dispatch(transfCalendars(mail, replace, t)),
	updateUser: (mail, add, replace, remove, t, avatar) => dispatch(updateUser(mail, add, replace, remove, t, avatar)),
	getUser: (email, t) => dispatch(getUser(email, t)),
	deleteUser: (userMail, t) => dispatch(deleteUser(userMail, t)),
	showAlert: (message, type) => dispatch(showAlertAction(message, type)),
});

class UserForm extends React.Component {
	static propTypes = {
		match: PropTypes.object.isRequired,
		location: PropTypes.object.isRequired,
		history: PropTypes.object.isRequired,
	};

	constructor(props) {
		super(props);
		this.state = {
			cn: '',
			sn: '',
			l: '',
			ou: '',
			domain: '',
			initials: '',
			title: '',
			description: '',
			telephoneNumber: '',
			labeledURI: '',
			// info: '',
			homePhone: '',
			email: '',
			altEmail: '',
			altDomain: '',
			employeeNumber: '',
			aliases: [],
			aliasIndex: [],
			aliasIndexWrongSymb: [],
			aliasIndexExtraDot: [],
			aliasMaxSymb: false,
			aliasErrorMail: false,
			aliasDelete: false,
			indexOfAliasDelete: '',
			userPassword: '',
			passwordConfirm: '',
			passwordError: false,
			errorWindow: false,
			croppedAvatar: '',
			trashWindow: false,
			blockWindow: false,
			quota: '',
			displayName: '',
			showCalendarManager: false,
			ErrorCalToast: false,
			telephoneError: false,
			disableButton: [],
		};
		this.recData = {};
		this.recDataAli = {};
		this.keysForUpd = [
			'sn',
			'cn',
			'l',
			'ou',
			'initials',
			'title',
			'description',
			'telephoneNumber',
			'homePhone',
			'employeeNumber',
			'alias',
			'userPassword',
			'quota',
			'displayName',
		];
		this.handleUserSubmit = this.handleUserSubmit.bind(this);
		this.handleAddAlias = this.handleAddAlias.bind(this);
		this.handleAliasEmailChange = this.handleAliasEmailChange.bind(this);
		this.handleAliasDomainChange = this.handleAliasDomainChange.bind(this);
		this.handleRemoveAlias = this.handleRemoveAlias.bind(this);
		this.history = createHashHistory();
		this.handleQuotaChange = this.handleQuotaChange.bind(this);
	}

	componentDidMount() {
		localStorage.removeItem('aliases');
		this.props.getDomains();
		let email = this.props.match.params.email;
		let displayName = this.props.user.displayName;
		this.props.getUser(email);
	}

	handleAvatarCrop = async avatarUrl => {
		let avatarBlob = await fetch(avatarUrl).then(r => r.blob());
		this.setState({ croppedAvatar: avatarBlob });
		let avatarForm = new FormData();
		avatarForm.append('file', avatarBlob);
		axios.put(Config.avatarUrl(this.props.match.params.email), avatarForm);
	};

	async componentDidUpdate(prevProps, prevState) {
		let defaultState = {};
		let recDataForUpd = {};
		if (this.props.user !== prevProps.user) {
			this.props.getCalendars(this.props.match.params.email);
			for (let i in this.keysForUpd) {
				let userField = this.keysForUpd[i];
				let currentUser = this.props.user;
				if (Object.keys(currentUser).includes(userField) && userField !== 'alias' && currentUser[userField].length > 0) {
					recDataForUpd[userField] = currentUser[userField];
				} else if (Object.keys(currentUser).includes(userField) && userField !== 'alias') recDataForUpd[userField] = '';
				if (Object.keys(currentUser).includes(userField) && userField === 'alias') {
					for (let i in currentUser.alias) {
						recDataForUpd[`alias${i}`] = currentUser.alias[i];
						this.recDataAli[`alias${i}`] = currentUser.alias[i];
					}
				}
				if (Object.keys(currentUser).includes(userField) && userField === 'quota') {
					recDataForUpd.quota =
						this.props.user.quota.size_limit === '-' ? '' : parseFloat((this.props.user.quota.size_limit / 1024).toFixed(2));
				}
			}
			this.recData = recDataForUpd;
			let userFields = [
				'mail',
				'sn',
				'cn',
				'altDomain',
				'altEmail',
				'l',
				'ou',
				'initials',
				'title',
				'description',
				'telephoneNumber',
				'labeledURI',
				'displayName',
				'homePhone',
				'employeeNumber',
				'aliases',
				'userPassword',
				'quota',
			];
			for (let i in userFields) {
				let userField = userFields[i];
				let currentUser = this.props.user;
				let userFieldValue = currentUser[userField];
				if (userFieldValue !== undefined) {
					defaultState[userField] = userFieldValue;
				}
			}

			for (let i in this.props.user.alias) {
				let recievedAlias = this.props.user.alias[i];
				let alias = {};
				alias['email'] = recievedAlias.split('@')[0];
				alias['domain'] = recievedAlias.split('@')[1];
				alias['num'] = i;
				alias['domains'] = this.props.domains.map(el => {
					return { key: this.uniq_id + el.name, value: el.name };
				});
				this.state.aliases.push(alias);
			}
			defaultState.storageUsed = parseFloat((this.props.user.quota.size_value / 1024).toFixed(2));
			defaultState.quota = isNaN(this.props.user.quota.size_limit / 1024)
				? ''
				: parseFloat((this.props.user.quota.size_limit / 1024).toFixed(2));
			defaultState['email'] = this.props.user.mail.split('@')[0];
			defaultState['domain'] = this.props.user.mail.split('@')[1];
			if (this.props.user.mailAlternateAddress && typeof this.props.user.mailAlternateAddress === 'string') {
				defaultState['altEmail'] = this.props.user.mailAlternateAddress.split('@')[0];
				defaultState['altDomain'] = this.props.user.mailAlternateAddress.split('@')[1];
			}
			this.setState(defaultState);
		}

		let curDataAli = {};
		if (Object.keys(this.state).includes('aliases') && this.state.aliases.length > 0) {
			for (let i in this.state.aliases) {
				curDataAli[`alias${i}`] = `${this.state.aliases[i].email}@${this.state.aliases[i].domain}`;
			}
		}
		if (Object.keys(curDataAli).length !== Object.keys(this.recDataAli).length) {
			this.state.disableButton.push('aliases');
		} else this.state.disableButton = this.state.disableButton.filter(e => e !== 'aliases');
	}

	handleUserSubmit(e, t) {
		e.preventDefault();
		let mail = `${this.state.email}@${this.state.domain}`;
		let curData = {};

		for (let i in this.keysForUpd) {
			let userField = this.keysForUpd[i];

			if (Object.keys(this.state).includes(userField) && userField !== 'alias') {
				if (userField === 'quota' && !this.state[userField]) curData[userField] = '0';
				else if (this.state[userField].length > 0) {
					curData[userField] = this.state[userField];
				} else if (this.state[userField].length === 0) {
					curData[userField] = '';
				}
			}
		}
		if (Object.keys(this.state).includes('aliases') && this.state.aliases.length > 0) {
			for (let i in this.state.aliases) {
				if (this.state.aliases[i].num) {
					curData[`alias${this.state.aliases[i].num}`] = `${this.state.aliases[i].email}@${this.state.aliases[i].domain}`;
				} else curData[`alias${i}`] = `${this.state.aliases[i].email}@${this.state.aliases[i].domain}`;
			}
		}

		const add = [];
		const replace = [];
		const remove = [];

		for (let key1 in this.recData) {
			if (key1.includes('alias') && !Object.keys(curData).includes(key1)) {
				let dataObj = {};
				dataObj[`/${key1}`] = this.recData[key1];
				remove.push(dataObj);
			} else if (this.recData[key1] && !curData[key1]) {
				let dataObj = {};
				dataObj[`/${key1}`] = this.recData[key1];
				remove.push(dataObj);
			}
		}
		for (let key2 in curData) {
			if (!this.recData[key2] && curData[key2] && key2 !== 'userPassword') {			
				let dataObj = {};
				dataObj[`/${key2}`] = curData[key2];
				add.push(dataObj);				
			} else if (!this.recData[key2] && curData[key2] && key2 === 'userPassword') {
				let dataObj = {};
				dataObj[`/${key2}`] = curData[key2];
				replace.push(dataObj);
			}			
			for (let key1 in this.recData) {
				if (key1 === key2) {
					if (this.recData[key1] && curData[key2] && this.recData[key1] !== curData[key2]) {
						let dataObj = {};
						if (key1.includes('alias')) {
							dataObj[`/${key2}`] = `${this.recData[key1]},${curData[key2]}`;
						} else dataObj[`/${key2}`] = `${curData[key2]}`;
						replace.push(dataObj);
					}
				}
			}			
		}
		
		this.props.updateUser(mail, add, replace, remove, t, this.state.croppedAvatar);
	}

	handleQuotaChange(e) {
		e.preventDefault();
		let valueX = e.target.value;
		let quotaToCompare = parseFloat((this.recData.quota / 1024).toFixed(2));
		if (valueX.length < 8) {
			if (valueX.match(/^\d*[,/.]?\d*$/)) {
				valueX = valueX.replace(/,/g, '.');
				valueX = valueX.replace(/\//g, '');
				this.setState({ quota: valueX });
				if (e.target.value != quotaToCompare && !this.state.disableButton.includes('quota')) this.state.disableButton.push('quota');
				else if (e.target.value == quotaToCompare) this.state.disableButton = this.state.disableButton.filter(e => e !== 'quota');
			}
		}
	}

	handleAliasEmailChange(e, index) {
		e.preventDefault();
		let value = e.target.value;
		if (value) this.setState({ aliasIndex: this.state.aliasIndex.filter(el => el !== index) });
		if (localStorage.getItem('aliases') && localStorage.getItem('aliases').includes(value)) {
			localStorage.removeItem('aliases');
		}
		const tester = /^[-0-9A-Za-z._+]+$/;
		let validated = value.match(tester);
		if (!validated & (value !== '')) {
			this.state.aliasIndexWrongSymb.push(index);
		} else {
			this.setState({ aliasIndexWrongSymb: this.state.aliasIndexWrongSymb.filter(el => el !== index) });
		}
		if (value.length > 255) {
			this.setState({ aliasMaxSymb: true });
		} else {
			this.setState({ aliasMaxSymb: false });
		}
		let currentAliases = this.state.aliases;
		currentAliases[index].email = value;
		this.setState(currentAliases);
	}

	handleAliasEmailBlur(e, index) {
		e.preventDefault();
		let value = e.target.value;
		if (value.length === 0) {
			this.state.aliasIndex.push(index);
		} else {
			this.setState({ aliasIndexWrongSymb: this.state.aliasIndex.filter(el => el !== index) });
		}
		if (/^\.{1,}/g.test(value) || /\.{1,}$/g.test(value) || /\.{2,}/g.test(value) & (value !== '')) {
			this.state.aliasIndexExtraDot.push(index);
		} else {
			this.setState({ aliasIndexExtraDot: this.state.aliasIndexExtraDot.filter(e => e !== index) });
		}
	}

	handleAliasDomainChange(e, index) {
		e.preventDefault();
		let value = e.target.value;
		let currentAliases = this.state.aliases;
		currentAliases[index].domain = value;
		this.setState(currentAliases);
	}

	handleRemoveAlias(e, index) {
		e.preventDefault();
		let currentAliases = this.state.aliases;
		currentAliases.splice(index, 1);
		this.setState({ aliasIndexWrongSymb: this.state.aliasIndexWrongSymb.filter(el => el !== index) });
		this.setState({ aliasIndex: this.state.aliasIndex.filter(el => el !== index) });
		this.setState({ aliasIndexExtraDot: this.state.aliasIndexExtraDot.filter(e => e !== index) });
		this.setState({ aliases: currentAliases });
	}
	handleAddAlias(e) {
		e.preventDefault();
		let currentAliases = this.state.aliases;
		let domains = this.props.domains;
		let indexedDomains = domains.map(el => {
			this.uniq_id += 1;
			return { key: this.uniq_id + el.name, value: el.name };
		});
		currentAliases.push({
			domains: indexedDomains,
			key: Date.now() + '',
			domain: indexedDomains[0].value,
			email: '',
		});
		this.setState({ aliases: currentAliases });
	}

	handleUserDelete = async (calendars, t) => {
		this.props.deleteUser(this.state.email + '@' + this.state.domain);
		if (calendars.length > 0) {
			this.props.showAlert(t('users_page.deleted_user_with_calendars', { cn: this.state.cn, sn: this.state.sn }), 'admin_toast_success');
		}
		if (calendars.length === 0)
			this.props.showAlert(t('users_page.deleted_user', { cn: this.state.cn, sn: this.state.sn }), 'admin_toast_success');
		this.setState({ trashWindow: false });
		this.props.history.push('/users');
	};

	handleUserBlock = async (newUser, calendars, t) => {
		let mail = `${this.state.email}@${this.state.domain}`;
		const replace = [];
		let dataObj = {};
		dataObj[`/inetUserStatus`] = this.props.user.inetUserStatus === '0' ? '1' : '0';
		replace.push(dataObj);
		let messageForManyCalendars, messageSuccessBlock, messageSuccessUnblock;
		await this.props.updateUser(mail, [], replace, [], t, this.state.croppedAvatar);
		messageSuccessUnblock = `${t('users_page.success_unblock', { cn: this.state.cn, sn: this.state.sn })} ${t(
			'users_page.success_unblock_2'
		)}`;
		if (calendars?.length > 0) {
			messageForManyCalendars = `${t('users_page.transfered_many_calendars', { newUser: newUser })}`;
			messageSuccessBlock = `${t('users_page.success_block', { cn: this.state.cn, sn: this.state.sn })} ${messageForManyCalendars}`;
			if (this.props.user.inetUserStatus !== '0') {
				this.props.showAlert(messageSuccessBlock, 'admin_toast_success');
				this.setState({ blockWindow: false });
			} else {
				this.props.showAlert(messageSuccessUnblock, 'admin_toast_success');
				this.setState({ blockWindow: false });
			}
		} else {
			messageSuccessBlock = `${t('users_page.success_block', { cn: this.state.cn, sn: this.state.sn })}`;
			if (this.props.user.inetUserStatus !== '0') {
				this.props.showAlert(messageSuccessBlock, 'admin_toast_success');
				this.setState({ blockWindow: false });
			} else {
				this.props.showAlert(messageSuccessUnblock, 'admin_toast_success');
				this.setState({ blockWindow: false });
			}
		}
	};

	render() {
		const colors = [
			blue[300],
			green[300],
			orange[500],
			purple[300],
			red[300],
			pink[300],
			purple[300],
			deepPurple[400],
			indigo[500],
			blue[500],
			cyan[500],
			lightGreen[500],
		];
		const getColor = index => {
			return colors[Math.floor(index % 10)];
		};
		const { classes, t } = this.props;
		return (
			<div className={classes.root}>
				{this.props.userFetching || (this.props.updatingUser && !this.state.blockWindow) ? (
					<Grid
						container
						spacing={0}
						direction='column'
						alignItems='center'
						// style={{ minHeight: "100vh" }}
					>
						<Grid item xs={3}>
							<CircularProgress />
						</Grid>
					</Grid>
				) : (
					<div
						style={{
							paddingBottom: '0.1px',
							paddingTop: '0.1px',
							// overflow: "scroll",
							// maxHeight: "100vh",
						}}
					>
						<div className='fixedTop'>
							<div className='backButton' onClick={e => this.history.push('/users')}>
								<img src={BackArrow} alt={BackArrow} width='24px' />
								<div className='backButtonTitle'>{t('back')}</div>
							</div>
							<div style={{ display: 'flex', marginLeft: 'auto', marginRight: '24px' }}>
								<DarkTooltip
									title={<>{t('users_page.forbid_block')}</>}
									placement='bottom-start'
									hidden={this.props.user.mail !== localStorage.getItem('currentAcc')}
								>
									<div
										className={
											this.props.user.mail === localStorage.getItem('currentAcc') ||
											(window.pgs_integration && this.props.user.inetUserStatus !== '0')
												? 'blockButtonDisabled'
												: 'blockButton'
										}
										style={{ width: this.props.user.inetUserStatus === '0' ? '199px' : '138px' }}
										onClick={() => {
											if (
												(this.props.user.mail !== localStorage.getItem('currentAcc') &&
													window.pgs_integration &&
													this.props.user.inetUserStatus === '0') ||
												(this.props.user.mail !== localStorage.getItem('currentAcc') && !window.pgs_integration)
											) {
												this.props.getCalendars(this.props.match.params.email).then(() => {
													if (this.props.calendars_status === 200) {
														this.setState({ ErrorCalToast: false });
													} else this.setState({ ErrorCalToast: true });
												});
												this.setState({ blockWindow: true });
											}
										}}
									>
										<div className='blockButtonText'>
											{this.props.user.inetUserStatus !== '0' ? t('users_page.block') : t('users_page.unblock_settings')}
										</div>
									</div>
								</DarkTooltip>
								<DarkTooltip
									title={<>{t('users_page.forbid_delete')}</>}
									placement='bottom-start'
									hidden={this.props.user.mail !== localStorage.getItem('currentAcc')}
								>
									<div
										className={
											window.pgs_integration || this.props.user.mail === localStorage.getItem('currentAcc')
												? 'delButtonDisabled'
												: 'delButton'
										}
										style={{ marginLeft: '16px' }}
										onClick={() => {
											if (!window.pgs_integration && this.props.user.mail !== localStorage.getItem('currentAcc')) {
												this.props.getCalendars(this.props.match.params.email).then(() => {
													if (this.props.calendars_status === 200) {
														this.setState({ ErrorCalToast: false });
													} else this.setState({ ErrorCalToast: true });
												});
												this.setState({ trashWindow: true });
											}
										}}
									>
										<div className='blockButtonText'>{t('users_page.delete')}</div>
									</div>
								</DarkTooltip>
							</div>
						</div>

						<div className='mainInfoAva' style={{ marginTop: '84px', marginBottom: '64px' }}>
							<div className='avaAndLogin'>
								<AvatarUploader
									onAvatarCrop={this.handleAvatarCrop}
									email={`${this.state.email}@${this.state.domain}`}
									displayName={`${this.state.cn} ${this.state.sn}`}
									inetUserStatus={this.props.user.inetUserStatus}
								/>
								<Table>
									<TableBody>
										{/* __________________________________MAIN_LOGIN________________________________________ */}
										<TableRow>
											<TableCell className='userTableCell'>
												<div className='userNameTitle'>{this.props.user.cn}</div>
												<div className='loginRaw'>
													<div>
														<div className='fieldLabel'>{t('login')}</div>
														<DarkTooltip
															title={<>{t('users_page.tooltip_disabled_fields')}</>}
															placement='bottom-start'
															hidden={!window.pgs_integration}
														>
															<TextField
																variant='outlined'
																className='loginField'
																style={{ width: '270px' }}
																value={this.state.email}
																onChange={e => this.setState({ email: e.target.value })}
																disabled
															/>
														</DarkTooltip>
													</div>

													<div>
														<div className='mainEmailLabel' style={{ opacity: '0.5' }}>
															@
														</div>
													</div>
													<div>
														<div className='fieldLabel'></div>
														<DarkTooltip
															title={<>{t('users_page.tooltip_disabled_fields')}</>}
															placement='bottom-start'
															hidden={!window.pgs_integration}
														>
															<TextField
																select
																variant='outlined'
																className='domainField'
																value={this.state.domain ? this.state.domain : ''}
																onChange={e => this.setState({ domain: e.target.value })}
																disabled
															>
																{this.props.domains ? (
																	this.props.domains.map((option, key) => (
																		<MenuItem className='options' key={'-1' + key} value={option.name}>
																			{option.name}
																		</MenuItem>
																	))
																) : (
																	<div></div>
																)}
															</TextField>
														</DarkTooltip>
													</div>
												</div>
											</TableCell>
										</TableRow>

										{/* __________________________________ALT_LOGIN________________________________________ */}
										<TableRow style={{ display: window.enable_alternative_login && this.state.altEmail ? 'contents' : 'none' }}>
											<TableCell className='userTableCell'>
												<div className='loginRaw'>
													<div>
														<div className='fieldLabel'>{t('users_page.alt_login')}</div>
														<DarkTooltip
															title={<>{t('users_page.tooltip_disabled_fields')}</>}
															placement='bottom-start'
															hidden={!window.pgs_integration}
														>
															<TextField
																className='loginField'
																variant='outlined'
																style={{ width: '262px' }}
																value={this.state.altEmail}
																onChange={e => this.setState({ altEmail: e.target.value })}
																disabled
															/>
														</DarkTooltip>
													</div>

													<div>
														<div className='mainEmailLabel' style={{ opacity: '0.5' }}>
															@
														</div>
													</div>
													<div
														style={{
															color: 'rgba(22, 33, 41, 0.56)',
															display: 'inherit',
															padding: 0,
															fontSize: '12px',
															width: '216px',
														}}
													>
														<div>
															<div className='fieldLabel'></div>
															<DarkTooltip
																title={<>{t('users_page.tooltip_disabled_fields')}</>}
																placement='bottom-start'
																hidden={!window.pgs_integration}
															>
																<TextField
																	select
																	variant='outlined'
																	className='domainField'
																	value={this.state.altDomain ? this.state.altDomain : ''}
																	// disabled={this.props.domainsFetching}
																	disabled
																	onChange={e => this.setState({ altdomain: e.target.value })}
																>
																	{this.props.domains ? (
																		this.props.domains.map((option, key) => (
																			<MenuItem className='options' key={'-1' + key} value={option.name}>
																				{option.name}
																			</MenuItem>
																		))
																	) : (
																		<div></div>
																	)}
																</TextField>
															</DarkTooltip>
														</div>
													</div>
												</div>
											</TableCell>
										</TableRow>
										{/* __________________________________ROLE_&&_QUOTA________________________________________ */}
										<TableRow>
											<TableCell className='userTableCell'>
												<div className='loginRaw'>
													<div>
														<div className='fieldLabel'>{t('role')}</div>
														<DarkTooltip
															title={<>{t('users_page.tooltip_disabled_fields')}</>}
															placement='bottom-start'
															hidden={!window.pgs_integration ? true : false}
														>
															<TextField
																disabled={window.pgs_integration}
																select
																variant='outlined'
																className='domainField'
																value={this.state.employeeNumber}
																onChange={e => {
																	this.setState({ employeeNumber: e.target.value });
																	if (!e.target.value && typeof this.recData.employeeNumber !== 'string')
																		this.state.disableButton = this.state.disableButton.filter(e => e !== 'employeeNumber');
																	else if (
																		e.target.value != this.recData.employeeNumber &&
																		!this.state.disableButton.includes('employeeNumber')
																	)
																		this.state.disableButton.push('employeeNumber');
																	else if (e.target.value == this.recData.employeeNumber)
																		this.state.disableButton = this.state.disableButton.filter(e => e !== 'employeeNumber');
																}}
															>
																{[
																	{ key: '2', value: t('user') },
																	{ key: '1', value: t('admin') },
																].map(option => (
																	<MenuItem className='options' key={option.key} value={option.key}>
																		{option.value}
																	</MenuItem>
																))}
															</TextField>
														</DarkTooltip>
													</div>

													<div style={{ marginLeft: '48px' }}>
														<div className='fieldLabel asterisk'>{t('quota')}</div>
														<TextField
															className='loginField'
															style={{ width: '262px' }}
															name='quota'
															variant='outlined'
															autoComplete='cc-number'
															InputProps={{
																startAdornment: (
																	<InputAdornment style={{ fontSize: '14px', width: '40%' }} position='start'>
																		{this.state.storageUsed} /
																	</InputAdornment>
																),
																endAdornment: <InputAdornment className='MBfield'>Mb</InputAdornment>,
															}}
															// type="number"
															value={this.state.quota}
															onChange={this.handleQuotaChange}
														/>
													</div>
												</div>
											</TableCell>
										</TableRow>
									</TableBody>
								</Table>
							</div>
							<PassFields
								setUserPassword={v => this.setState({ userPassword: v })}
								setPasswordConfirm={v => this.setState({ passwordConfirm: v })}
								setPasswordError={v => this.setState({ passwordError: v })}
								edit={true}
								t={t}
							/>

							{/* ___________________________________ALIASES________________________________________ */}
							<div>
								<div className='addNewUserForm' style={{ display: 'flex', flexDirection: 'row' }}>
									<div style={{ marginTop: '8px' }}>{t('alias.aliases')}</div>
									{this.state.aliases.length === 10 ? (
										<DarkTooltip title={<>{t('alias.max_aliases')}</>}>
											<div>
												<Button
													disableRipple
													disabled={this.state.aliases.length === 10}
													className={this.props.classes.addUserBtn}
													onClick={this.handleAddAlias}
												>
													<div style={{ paddingLeft: '0px', display: 'flex' }}>
														<img src={this.state.aliases.length === 10 ? GreyPlus : Plus} alt={Plus} />
														<div style={{ marginLeft: '10px' }}>{t('alias.add_mail')}</div>
													</div>
												</Button>
											</div>
										</DarkTooltip>
									) : (
										<Button disableRipple className={this.props.classes.addUserBtn} onClick={this.handleAddAlias}>
											<div style={{ paddingLeft: '0px', display: 'flex' }}>
												<img src={Plus} alt={Plus} />
												<div style={{ marginLeft: '10px' }}>{t('alias.add_mail')}</div>
											</div>
										</Button>
									)}
								</div>

								{this.state.aliases.map((alias, index) => (
									<div>
										<div className='loginRaw' style={{ marginTop: '36px' }}>
											<div>
												<div className='fieldLabel'>{t('alias.label')}</div>
												<TextField
													variant='outlined'
													style={{ width: '340px' }}
													className={
														(localStorage.getItem('aliases') && localStorage.getItem('aliases').includes(alias.email)) ||
														this.state.aliasIndexExtraDot.includes(index) ||
														this.state.aliasMaxSymb ||
														this.state.aliasIndexWrongSymb.includes(index) ||
														this.state.aliasIndex.includes(index) ||
														this.state.aliasErrorMail
															? 'loginFieldError'
															: 'loginField'
													}
													value={alias.email}
													InputProps={{
														className:
															(localStorage.getItem('aliases') && localStorage.getItem('aliases').includes(alias.email)) ||
															this.state.aliasIndexExtraDot.includes(index) ||
															this.state.aliasMaxSymb ||
															this.state.aliasIndexWrongSymb.includes(index) ||
															this.state.aliasErrorMail
																? classes.inputAliasEmailError
																: classes.inputAliasEmail,
													}}
													onChange={e => this.handleAliasEmailChange(e, index)}
													onBlur={e => this.handleAliasEmailBlur(e, index)}
												/>
											</div>

											<div
												style={{
													marginLeft: '16px',
													marginTop: '25px',
													color:
														localStorage.getItem('aliases') && localStorage.getItem('aliases').includes(alias.email)
															? '#E2333A'
															: '#000000',
												}}
											>
												@
											</div>
											<div>
												<div
													style={{
														color: 'rgba(22, 33, 41, 0.56)',
														display: 'inherit',
														fontSize: '12px',
														width: '340px',
														marginLeft: '16px',
														marginTop: '10px',
													}}
												>
													<TextField
														select
														variant='outlined'
														className={
															localStorage.getItem('aliases') && localStorage.getItem('aliases').includes(alias.email)
																? 'domainCellError'
																: 'domainCell'
														}
														style={{ paddingRight: 0 }}
														value={alias.domain}
														InputProps={{
															className:
																localStorage.getItem('aliases') && localStorage.getItem('aliases').includes(alias.email)
																	? classes.inputAliasEmailError
																	: classes.inputAliasEmail,
														}}
														disabled={this.props.domainsFetching}
														onChange={e => this.handleAliasDomainChange(e, index)}
													>
														{alias.domains.map((option, key) => (
															<MenuItem className='options' key={option.key} value={option.value}>
																{option.value}
															</MenuItem>
														))}
													</TextField>
												</div>
											</div>
											<div style={{ marginLeft: '30px', marginTop: '8px' }}>
												<Tooltip title={t('alias.copy')}>
													<IconButton
														aria-label='Remove'
														onClick={e => {
															if (alias.email && alias.domain) {
																let aliasToCopy = `${alias.email}@${alias.domain}`;
																navigator.clipboard.writeText(aliasToCopy);
															}
															let aliasToCopy = `${alias.email}@${alias.domain}`;
															navigator.clipboard.writeText(aliasToCopy);
															this.props.showAlert(t('users_page.email_copied'), 'admin_toast_success');
														}}
														className={this.props.classes.RoundTooltip}
													>
														<img src={CopyIcon} alt={CopyIcon} style={{ color: 'red' }} />
													</IconButton>
												</Tooltip>
											</div>
											<div style={{ marginLeft: '10px', marginTop: '8px' }}>
												<Tooltip title={t('alias.remove')}>
													<IconButton
														aria-label='Remove'
														onClick={e =>
															this.setState({
																aliasDelete: true,
																indexOfAliasDelete: index,
															})
														}
														className={this.props.classes.RoundTooltip}
													>
														<img src={Trash} alt={Trash} />
													</IconButton>
												</Tooltip>
											</div>
											<DeleteAlias
												open={this.state.aliasDelete}
												setOpen={v => this.setState({ aliasDelete: v })}
												title={t('alias.remove_title')}
												body={t('alias.remove_body')}
												t={t}
												mainButton={{
													action: e => {
														this.handleRemoveAlias(e, this.state.indexOfAliasDelete);
														this.setState({ aliasDelete: false });
													},
												}}
											/>
										</div>
										<div
											className='underLoginFieldError'
											style={{
												display: this.state.aliases[index].email.length > 255 ? 'block' : 'none',
											}}
										>
											{t('alias.max_symb')}
										</div>
										<div
											className='underLoginFieldError'
											style={{
												display:
													this.state.aliasIndexWrongSymb.includes(index) && this.state.aliases[index].email !== '' ? 'block' : 'none',
											}}
										>
											{t('alias.wrong_symb')}
										</div>
										<div
											className='underLoginFieldError'
											style={{
												display: this.state.aliases[index].email === '' && this.state.aliasIndex.includes(index) ? 'block' : 'none',
											}}
										>
											{t('alias.empty_field')}
										</div>
										<div
											className='underLoginFieldError'
											style={{
												display: this.state.aliasIndexExtraDot.includes(index) ? 'block' : 'none',
											}}
										>
											{t('alias.alias_extra_dot')}
										</div>
										<div
											className='errorAlias'
											style={{
												display:
													localStorage.getItem('aliases') && localStorage.getItem('aliases').includes(alias.email) ? 'block' : 'none',
											}}
										>
											{t('alias.error')}
										</div>
									</div>
								))}
							</div>
							{/* ________________________________ALIASES_END____________________________________ */}

							<PersonInfo
								cn={this.props.user.cn}
								setCn={v => this.setState({ cn: v })}
								sn={this.props.user.sn}
								setSn={v => this.setState({ sn: v })}
								l={this.props.user.l}
								setL={v => this.setState({ l: v })}
								ou={this.props.user.ou}
								setOu={v => this.setState({ ou: v })}
								initials={this.props.user.initials}
								setInitials={v => this.setState({ initials: v })}
								title={this.props.user.title}
								setTitle={v => this.setState({ title: v })}
								description={this.props.user.description}
								setDescription={v => this.setState({ description: v })}
								displayName={this.props.user.displayName}
								setDisplayName={v => this.setState({ displayName: v })}
								t={t}
							/>
							<Contacts
								telephoneNumber={this.props.user.telephoneNumber}
								setTelephoneNumber={v => this.setState({ telephoneNumber: v })}
								homePhone={this.props.user.homePhone}
								setHomePhone={v => this.setState({ homePhone: v })}
								setTelephoneError={v => this.setState({ telephoneError: v })}
								t={t}
							/>

							<DeleteUser
								open={this.state.trashWindow}
								setOpen={v => this.setState({ trashWindow: v })}
								cn={this.state.cn}
								sn={this.state.cn}
								t={t}
								mail={this.props.user.mail}
								transf_status={this.props.transf_status}
								transf_code={this.props.transf_code}
								calendars_status={this.props.calendars_status}
								calendarsFetching={this.props.calendarsFetching}
								calendarsTransfering={this.props.calendarsTransfering}
								mainButton={{
									action: async replace => {
										if (replace.length > 0) {
											await this.props.transfCalendars(this.props.user.mail, replace, t);
											this.handleUserDelete(replace, t);
										} else this.handleUserDelete(replace, t);
									},
								}}
							/>
							<BlockUser
								open={this.state.blockWindow}
								setOpen={v => this.setState({ blockWindow: v })}
								cn={this.state.cn}
								sn={this.state.sn}
								t={t}
								mail={this.props.user.mail}
								calendars_status={this.props.calendars_status}
								transf_status={this.props.transf_status}
								transf_code={this.props.transf_code}
								upd_status={this.props.upd_status}
								upd_code={this.props.upd_code}
								updatingUser={this.props.updatingUser}
								calendarsFetching={this.props.calendarsFetching}
								calendarsTransfering={this.props.calendarsTransfering}
								inetUserStatus={this.props.user.inetUserStatus}
								blockButton={{
									action: async (replace, newUser) => {
										if (replace) {
											this.props.transfCalendars(this.props.user.mail, replace, t);
											this.handleUserBlock(newUser, replace, t);
										} else this.handleUserBlock(newUser, replace, t);
										this.setState({ blockWindow: false });
									},
								}}
								saveButton={{
									action: async replace => {
										if (replace) {
											await this.props.transfCalendars(this.props.user.mail, replace, t);
										}
										this.props.showAlert(
											t('users_page.success_change_settings', { cn: this.state.cn, sn: this.state.sn }),
											'admin_toast_success'
										);
										this.setState({ blockWindow: false });
									},
								}}
								cancelButton={{
									action: () => {
										this.setState({ blockWindow: false });
										return;
									},
								}}
							/>
							{/* {localStorage.getItem('role') !== '3' ? ( */}
							<div>
								<div className='addNewUserForm'>{t('users_page.manage_user_calendars')}</div>
								<div className='transferInfo'>{t('users_page.transfer_info')}</div>
								<Button
									onClick={() => {
										this.props.getCalendars(this.props.match.params.email).then(() => {
											if (this.props.calendars_status === 200) {
												this.setState({ showCalendarManager: true });
												this.setState({ ErrorCalToast: false });
											} else this.setState({ ErrorCalToast: true });
										});
									}}
									className={classes.transButton}
								>
									{t('users_page.transfer')}
								</Button>
								<ErrorToast
									open={this.state.ErrorCalToast}
									setOpen={v => this.setState({ ErrorCalToast: v })}
									body={t('users_page.error_download_calendars')}
									button={t('users_page.repeat')}
									style={{ position: 'fixed', marginTop: '20px' }}
									mainButton={{
										action: () => {
											this.props.getCalendars(this.props.match.params.email);
										},
									}}
								/>
								<CalendarManager
									open={this.state.showCalendarManager}
									setOpen={v => this.setState({ showCalendarManager: v })}
									mail={this.props.user.mail}
									calendars={this.props.calendars}
									calendars_status={this.props.calendars_status}
									transf_status={this.props.transf_status}
									transf_code={this.props.transf_code}
									calendarsFetching={this.props.calendarsFetching}
									calendarsTransfering={this.props.calendarsTransfering}
									mainButton={{
										action: async replace => {
											await this.props.transfCalendars(this.props.user.mail, replace, t);
											if (this.props.transf_status === 200) {
												this.setState({ showCalendarManager: false });
												this.props.showAlert(
													t('users_page.transfered_many_calendars', { cn: this.state.cn, sn: this.state.sn }),
													'admin_toast_success'
												);
											}
										},
									}}
								/>
							</div>
						</div>

						<div className='fixedBottom'>
							<Button
								onClick={e => this.handleUserSubmit(e, t)}
								className={classes.refreshButton}
								disabled={
									!this.state.cn ||
									!this.state.sn ||
									!this.state.email ||
									this.state.passwordError ||
									this.state.homePhoneError ||
									this.state.telephoneError ||
									this.state.aliasIndex.length > 0 ||
									this.state.aliasIndexWrongSymb.length > 0 ||
									this.state.aliasIndexExtraDot.length > 0 ||
									this.state.aliasMaxSymb ||
									this.state.employeeNumber === '0'
									// ||
									// (!this.state.disableButton.length && !this.state.userPassword)
								}
							>
								{t('refresh')}
							</Button>
						</div>
					</div>
				)}
			</div>
		);
	}
}

export default withStyles(styles)(withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(UserForm))));

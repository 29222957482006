import {
	BEGIN_USERS_FETCH,
	SUCCESS_USERS_FETCH,
	SUCCESS_USERSFORMODALS_FETCH,
	BEGIN_USER_FETCH,
	SUCCESS_USER_FETCH,
	CLEAN_SELECTED_USER,
	BEGIN_DOMAINS_FETCH,
	SUCCESS_DOMAINS_FETCH,
	ADDING_NEW_USER,
	ADDED_NEW_USER,
	PREVENT_USER_ADD,
	UPDATING_USER,
	UPDATED_USER,
	DELETED_USER,
	DELETING_USER,
	BEGIN_CALENDARS_FETCH,
	SUCCESS_CALENDARS_FETCH,
	BEGIN_TRANSF_CALENDARS,
	SUCCESS_TRANSF_CALENDARS,
	REMOVE_CALENDARS_STATUS,
} from '../constants/UsersConstants';

const initialState = {
	usersFetching: false,
	users: [],
	usersForModals: [],
	usersPages: 0,
	currentUsersPage: 0,
	user: {},
	userFetching: false,
	selectedUser: 'empty',
	domains: [],
	domainsFetching: false,
	addingNewUser: false,
	updatingUser: false,
	deletingUser: false,
	calendars: [],
	calendars_status: '',
	transf_status: '',
	transf_code: '',
	upd_status: '',
	upd_code: '',
	calendarsFetching: false,
	calendarsTransfering: false,
};

export const users = (state = initialState, action = {}) => {
	switch (action.type) {
		case BEGIN_USERS_FETCH:
			return Object.assign({}, state, {
				usersFetching: action.payload.usersFetching,
			});
		case SUCCESS_USERS_FETCH:
			return Object.assign({}, state, {
				usersFetching: action.payload.usersFetching,
				users: action.payload.users,
				usersPages: action.payload.usersPagesCount,
				currentUsersPage: action.payload.page,
			});
		case SUCCESS_USERSFORMODALS_FETCH:
			return Object.assign({}, state, {
				usersFetching: action.payload.usersFetching,
				usersForModals: action.payload.usersForModals,
			});
		case BEGIN_USER_FETCH:
			return Object.assign({}, state, {
				selectedUser: action.payload.selectedUser,
				userFetching: action.payload.userFetching,
			});
		case SUCCESS_USER_FETCH:
			return Object.assign({}, state, {
				userFetching: action.payload.userFetching,
				user: action.payload.user,
			});
		case CLEAN_SELECTED_USER:
			return Object.assign({}, state, {
				selectedUser: action.payload.selectedUser,
			});
		case BEGIN_DOMAINS_FETCH:
			return Object.assign({}, state, {
				domainsFetching: action.payload.domainsFetching,
			});
		case SUCCESS_DOMAINS_FETCH:
			return Object.assign({}, state, {
				domainsFetching: action.payload.domains.domainsFetching,
				domains: action.payload.domains.results,
			});
		case ADDING_NEW_USER:
			return Object.assign({}, state, {
				addingNewUser: true,
			});
		case ADDED_NEW_USER:
			return Object.assign({}, state, {
				addingNewUser: false,
			});
		case PREVENT_USER_ADD:
			return Object.assign({}, state, {
				addingNewUser: false,
				updatingUser: false,
			});
		case UPDATING_USER:
			return Object.assign({}, state, {
				updatingUser: true,
			});
		case UPDATED_USER:
			return Object.assign({}, state, {
				updatingUser: false,
				upd_status: action.payload.status,
				upd_code: action.payload.code,
			});
		case DELETING_USER:
			return Object.assign({}, state, {
				deletingUser: action.payload.deletingUser,
			});
		case DELETED_USER:
			return Object.assign({}, state, {
				deletingUser: action.payload.deletingUser,
			});
		case BEGIN_CALENDARS_FETCH:
			return Object.assign({}, state, {
				calendarsFetching: action.payload.calendarsFetching,
			});
		case SUCCESS_CALENDARS_FETCH:		
			return Object.assign({}, state, {
				calendarsFetching: action.payload.calendarsFetching,
				calendars: action.payload.calendars.results,
				calendars_status: action.payload.status,
			});
		case REMOVE_CALENDARS_STATUS:
			return Object.assign({}, state, {
				calendars_status: action.payload.status,
			});
		case BEGIN_TRANSF_CALENDARS:
			return Object.assign({}, state, {
				calendarsTransfering: true,
			});
		case SUCCESS_TRANSF_CALENDARS:
			return Object.assign({}, state, {
				calendarsTransfering: false,
				transf_status: action.payload.status,
				transf_code: action.payload.code,
			});
		default:
			return state;
	}
};

import React, { useState } from 'react';
// import {
//   Button, 
//   makeStyles,
// } from "@material-ui/core";
import { withTranslation } from "react-i18next";
// import "../../styles/Toast.css";

// const useStyles = makeStyles((theme) => ({
  
//   refreshButton: {
//     marginLeft: "16px",
//     // marginTop: "4px",
//     color: "#19252E",
//     backgroundColor: "rgba(68, 187, 255, 0.56)",
//     fontWeight: "550",
//     width: "99px",
//     height: "32px",
//     textTransform: "none",
//     fontSize: "14px",
//     lineHeight: "24px",
//     letterSpacing: "0.35px",    
//     "&:hover": {
//       backgroundColor: "rgba(68, 187, 255, 0.72)", 
//       color: "#19252E",      
//     },
//     "&:active": {
//      backgroundColor: "red(68, 187, 255, 0.88)", 
//       color: "#19252E", 
//     }
//   },
//   }));


const AdminToast = ({
  open,    
  mainTitle,
  ...props
}) => {
    // const classes = useStyles();

    const [isOpen, setIsOpen] = useState(true);
        if(!isOpen){
            return null
        }
    if (open) {
        return (
            <>
                <div className='success_toast'>
                    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'
                        style={{ position: "absolute", top: "opx", right: "4px", cursor: "pointer" }}
                        onClick={() => setIsOpen("")}
                    >
                        <path fill-rule='evenodd' clip-rule='evenodd' d='M8.4 7L7 8.4L10.6 12L7 15.6L8.4 17L12 13.4L15.6 17L17 15.6L13.4 12L17 8.4L15.6 7L12 10.6L8.4 7Z' fill='#19252E' />
                    </svg>
                    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
                        <path
                            fill-rule='evenodd'
                            clip-rule='evenodd'
                            d='M20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12ZM7 12.5L11.5 17L16.5721 8.00001L14.935 7.00002L11.2 13.7L8.5 11L7 12.5Z'
                            fill='#00884F'
                        />
                    </svg>
                    <div className='toast_main_info' style={{ marginLeft: '8px' }}>
                        {mainTitle}
                    </div>
                </div>
            </>
        )
    }
    else setIsOpen("");
};

export default withTranslation()(AdminToast)
    
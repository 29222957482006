import React from 'react';
import { HashRouter, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import '../styles/App.css';
import Login from './Login/Login.js';
import PrivateRoute from './Router/PrivateRoute.js';
import PublicRoute from './Router/PublicRoute.js';
import TenantsList from './Tenants/TenantsList';
import NewTenant from './Tenants/NewTenant';
import TenantForm from './Tenants/TenantForm';
import Domains from './Domains/Domains.js';
import NewDomain from './Domains/NewDomain.js';
import DomainForm from './Domains/DomainForm.js';
import Users from './Users/Users.js';
import ResourceGroups from './ResourceGroups/ResourceGroups.js';
import ResourceGroupForm from './ResourceGroups/ResourceGroupForm.js';
import NewGroup from './ResourceGroups/NewGroup.js';
import Sidebar from './Sidebar/Sidebar.js';
import UserForm from './Users/UserForm.js';
import Alert from './Alerts/Alert.js';
import NewUser from './Users/NewUser.js';
import Maillists from './Maillists/Maillists.js';
import NewMaillist from './Maillists/NewMaillist.js';
import NewResource from './ResourceGroups/NewResource.js';
import EditResource from './ResourceGroups/EditResource.js';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import MaillistForm from './Maillists/MaillistForm.jsx';
import { SnackbarProvider } from 'notistack';
import { getSelectedTenantMenu } from '../actions/LoginActions';
import i18next from 'i18next';
// import axios from "axios";

class App extends React.Component {
	constructor() {
		super();
		this.state = { baseUrlLoading: true };
	}

	componentDidMount() {
		fetch('/config.json')
			.then(r => r.json())
			.then(data => {
				let strigified = JSON.stringify(data);

				let cfg = JSON.parse(strigified);
				window.AdminConfig = { ...cfg };

				//TODO: remove this & refactor into config object
				window.PBM_API_URL = data.PBM_API_URL;
				window.pgs_integration = data.pgs_integration;
				window.enable_alternative_login = data.enable_alternative_login;
				window.AVATARS_URL = data.AVATARS_URL;
				window.PGS_ADMIN_URL = data.PGS_ADMIN_URL;
				this.setState({ baseUrlLoading: false });
			});
		//Это костыль для выделения нужной строчки в сайдбаре.
		//Это ОЧЕНЬ плохой костыль, потому что в проекте нет нормального роутинга. Если тебе придется еще когда-то работать надо этим проектом, пожалуйста перепиши здесь роутинг, это займет много времени, но оно того стоит, прости :)
		let location;
		switch (window.location.hash) {
			case '#/domains':
				location = i18next.t('domains_page.mail_domains');
				break;
			case '#/users':
				location = i18next.t('users');
				break;
			case '#/resource_groups':
				location = i18next.t('resource_groups');
				break;
			case '#/maillists':
				location = i18next.t('mailLists.mailGroups');
				break;
			case '#/tenants':
				location = i18next.t('tenants.tenants_list');
				break;

			default:
				break;
		}
		this.props.dispatch(getSelectedTenantMenu(localStorage.getItem('tenant'), location));
		// localstorage tenant + url param  == getselected item
	}

	render() {
		let baseUrlLoading = this.state.baseUrlLoading;
		const isSuperAdmin = localStorage.getItem('role') === '3';
		if (!baseUrlLoading) {
			return (
				<HashRouter>
					<SnackbarProvider
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'center',
						}}
						// style={{color: "red"}}
					/>
					<Alert />
					<Switch>
						<PublicRoute login path='/login' component={Login} />
					</Switch>

					<Switch>
						<PrivateRoute exact path='/'>
							{!window.pgs_integration && isSuperAdmin ? <Redirect to='/tenants' /> : <Redirect to='/users' />}
						</PrivateRoute>
						<PrivateRoute main path='/tenants'>
							<Sidebar />
							<TenantsList />
						</PrivateRoute>
						<PrivateRoute exact path='/tenant/new'>
							<Sidebar />
							<NewTenant />
						</PrivateRoute>
						<PrivateRoute exact path='/tenant/:tenant/edit'>
							<Sidebar />
							<TenantForm />
						</PrivateRoute>
						{/* <PrivateRoute main path="/:tenant/domains">  */}
						<PrivateRoute main path='/domains'>
							<Sidebar />
							<Domains />
						</PrivateRoute>
						<PrivateRoute newDomain path='/domain/new'>
							{/* <PrivateRoute newUser path="/:tenant/domains/new"> */}
							<Sidebar />
							<NewDomain />
						</PrivateRoute>
						{/* <PrivateRoute userForm path="/:tenant/domains/domain/edit"> */}
						<PrivateRoute domainForm path='/domain/:domain/edit'>
							<Sidebar />
							<DomainForm />
						</PrivateRoute>
						<PrivateRoute main path='/users'>
							<Sidebar />
							<Users />
						</PrivateRoute>
						<PrivateRoute newUser path='/user/new'>
							<Sidebar />
							<NewUser />
						</PrivateRoute>
						<PrivateRoute userForm path='/user/:email/edit'>
							<Sidebar />
							<UserForm />
						</PrivateRoute>

						<PrivateRoute groups path='/resource_groups'>
							<Sidebar />
							<ResourceGroups />
							<PrivateRoute newGroup exact path='/resource_groups/group/new'>
								<NewGroup />
							</PrivateRoute>
							<PrivateRoute editGroup exact path='/resource_groups/:groupName'>
								<ResourceGroupForm />
							</PrivateRoute>
							<PrivateRoute editResource exact path='/resource_groups/:groupName/resource/:resourceId/edit'>
								<EditResource />
							</PrivateRoute>
							<PrivateRoute addResource exact path='/resource_groups/:groupName/resource/new'>
								<NewResource />
							</PrivateRoute>
						</PrivateRoute>

						<PrivateRoute maillists path='/maillists'>
							<Sidebar />
							<Maillists />
							<PrivateRoute exact path='/maillists/maillist/new'>
								<NewMaillist />
							</PrivateRoute>
							<PrivateRoute exact path='/maillists/:maillistMail'>
								<MaillistForm />
							</PrivateRoute>
						</PrivateRoute>
					</Switch>
				</HashRouter>
			);
		} else {
			return (
				<Grid container spacing={0} direction='column' alignItems='center' justify='center' style={{ minHeight: '100vh' }}>
					<Grid item xs={3}>
						<CircularProgress />
					</Grid>
				</Grid>
			);
		}
	}
}

export default connect()(App);

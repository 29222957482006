import React from 'react';
import { makeStyles, Button } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Arrow from '../../../assets/arrow.svg';
import ArrowBlue from '../../../assets/arrowBlue.svg';
import { withTranslation } from 'react-i18next';
import '../../../styles/Users.css';

const useStyles = makeStyles(theme => ({
	button: {
		padding: '4px',
		backgroundColor: 'inherit',
		borderRadius: '4px',
		textTransform: 'none',
		'&:hover': {
			backgroundColor: 'rgba(25, 37, 46, 0.08)) !important',
		},
	},
}));

const Header = ({ title, bdName, columnOrder, sort, columnOrderBy, setColumnOrderBy, changeSort, getUsers, t }) => {
	const classes = useStyles();
	const arrowDirection = () => {
		// according to the UI/UX requirements, we must do a special sorting for the "role" (7) column on the contrary,
		// because the parameters on the backend are mixed up
		if (columnOrder !== '7' && columnOrder !== '8') {
			if (columnOrderBy === columnOrder && sort === 0) {
				return <img width='24px' height='24px' src={ArrowBlue} alt={''} />;
			}
			if (columnOrderBy === columnOrder && sort === 1) {
				return <img width='24px' height='24px' src={ArrowBlue} alt={''} style={{ transform: 'rotate(180deg)' }} />;
			}
		}
		if (columnOrder === '7') {
			if (columnOrderBy === columnOrder && sort === 0) {
				return <img width='24px' height='24px' src={ArrowBlue} alt={''} style={{ transform: 'rotate(180deg)' }} />;
			}
			if (columnOrderBy === columnOrder && sort === 1) {
				return <img width='24px' height='24px' src={ArrowBlue} alt={''} />;
			}
		}
		if (columnOrder === '8') {
			return;
		}
		if (columnOrderBy === columnOrder && sort === 0) {
			return <img width='24px' height='24px' src={ArrowBlue} alt={''} />;
		}
		if (columnOrderBy === columnOrder && sort === 1) {
			return <img width='24px' height='24px' src={ArrowBlue} alt={''} style={{ transform: 'rotate(180deg)' }} />;
		}
		if (columnOrderBy !== columnOrder) {
			return <img width='24px' height='24px' src={Arrow} alt={''} />;
		}
	};

	const changeParams = () => {
		// turning off quota sort
		if (columnOrderBy !== columnOrder && columnOrder === '8') return 
		
		let howToSort = sort === 0 ? 1 : 0;
		setColumnOrderBy(columnOrder);
		if (columnOrderBy === columnOrder) {
			changeSort(howToSort);
			localStorage.setItem('sort', howToSort);
			getUsers(howToSort, bdName);
		}

		if (columnOrderBy !== columnOrder && columnOrder !== '7') {
			setColumnOrderBy(columnOrder);
			changeSort(1);
			localStorage.setItem('orderby', bdName);
			localStorage.setItem('columnOrderBy', columnOrderBy);
			localStorage.setItem('sort', 1);
			getUsers(1, bdName);
		}
		if (columnOrderBy !== columnOrder && columnOrder === '7') {
			setColumnOrderBy(columnOrder);
			changeSort(0);
			localStorage.setItem('orderby', bdName);
			localStorage.setItem('columnOrderBy', columnOrderBy);
			localStorage.setItem('sort', 0);
			getUsers(0, bdName);
		}
	};

	return (
		<div>
			<Button className={classes.button}>
				<Box display='flex' alignItems='center' onClick={changeParams} height='24px'>
					<div className='headerTitle'>{title}</div>
					<div style={{ marginTop: '7px' }}>{arrowDirection()}</div>
				</Box>
			</Button>
		</div>
	);
};

export default withTranslation()(Header);

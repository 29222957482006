import React from 'react';
import { TextField, Button, MenuItem, IconButton, Table, TableBody, TableCell, TableRow, Tooltip, InputAdornment } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { addNewUser } from '../../actions/UsersActions.js';
import { getDomains } from '../../actions/DomainsActions.js';
import { connect } from 'react-redux';
import ClipboardJS from 'clipboard';
import copy from 'copy-to-clipboard';
import GoBack from '../../assets/back.svg';
import Trash from '../../assets/trashGreyDark.svg';
import CopyIcon from '../../assets/copyIcon.svg';
import Plus from '../../assets/plus.svg';
import GreyPlus from '../../assets/GreyPlus.svg';
import DarkTooltip from '../GeneralElements/DarkTooltip.js';
import { createHashHistory } from 'history';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import AvatarUploader from '../Avatars/AvatarUploader.js';
import { withTranslation } from 'react-i18next';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { showAlertAction } from '../../actions/AlertsActions';
import axios from 'axios';
import { parser } from '../../allFunctions/parser.js';
import { phoneParser } from '../../allFunctions/parser.js';
import DeleteAlias from './DeleteAlias.js';
import PersonInfo from './PersonInfo.js';
import Contacts from './Contacts.js';
import PassFields from './PassFields.js';
import '../../styles/Users.css';

const mapStateToProps = state => ({
	domains: state.domains.domains,
	domainsFetching: state.domains.domainsFetching,
	addingNewUser: state.users.addingNewUser,
});

const mapDispatchToProps = dispatch => ({
	getDomains: () => dispatch(getDomains()),
	addNewUser: (userInfo, t) => dispatch(addNewUser(userInfo, t)),
	showAlert: (message, type) => dispatch(showAlertAction(message, type)),
});

const styles = theme => ({
	root: {
		width: '100%',
		backgroundColor: 'white',
		minHeight: '100vh',
		boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)',
	},
	passHidden: {
		fontSize: '14px !important',
		fontFamily: 'text-security-disc',
		'-webkit-text-security': 'disc',
		'-moz-text-security': 'disc',
		'text-security': 'disc',
	},
	addUserBtn: {
		width: '168px',
		height: '32px',
		paddingLeft: '0px',
		marginLeft: '40px',
		color: '#19252E',
		// backgroundColor: "#F5F5F5",
		fontStyle: 'normal',
		fontWeight: '500',
		fontSize: '14px',
		lineHeight: '24px',
		letterSpacing: '0.35px',
		border: '1px solid rgba(25, 37, 46, 0.4)',
		textTransform: 'none',
		cursor: 'pointer',
		'&:hover': {
			backgroundColor: 'rgba(25, 37, 46, 0.08) !important',
			color: '#19252E',
			border: '1px solid rgba(25, 37, 46, 0.08)',
		},
		'&:disabled': {
			backgroundColor: 'rgba(25, 37, 46, 0.08) !important',
			color: '#A3A8AB',
			border: '1px solid rgba(25, 37, 46, 0.08)',
			cursor: 'pointer !important',
		},
		// "&:selected": {
		//   backgroundColor: "#BFDDF3 !important",
		//   color: "#0079D0",
		//   // border: "1px solid rgba(25, 37, 46, 0.08)",
		// },
		// "&:active": {
		//   backgroundColor: "#BFDDF3 !important",
		//   color: "#0079D0",
		//   // border: "1px solid rgba(25, 37, 46, 0.08)",
		// },
	},
	RoundTooltip: {
		width: '32px',
		height: '32px',
		marginTop: '16px',
		'&:hover': {
			backgroundColor: 'rgba(25, 37, 46, 0.08); !important',
		},
		'&:active': {
			backgroundColor: 'rgba(25, 37, 46, 0.16); !important',
		},
	},
	inputAliasEmail: {
		fontSize: '14px !important',
		lineHeight: '24px',
	},
	inputAliasEmailError: {
		fontSize: '14px !important',
		lineHeight: '24px',
		color: '#E2333A',
	},
});

class NewUser extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			cn: '',
			sn: '',
			l: '',
			ou: '',
			domain: '',
			altDomain: '',
			initials: '',
			title: '',
			description: '',
			telephoneNumber: '',
			labeledURI: '',
			// info: "",
			homePhone: '',
			email: '',
			altEmail: '',
			employeeNumber: 2,
			aliases: [],
			aliasIndex: [],
			aliasIndexWrongSymb: [],
			aliasIndexExtraDot: [],
			aliasMaxSymb: false,
			aliasErrorMail: false,
			aliasDelete: false,
			indexOfAliasDelete: '',
			userPassword: '',
			PasswordConfirm: '',
			errorWindow: false,
			croppedAvatar: '',
			quota: '',
			displayName: '',
			passwordError: false,
			telephoneError: false,
			smth: '',
		};

		this.handleUserSubmit = this.handleUserSubmit.bind(this);
		this.handleAddAlias = this.handleAddAlias.bind(this);
		this.handleAvatarCrop = this.handleAvatarCrop.bind(this);
		this.handleAliasEmailChange = this.handleAliasEmailChange.bind(this);
		this.handleAliasDomainChange = this.handleAliasDomainChange.bind(this);
		this.handleRemoveAlias = this.handleRemoveAlias.bind(this);
		this.handleChangeRole = this.handleChangeRole.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleClickOpen = this.handleClickOpen.bind(this);
		this.handleQuotaChange = this.handleQuotaChange.bind(this);
		this.history = createHashHistory();
		this.uniq_id = 0;
	}
	async componentDidMount() {
		await this.props.getDomains();
		this.setState({ domain: this.props.domains[0].name });
		localStorage.removeItem('aliases');
	}

	async handleAvatarCrop(avatarUrl) {
		let avatarBlob = await fetch(avatarUrl).then(r => r.blob());
		this.setState({ croppedAvatar: avatarBlob });
	}

	handleQuotaChange(e) {
		e.preventDefault();
		let valueX = e.target.value;
		if (valueX.length < 8) {
			if (valueX.match(/^\d*[,/.]?\d*$/)) {
				valueX = valueX.replace(/,/g, '.');
				valueX = valueX.replace(/\//g, '');
				this.setState({ quota: valueX });
			}
		}
	}

	handleUserSubmit(e, t) {
		e.preventDefault();
		this.props.addNewUser(this.state, t);
	}
	async handleAliasEmailChange(e, index) {
		e.preventDefault();
		let value = e.target.value;
		if (value) this.setState({ aliasIndex: this.state.aliasIndex.filter(el => el !== index) });
		if (localStorage.getItem('aliases') && localStorage.getItem('aliases').includes(value)) {
			localStorage.removeItem('aliases');
		}
		const tester = /^[-0-9A-Za-z._+]+$/;
		let validated = value.match(tester);
		if (!validated & (value !== '')) {
			this.state.aliasIndexWrongSymb.push(index);
		} else {
			this.setState({ aliasIndexWrongSymb: this.state.aliasIndexWrongSymb.filter(el => el !== index) });
		}
		if (value.length > 255) {
			this.setState({ aliasMaxSymb: true });
		} else {
			this.setState({ aliasMaxSymb: false });
		}
		let currentAliases = this.state.aliases;
		currentAliases[index].email = value;
		this.setState(currentAliases);
	}

	handleAliasEmailBlur(e, index) {
		e.preventDefault();
		let value = e.target.value;
		if (value.length === 0) {
			this.state.aliasIndex.push(index);
		} else {
			this.setState({ aliasIndexWrongSymb: this.state.aliasIndex.filter(el => el !== index) });
		}
		if (/^\.{1,}/g.test(value) || /\.{1,}$/g.test(value) || /\.{2,}/g.test(value) & (value !== '')) {
			this.state.aliasIndexExtraDot.push(index);
		} else {
			this.setState({ aliasIndexExtraDot: this.state.aliasIndexExtraDot.filter(e => e !== index) });
		}
	}
	handleAliasDomainChange(e, index) {
		e.preventDefault();
		let value = e.target.value;
		let currentAliases = this.state.aliases;
		currentAliases[index].domain = value;
		this.setState(currentAliases);
	}

	handleRemoveAlias(e, index) {
		e.preventDefault();
		let currentAliases = this.state.aliases;
		currentAliases.splice(index, 1);
		this.setState({ aliasIndexWrongSymb: this.state.aliasIndexWrongSymb.filter(el => el !== index) });
		this.setState({ aliasIndex: this.state.aliasIndex.filter(el => el !== index) });
		this.setState({ aliasIndexExtraDot: this.state.aliasIndexExtraDot.filter(e => e !== index) });
		this.setState({ aliases: currentAliases });
	}
	handleAddAlias(e) {
		e.preventDefault();
		let currentAliases = this.state.aliases;
		let domains = this.props.domains;
		let indexedDomains = domains.map(el => {
			this.uniq_id += 1;
			return { key: this.uniq_id + el.name, value: el.name };
		});
		currentAliases.push({
			domains: indexedDomains,
			key: Date.now() + '',
			domain: indexedDomains[0].value,
			email: '',
		});
		this.setState({ aliases: currentAliases });
	}

	handleChangeRole = event => {
		this.setState({ role: event.target.value });
	};

	handleClickOpen = () => {
		this.setState({ errorWindow: true });
	};

	handleClose = () => {
		this.setState({ errorWindow: false });
	};

	render() {
		const { classes, t } = this.props;
		return (
			<div className={classes.root}>
				{this.state.aliasDelete ? (
					<div
						style={{
							position: 'absolute',
							backgroundColor: 'rgba(0, 0, 0, 0.3)',
							width: '100vw',
							height: '1000vh',
							left: '0px',
						}}
					></div>
				) : null}
				{this.props.addingNewUser ? (
					<Grid container spacing={0} direction='column' alignItems='center' justify='center' style={{ minHeight: '100vh' }}>
						<Grid item xs={3}>
							<CircularProgress />
						</Grid>
					</Grid>
				) : (
					<div
						style={{
							paddingBottom: '10px',
							paddingTop: '10px',
						}}
					>
						<div className='backAndDelete'>
							<div className='newUserForm'>
								<Button className='backBtnIcon' startIcon={<ArrowBackIcon color='primary' />} onClick={e => this.history.push('/users')}>
									{t('back')}
								</Button>
							</div>
						</div>

						<div className='mainInfoAva'>
							<div className='avaAndLogin'>
								<AvatarUploader
									email={this.state.email && this.state.domain ? `${this.state.email}@${this.state.domain}` : ''}
									displayName={this.state.cn && this.state.sn ? `${this.state.cn} ${this.state.sn}` : ''}
									onAvatarCrop={this.handleAvatarCrop}
								/>
								<Table>
									<TableBody>
										<TableRow>
											<TableCell className='userTableCell'>
												<div className='loginRaw'>
													<div>
														<div className='fieldLabel asterisk'>{t('login')}</div>
														<TextField
															className='loginField'
															style={{ width: '270px' }}
															value={this.state.email}
															onChange={e => this.setState({ email: e.target.value })}
														/>
													</div>
													<div>
														<div className='mainEmailLabel'>@</div>
													</div>
													<div
														style={{
															color: 'rgba(22, 33, 41, 0.56)',
															display: 'inherit',
															padding: 0,
															fontSize: '12px',
															marginTop: '18px',
														}}
													>
														<TextField
															select
															className='domainCell'
															style={{ width: '270px' }}
															value={this.state.domain ? this.state.domain : ''}
															disabled={this.props.domainsFetching}
															onChange={e => this.setState({ domain: e.target.value })}
														>
															{this.props.domains ? (
																this.props.domains.map((option, key) => (
																	<MenuItem className='options' key={'-1' + key} value={option.name}>
																		{option.name}
																	</MenuItem>
																))
															) : (
																<div></div>
															)}
														</TextField>
													</div>
												</div>
											</TableCell>
										</TableRow>
										<TableRow style={{ display: window.enable_alternative_login ? 'contents' : 'none' }}>
											<TableCell className='userTableCell'>
												<div className='loginRaw'>
													<div>
														<div className='fieldLabel asterisk'>{t('users_page.alt_login')}</div>
														<TextField															
															className='loginField'
															style={{ width: '270px' }}
															value={this.state.altEmail}
															onChange={e => this.setState({ altEmail: e.target.value })}
														/>
													</div>
													<div>
														<div className='mainEmailLabel'>@</div>
													</div>
													<div
														style={{
															color: 'rgba(22, 33, 41, 0.56)',
															display: 'inherit',
															padding: 0,
															fontSize: '12px',
															marginTop: '18px',
														}}
													>
														<TextField
															select
															className='domainCell'
															style={{ width: '270px' }}
															value={this.state.altDomain}
															disabled={this.props.domainsFetching}
															onChange={e => this.setState({ altDomain: e.target.value })}
														>
															{this.props.domains ? (
																this.props.domains.map((option, key) => (
																	<MenuItem className='options' key={'-1' + key} value={option.name}>
																		{option.name}
																	</MenuItem>
																))
															) : (
																<div></div>
															)}
														</TextField>
													</div>
												</div>
											</TableCell>
										</TableRow>
										<TableRow>
											<TableCell className='userTableCell'>
												<div className='roleRow'>
													<TextField
														label={t('role')}
														select
														// className="domainCell"
														style={{ width: '270px' }}
														value={this.state.employeeNumber}
														onChange={e => this.setState({ employeeNumber: e.target.value })}
													>
														{[
															{ key: 2, value: t('user') },
															{ key: 1, value: t('admin') },
														].map(option => (
															<MenuItem className='options' key={option.key} value={option.key}>
																{option.value}
															</MenuItem>
														))}
													</TextField>
													<TextField
														// className="domainCell"
														label={t('quota')}
														name='quota'
														style={{ marginLeft: '48px', width: '220px' }}
														autoComplete='cc-number'
														InputProps={{
															endAdornment: <InputAdornment position='end'>Mb</InputAdornment>,
														}}
														value={this.state.quota}
														onChange={this.handleQuotaChange}
													/>
												</div>
											</TableCell>
										</TableRow>
									</TableBody>
								</Table>
							</div>

							<PassFields
								setUserPassword={v => this.setState({ userPassword: v })}
								setPasswordConfirm={v => this.setState({ passwordConfirm: v })}
								setPasswordError={v => this.setState({ passwordError: v })}
								edit={false}
								t={t}
							/>

							<div>
								<div className='addNewUserForm' style={{ display: 'flex', flexDirection: 'row' }}>
									{/* -------------- ALIASES---------------- */}

									<div style={{ marginTop: '8px' }}>{t('alias.aliases')}</div>
									{this.state.aliases.length === 10 ? (
										<DarkTooltip title={<>{t('alias.max_aliases')}</>}>
											<div>
												<Button
													disableRipple
													disabled={this.state.aliases.length === 10}
													className={this.props.classes.addUserBtn}
													onClick={this.handleAddAlias}
												>
													<div style={{ paddingLeft: '0px', display: 'flex' }}>
														<img src={this.state.aliases.length === 10 ? GreyPlus : Plus} alt={Plus} />
														<div style={{ marginLeft: '10px' }}>{t('alias.add_mail')}</div>
													</div>
												</Button>
											</div>
										</DarkTooltip>
									) : (
										<Button disableRipple className={this.props.classes.addUserBtn} onClick={this.handleAddAlias}>
											<div style={{ paddingLeft: '0px', display: 'flex' }}>
												<img src={Plus} alt={Plus} />
												<div style={{ marginLeft: '10px' }}>{t('alias.add_mail')}</div>
											</div>
										</Button>
									)}
								</div>

								{this.state.aliases.map((alias, index) => (
									<div>
										<DeleteAlias
											open={this.state.aliasDelete}
											setOpen={v => this.setState({ aliasDelete: v })}
											title={t('alias.remove_title')}
											body={t('alias.remove_body')}
											mainButton={{
												action: e => {
													this.handleRemoveAlias(e, this.state.indexOfAliasDelete);
													this.setState({ aliasDelete: false });
												},
											}}
										/>
										<div className='loginRaw' style={{ marginTop: '36px' }}>
											<TextField
												label={t('alias.label')}
												className={
													(localStorage.getItem('aliases') && localStorage.getItem('aliases').includes(alias.email)) ||
													this.state.aliasIndexExtraDot.includes(index) ||
													this.state.aliasMaxSymb ||
													this.state.aliasIndexWrongSymb.includes(index) ||
													this.state.aliasIndex.includes(index) ||
													this.state.aliasErrorMail
														? 'loginFieldError'
														: 'loginField'
												}
												value={alias.email}
												InputProps={{
													className:
														(localStorage.getItem('aliases') && localStorage.getItem('aliases').includes(alias.email)) ||
														this.state.aliasIndexExtraDot.includes(index) ||
														this.state.aliasMaxSymb ||
														this.state.aliasIndexWrongSymb.includes(index) ||
														this.state.aliasErrorMail
															? classes.inputAliasEmailError
															: classes.inputAliasEmail,
												}}
												onChange={e => this.handleAliasEmailChange(e, index)}
												onBlur={e => this.handleAliasEmailBlur(e, index)}
											/>
											<div
												style={{
													marginLeft: '16px',
													marginTop: '25px',
													color:
														localStorage.getItem('aliases') && localStorage.getItem('aliases').includes(alias.email)
															? '#E2333A'
															: '#000000',
												}}
											>
												@
											</div>

											<div
												style={{
													color: 'rgba(22, 33, 41, 0.56)',
													display: 'inherit',
													padding: '0',
													fontSize: '12px',
													width: '320px',
													marginLeft: '16px',
												}}
											>
												<TextField
													select
													className={
														localStorage.getItem('aliases') && localStorage.getItem('aliases').includes(alias.email)
															? 'domainCellError'
															: 'domainCell'
													}
													style={{ paddingRight: 0 }}
													value={alias.domain}
													InputProps={{
														className:
															localStorage.getItem('aliases') && localStorage.getItem('aliases').includes(alias.email)
																? classes.inputAliasEmailError
																: classes.inputAliasEmail,
													}}
													disabled={this.props.domainsFetching}
													onChange={e => this.handleAliasDomainChange(e, index)}
												>
													{alias.domains.map((option, key) => (
														<MenuItem className='options' key={option.key} value={option.value}>
															{option.value}
														</MenuItem>
													))}
												</TextField>
											</div>
											<div style={{ marginLeft: '30px', marginTop: '8px' }}>
												<Tooltip title={t('alias.copy')}>
													<IconButton
														aria-label='Remove'
														onClick={e => {
															copy(alias.email + '@' + alias.domain);
														}}
														className={this.props.classes.RoundTooltip}
													>
														<img src={CopyIcon} alt={CopyIcon} style={{ color: 'red' }} />
													</IconButton>
												</Tooltip>
											</div>
											<div style={{ marginLeft: '10px', marginTop: '8px' }}>
												<Tooltip title={t('alias.remove')}>
													<IconButton
														aria-label='Remove'
														onClick={e =>
															this.setState({
																aliasDelete: true,
																indexOfAliasDelete: index,
															})
														}
														className={this.props.classes.RoundTooltip}
													>
														<img src={Trash} alt={Trash} />
													</IconButton>
												</Tooltip>
											</div>
										</div>
										<div
											className='underLoginFieldError'
											style={{
												display: this.state.aliases[index].email.length > 255 ? 'block' : 'none',
											}}
										>
											{t('alias.max_symb')}
										</div>
										<div
											className='underLoginFieldError'
											style={{
												display:
													this.state.aliasIndexWrongSymb.includes(index) && this.state.aliases[index].email !== '' ? 'block' : 'none',
											}}
										>
											{t('alias.wrong_symb')}
										</div>
										<div
											className='underLoginFieldError'
											style={{
												display: this.state.aliases[index].email === '' && this.state.aliasIndex.includes(index) ? 'block' : 'none',
											}}
										>
											{t('alias.empty_field')}
										</div>
										<div
											className='underLoginFieldError'
											style={{
												display: this.state.aliasIndexExtraDot.includes(index) ? 'block' : 'none',
											}}
										>
											{t('alias.alias_extra_dot')}
										</div>
										<div
											className='errorAlias'
											style={{
												display:
													localStorage.getItem('aliases') && localStorage.getItem('aliases').includes(alias.email) ? 'block' : 'none',
											}}
										>
											{t('alias.error')}
										</div>
									</div>
								))}
							</div>

							{/* -------------- ALIASES END---------------- */}

							<PersonInfo
								cn={this.state.cn}
								setCn={v => this.setState({ cn: v })}
								sn={this.state.sn}
								setSn={v => this.setState({ sn: v })}
								l={this.state.l}
								setL={v => this.setState({ l: v })}
								ou={this.state.ou}
								setOu={v => this.setState({ ou: v })}
								initials={this.state.initials}
								setInitials={v => this.setState({ initials: v })}
								title={this.state.title}
								setTitle={v => this.setState({ title: v })}
								description={this.state.description}
								setDescription={v => this.setState({ description: v })}
								displayName={this.state.displayName}
								setDisplayName={v => this.setState({ displayName: v })}
								t={t}
							/>
							<Contacts
								telephoneNumber={this.state.telephoneNumber}
								setTelephoneNumber={v => this.setState({ telephoneNumber: v })}
								homePhone={this.state.homePhone}
								setHomePhone={v => this.setState({ homePhone: v })}
								setTelephoneError={v => this.setState({ telephoneError: v })}
								t={t}
							/>
						</div>
						<div className='addButton'>
							<Button
								onClick={e => this.handleUserSubmit(e, t)}
								className='addButtonBtn'
								variant='outlined'
								disabled={
									!this.state.cn ||
									!this.state.sn ||
									!this.state.email ||
									this.state.aliasMaxSymb ||
									this.state.aliasIndexWrongSymb.length > 0 ||
									this.state.aliasIndex.length > 0 ||
									this.state.aliasIndexExtraDot.length > 0 ||
									this.state.employeeNumber === '0' ||
									this.state.homePhoneError ||
									this.state.telephoneError ||
									!this.state.userPassword ||
									!this.state.passwordConfirm ||
									this.state.passwordError
								}
							>
								{t('add')}
							</Button>
						</div>
					</div>
				)}
			</div>
		);
	}
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(NewUser)));

import React from 'react';
import { connect } from 'react-redux';
import { LANGARR } from '../../constants/LanguageConstants';
import { cleanSelectedUser } from '../../actions/UsersActions';
import { Config } from '../../configs/index.js';
import axios from 'axios';
import { getTenants, removeFetchStatus, deleteTenant } from '../../actions/TenantsActions';
import {
	Avatar,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	InputAdornment,
	Input,
	Toolbar,
	Tooltip,
	IconButton,
	Divider,
	TextField,
	Button,
	Dialog,
	DialogContent,
	DialogActions,
	DialogContentText,
	DialogTitle,
	Slide,
} from '@material-ui/core';
import DarkTooltip from '../GeneralElements/DarkTooltip';
import PsnCheckbox from './../GeneralElements/PsnCheckbox';
import Info from '../../assets/info-red.svg';
import { withStyles } from '@material-ui/core/styles';
import { createHashHistory } from 'history';
import '../../styles/Domain.css';
import CircularProgress from '@material-ui/core/CircularProgress';
import TrashGreyDark from '../../assets/trashGreyDark.svg';
import Plus from '../../assets/tenants_plus.svg';
import Pencil from '../../assets/pencil.svg';
import i18next from 'i18next';

const mapStateToProps = state => ({
	tenants: state.tenants.tenants ? state.tenants.tenants : [],
	tenantsFetching: state.tenants.tenantsFetching,
	selectedUser: state.users.selectedUser,
	tenants_status: state.tenants.tenants_status,
});

const mapDispatchToProps = dispatch => ({
	getTenants: () => dispatch(getTenants()),
	// deleteTenant: (id) => dispatch(deleteTenant(id)),
	cleanSelectedUser: () => dispatch(cleanSelectedUser()),
	removeFetchStatus: () => dispatch(removeFetchStatus()),
});

const styles = {
	refreshButton: {
		position: "absolute",  
		bottom: "32px",
		color: '#006FAE',
		backgroundColor: 'FFF0F0',
		fontWeight: '500',
		width: '99px',
		height: '24px',
		textTransform: 'none',
		fontSize: '14px',
		lineHeight: '24px',
		letterSpacing: '0.35px',
		'&:hover': {
			backgroundColor: 'rgba(25, 37, 46, 0.08)',
			color: '#006FAE',
		},
		'&:active': {
			backgroundColor: 'rgba(25, 37, 46, 0.16)',
			color: '#006FAE',
		},
	},
	newDomButton: {
		// marginTop: "12px",
		color: '#19252E',
		backgroundColor: 'rgba(68, 187, 255, 0.56)',
		fontWeight: '500',
		width: '219px',
		height: '32px',
		textTransform: 'none',
		fontSize: '14px',
		lineHeight: '24px',
		letterSpacing: '0.35px',
		'&:hover': {
			backgroundColor: 'rgba(68, 187, 255, 0.72)',
			color: '#19252E',
		},
		'&:active': {
			backgroundColor: 'red(68, 187, 255, 0.88)',
			color: '#19252E',
		},
	},
};

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction='up' ref={ref} {...props} />;
});

class Tenants extends React.Component {
	constructor(props) {
		super(props);
		// this.selectUser = this.selectUser.bind(this);
		this.history = createHashHistory();
		// this.showNewUserForm = this.showNewUserForm.bind(this);
		this.state = {
			selectedDomain: '',
			deleteTitle: '',
			search: '',
			isSearchSuccessful: false,
			trashWindow: false,
			show: '',
			langArr: LANGARR,
		};
	}
	langArr = LANGARR;

	componentDidMount() {
		//  localStorage.removeItem("description")
		//  localStorage.removeItem("is_default")
		this.props.cleanSelectedUser();
		this.props.removeFetchStatus();
		this.props.getTenants();
	}

	//   componentDidUpdate(prevProps) {
	//   }

	selectTenant = (e, id) => {
		e.preventDefault();
		this.history.push(`/tenant/${id}/edit`);
		localStorage.setItem('tenantCur', id);
	};

	showNewTenantForm = e => {
		e.preventDefault();
		// this.props.cleanSelectedUser();
		this.history.push('/tenant/new');
	};

	getFullLang = tenantInfo => {
		let findLang = '';
		if (tenantInfo.lang) findLang = this.state.langArr.find(e => e.value === tenantInfo.lang);
		return findLang.label;
	};

	render() {
		let classes = this.props.classes;
		return (
			<div style={{ width: '100%', backgroundColor: 'white' }}>
				<div className='DomainsTable'>
					<Toolbar className='toolbar'>
						<div className='title'>{i18next.t('tenants.tenants_list')}</div>

						<Button className={classes.newDomButton} onClick={this.showNewTenantForm}>
							<img width='24px' height='24px' src={Plus} alt={''} style={{ marginRight: '4px' }} />
							{i18next.t('tenants.add_tenant')}
						</Button>

						<div
							className='TenantsGetError'
							style={{
								display:
									(this.props.tenants_status && this.props.tenants_status !== 404 && this.props.tenants_status !== 200) || this.props.tenants_status === 'unknown'
										? 'block'
										: 'none',
								marginTop: '24px',
							}}
						>
							<div style={{ display: 'flex' }}>
								<img style={{ marginRight: '8px' }} src={Info} alt={''} width='24px' height='24px' />
								{i18next.t('tenants.error_tenants')}
							</div>
							<Button
								onClick={this.props.getTenants}
								className={classes.refreshButton}
								// variant="outlined"
								// disabled={!this.state.domain || this.state.domainFieldError}
							>
								{i18next.t('tenants.refresh')}
							</Button>
						</div>
					</Toolbar>
					<TableContainer
						style={{
							height: 'calc(95vh - 64px)',
							// width: "100%",
							display:
								(this.props.tenants_status && this.props.tenants_status !== 404 && this.props.tenants_status !== 200) || this.props.tenants_status === 'unknown' ? 'none' : 'block',
						}}
						component={Paper}
					>
						<Table stickyHeader aria-label='sticky table' style={{ width: '60%' }}>
							<TableHead>
								<TableRow>
									<TableCell align='left' style={{ width: '70%', paddingLeft: '24px' }}>
										{i18next.t('tenants.tenant')}
									</TableCell>
									<TableCell align='left' style={{ width: '20%' }}>
										{i18next.t('tenants.lang')}
									</TableCell>
									{/* <TableCell align="left">{i18next.t("email")}</TableCell>
                <TableCell align="left">{i18next.t("actions")}</TableCell> */}
									<TableCell align='left' style={{ width: '10%' }}></TableCell>
								</TableRow>
							</TableHead>

							{this.props.tenantsFetching ? (
								<TableBody className='loading'>
									<TableRow>
										<TableCell className='loadingCell' colSpan='3'>
											<CircularProgress />
										</TableCell>
									</TableRow>
								</TableBody>
							) : (
								<TableBody>
									{this.props.tenants.map((row, index) => (
										<TableRow className='domainsRow' style={{ cursor: 'pointer' }} selected={this.props.selectedUser === row.mail} hover key={index}>
											<DarkTooltip title={<>{row[Object.keys(row)[0]].name}</>} hidden={row[Object.keys(row)[0]].name ? false : true}>
												<TableCell align='left' className='domainCells' onClick={e => this.selectTenant(e, Object.keys(row))}>
													<div style={{ display: 'column' }}>
														<div className='name_in_list' style={{ marginBottom: '4px' }}>
															{row[Object.keys(row)[0]].name}
														</div>
														<div className={row[Object.keys(row)[0]].name ? 'id_in_list' : 'name_in_list'}>{Object.keys(row)}</div>
													</div>
												</TableCell>
											</DarkTooltip>
											<TableCell align='left' className='domainCells' style={{ alignItems: 'center', maxWidth: '400px' }} onClick={e => this.selectTenant(e, Object.keys(row))}>
												{this.getFullLang(row[Object.keys(row)])}
											</TableCell>

											<TableCell align='left' className='domainCells'>
												<Tooltip title={i18next.t('edit')}>
													<IconButton aria-label='Delete' onClick={e => this.selectTenant(e, Object.keys(row))}>
														<img className='trashImgHover' src={Pencil} alt={''} />
													</IconButton>
												</Tooltip>
												{/* <Tooltip title={i18next.t('delete')}>
													<IconButton
														onClick={() =>
															this.props.deleteTenant(Object.keys(row))
														}
													>
														<img className='trashImgHover' src={TrashGreyDark} alt={''} />
													</IconButton>
												</Tooltip> */}
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							)}
						</Table>
					</TableContainer>
				</div>
			</div>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Tenants));

import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { withTranslation } from 'react-i18next';
// import { connect } from 'react-redux';
import Globe from '../../assets/globe.svg';
// import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import List from '@material-ui/core/List';

const LANGUAGES = [
	{ label: 'Русский', value: 'ru-RU' },
	{ label: 'English', value: 'en-US' },
	{ label: 'Español', value: 'es-PA' },
	{ label: 'Français', value: 'fr-FR' },
	{ label: 'Português', value: 'pt-BR' },
	{ label: 'Italiano', value: 'it-IT' },
	{ label: 'Башҡорт', value: 'ba-RU'  },
	{ label: 'Татарча', value: 'tt-RU'  },
];

class ChangeLanguage extends React.Component {
	constructor(props) {
		super(props);

		this.toggle = this.toggle.bind(this);
		this.state = {
			isOpen: false,
			langWindow: false,
		};
	}
	toggle() {
		this.setState({
			isOpen: !this.state.isOpen,
			tooltipOpen: !this.state.tooltipOpen,
		});
	}

	closeLangWindow() {
		this.setState({ langWindow: false });
	}

	selectLanguage = item => {
		localStorage.setItem('lang', item);
		document.location.reload(true);
	};

	render() {
		const { t } = this.props;
		return (
			<div className='changeLang'>
				<Tooltip title={t('change_language')}>
					<div className='langBtn'>
						<img className='langImg' src={Globe} alt={Globe} onClick={() => this.setState({ langWindow: true })} />
					</div>
				</Tooltip>
				<Dialog className={this.props.positionStyle} onClose={() => this.closeLangWindow()} aria-labelledby='simple-dialog-title' open={this.state.langWindow}>
					{/* <DialogTitle id="simple-dialog-title">
            {t("select_language")}
          </DialogTitle> */}
					<List component='nav' aria-label='secondary mailbox folders'>
						{LANGUAGES.map(item => (
							<ListItem button key={item.value}>
								<ListItemText
									primary={item.label}
									onClick={() => {
										this.selectLanguage(item.value);
									}}
								/>
							</ListItem>
						))}
					</List>
				</Dialog>
			</div>
		);
	}
}

export default withTranslation()(ChangeLanguage);

import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  makeStyles,
} from "@material-ui/core";
import { Height, NoEncryption } from "@material-ui/icons";
import { withTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  dialog: {
    backgroundColor: "white",
    width: "560px",
    height: "216px",
    paddingTop: "12px",
    paddingLeft: "8px",
    paddingBottom: "32px",
    borderRadius: "4px",   
    boxShadow: "0px 0px 12px rgba(25, 37, 46, 0.24) !important"  
  },
  footer: {
    paddingLeft: "24px",
    justifyContent: "flex-start",
    paddingBottom: "0px",
  },
  row: {
    width: "440px",
    height: "48px",
    paddingTop:"6px",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "24px",
    color: "black",
  },
    mainButton: {
      backgroundColor: "#E2333A !important",
      color: "white",
      width: "90px",
      height: "32px",
      marginRight: "4px",
      textTransform: "none",
      fontWeight: "525",
      fontSize: "14px",
      lineHeight: "16px",
      letterSpacing: "0.35px",
      '&:hover': {
        backgroundColor: '#BB1B1F !important',
        color: 'white',
      },
      '&:disabled': {
        backgroundColor: 'rgba(25, 37, 46, 0.08) !important',
        color: '#A3A8AB',
        border: '1px solid rgba(25, 37, 46, 0.08)',
      },      
  },  
  cancelButton: {
      color: "#19252E",
      backgroundColor: "white !important",
      fontWeight: "525",
      width: "84px",
      height: "32px",
      textTransform: "none",
      fontSize: "14px",
      lineHeight: "16px",
      letterSpacing: "0.35px",
      border: "1px solid rgba(25, 37, 46, 0.4)",
      '&:hover': {
        backgroundColor: '#EDEEEF !important',
        color: '#19252E',
      },     
    },   
  dialogCont: {
    // backgroundColor: "rgba(0, 0, 0, 0.40)",
    zIndex: "4000",
  },
  }));

const DeleteAlias = ({
  open,
  setOpen,
  title,
  body,
  mainButton,
  t,
  ...props
}) => {
  const classes = useStyles();
  return (   
    <Dialog
      open={open != ""}
      onClose={() => setOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="false"      
      classes={{ paper: classes.dialog, container: classes.dialogCont }}
    >
      <DialogTitle
        style={{ paddingBottom: "2px", paddingTop: "12" }}
        id="alert-dialog-title"
      >
        <div
          style={{
            fontStyle: "normal",
            fontWeight: "bold",
            fontSize: "19px",
            lineHeight: "24px",
            color: "rgba(22, 33, 41, 0.92)",
          }}
        >
          {title}
        </div>
      </DialogTitle>
      <DialogContent className={classes.body}>
        <DialogContentText className={classes.row}>{body}</DialogContentText>
      </DialogContent>
      <DialogActions className={classes.footer}>
        <Button className={classes.mainButton} onClick={mainButton.action} >
          {t("alias.remove")}
        </Button>
        <Button
          // disableRipple
          // variant="outlined"
          className={classes.cancelButton}
          onClick={() => setOpen("")}
          autoFocus
        >
          {t("alias.cancel")}
        </Button>
      </DialogActions>
    </Dialog>    
  );
};

export default withTranslation()(DeleteAlias)
    
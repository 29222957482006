export const parser = (value) => {
let valueX = value   
    valueX = valueX.replace(/^\s+/g, '');
    valueX = valueX.replace(/\+/g, '');
    valueX = valueX.replace(/\//g, '')
    valueX = valueX.replace(/ {1,}/g, " ");
    valueX = valueX.replace(/^\./g, '')
    valueX = valueX.replace(/\.{2,}/g, ".");
    valueX = valueX.replace(/^-/g, '')
    valueX = valueX.replace(/-{2,}/g, "-");
    valueX = valueX.replace(/^_/g, '')
    valueX = valueX.replace(/_{2,}/g, "_");
    return valueX
}

export const phoneParser = (value) => {
    let valueX = value   
        valueX = valueX.replace(/^\s+/g, '');
        valueX = valueX.replace(/^-/g, '')
    valueX = valueX.replace(/-{2,}/g, "-"); 
    valueX = valueX.replace(/\//g, '')
    return valueX
}

export const mailParser = (value) => {
    let valueX = value   
    // valueX = valueX.replace(/^_/g, '')
    valueX = valueX.replace(/_{2,}/g, "_");
    // valueX = valueX.replace(/^-/g, '')
    valueX = valueX.replace(/-{2,}/g, "-");
    // valueX = valueX.replace(/^\./g, '')
    valueX = valueX.replace(/\.{2,}/g, ".");
    valueX = valueX.replace(/ {1,}/g, "");    
    return valueX
}


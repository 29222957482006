import { TextField, makeStyles, MenuItem, Select, Popper, Paper } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useEffect, useState } from 'react';
import { parser } from '../../../allFunctions/parser.js';
import DarkTooltip from '../../GeneralElements/DarkTooltip.js';
import { withTranslation } from 'react-i18next';
import '../../../styles/Maillists.css';
import { styled } from '@material-ui/core';

const useStyles = makeStyles(theme => ({}));

const UserFields = ({ users, getUsers, maillistAlias, setMaillistAlias, edit, t }) => {
	const classes = useStyles();
	const [noOpen, setNoOpen] = useState(true);

	const StyledPopper = styled(Popper)({
		'& .MuiAutocomplete-listbox': {
			maxHeight: '263px',
			overflowY: 'auto',
		},
	});

	return (
		<>
			{/* ----------------------1st raw GROUPNAME and GROUPTYPE -------------------- */}
			<div className='staticRaw' style={{ marginBottom: '16px' }}>
				<div style={{ width: '832px', marginTop: '6px' }}>
					<Autocomplete
						multiple
						freeSolo
						className={edit ? '' : 'autocompleteButton'}
						disabled={!edit}
						filterSelectedOptions
						autoHighlight={false}
						autoSelect={false}
						id='tags-standard'
						value={maillistAlias}
						options={noOpen ? [] : users.map(item => item.mail)}
						noOptionsText={t('no_option')}
						clearText={t('clear')}
						getOptionLabel={option => option}
						filterOptions={options => {
							return options.filter(item => !maillistAlias.includes(item));
						}}
						PopperComponent={props => <StyledPopper {...props} />}
						onChange={(e, value) => {
							let valueSplitted = [];
							value.map(option => {
								option = option.trim();
								if (option.includes(';') || option.includes(' ') || option.includes(',')) {
									valueSplitted = valueSplitted.concat(option.split(/[,;" "]/));
								} else {
									valueSplitted.push(option);									
								}
								valueSplitted = Array.from(new Set(valueSplitted));
							});
							setMaillistAlias([...valueSplitted]);
						}}
						renderInput={params => (
							<div className={edit ? 'chipEdit' : 'chip'}>
								<TextField
									{...params}
									className='custom-autocomplete'
									style={{
										width: '817px',
									}}
									variant='outlined'
									onChange={e => {
										if (!e.target.value.includes(' ') && !e.target.value.includes(',') && !e.target.value.includes(';')) {
											setNoOpen(false);
											getUsers(e.target.value);
										} else setNoOpen(true);
									}}
								/>
							</div>
						)}
					/>
				</div>
			</div>
		</>
	);
};

export default withTranslation()(UserFields);

import React, { useEffect, useState } from 'react';
import DarkTooltip from '../../GeneralElements/DarkTooltip.js';
import { IconButton, makeStyles } from '@material-ui/core';
import { withTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
	roundTooltip: {
		width: '32px',
		height: '32px',
		marginTop: '8px',
		cursor: 'pointer',
		'&:hover': {
			backgroundColor: 'rgba(25, 37, 46, 0.08); !important',
		},
		'&:active': {
			backgroundColor: 'rgba(25, 37, 46, 0.16); !important',
		},
		'&.Mui-disabled': {
			opacity: '0.4',
			pointerEvents: 'unset',
			cursor: 'pointer',
			'&:hover': {
				backgroundColor: 'inherit',
			},
		},
	},
}));

const ToolIcon = ({ editAction, mail, inetUserStatus, image, t}) => {
	const classes = useStyles();
	const [title, setTitle] = useState('');
	useEffect(() => {
		getTitle();
	}, []);

	const getTitle = () => {
		if (!window.pgs_integration) {
			if (editAction === 'block') {
				if (mail === localStorage.getItem('currentAcc')) setTitle(t('users_page.forbid_block'));
				else if (inetUserStatus === '0') {
					setTitle(t('users_page.unblock_change_settings'));
				} else setTitle(t('users_page.block'));
			}
			if (editAction === 'delete') {
				if (mail === localStorage.getItem('currentAcc')) setTitle(t('users_page.forbid_delete'));
				else setTitle(t('users_page.delete'));
			}
		}
		if (editAction === 'edit') {
			setTitle(t('edit'));
		}
	};
	return (
		<DarkTooltip title={<>{title}</>} placement='bottom-start' hidden={window.pgs_integration && editAction !== 'edit'}>
			<IconButton className={classes.roundTooltip} disabled={(editAction !== 'edit' && window.pgs_integration) || (mail === localStorage.getItem('currentAcc') && editAction !== 'edit')}>
				<img src={image} alt={''} />
			</IconButton>
		</DarkTooltip>
	);
};

export default withTranslation()(ToolIcon);

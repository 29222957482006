import {
    ADDING_NEW_TENANT,
    ADDED_NEW_TENANT,
    PREVENT_TENANT_ADD,
    // CLEAN_SELECTED_USER,
    BEGIN_TENANTS_FETCH,
    SUCCESS_TENANTS_FETCH,
    UPDATING_TENANT,
    UPDATED_TENANT,
    BEGIN_TENANT_FETCH,
    SUCCESS_TENANT_FETCH,
  } from "../constants/TenantsConstants";
  
const initialState = {
  tenant: {},  
  tenants: [],  
  tenantsFetching: false,  
  tenantFetching: false,  
 
  selectedTenant: "",        
  addingNewTenant: false,
  updatingTenant: false,
  deletingTenant: false,
  delete_status: "",
  tenants_status: "",
  tenant_status: "",
  add_status: "",
  add_code: "",
  upd_status: "",
  upd_code: ""
  };
  
  export const tenants = (state = initialState, action = {}) => {
    switch (action.type) {
     
      // case CLEAN_SELECTED_USER:
      //   return Object.assign({}, state, {
      //     selectedUser: action.payload.selectedUser,
      //   });
      case BEGIN_TENANTS_FETCH:
        return Object.assign({}, state, {
          tenantsFetching: action.payload.tenantsFetching,
        });
      case SUCCESS_TENANTS_FETCH:       
        return Object.assign({}, state, {
          tenantsFetching: action.payload.tenantsFetching,
          tenants: action.payload.tenants.results,
          tenants_status: action.payload.status
        });
      case BEGIN_TENANT_FETCH:
        return Object.assign({}, state, {
          tenantFetching: action.payload.tenantFetching,
        });
      case SUCCESS_TENANT_FETCH:     
        return Object.assign({}, state, {
          tenantFetching: action.payload.tenantFetching,
          tenant: action.payload.tenant[0],
          tenant_status: action.payload.status
        });
      case ADDING_NEW_TENANT:
        return Object.assign({}, state, {
          addingNewTenant: true,
        });
      case ADDED_NEW_TENANT:        
        return Object.assign({}, state, {          
          addingNewTenant: false,
          add_status: action.payload.status,
          add_code: action.payload.code,
        });
        case PREVENT_TENANT_ADD: 
        return Object.assign({}, state, {
            addingNewTenant: false,
            updatingTenant: false
        })
      case UPDATING_TENANT:
        return Object.assign({}, state, {
          updatingTenant: true,
        });
      case UPDATED_TENANT:         
        return Object.assign({}, state, {
          updatingTenant: false,
          upd_status: action.payload.status,
          upd_code: action.payload.code,
        });
     
      default:
        return state;
    }
  };
  
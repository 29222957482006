import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import {
	getUsers,
	cleanSelectedUser,
	deleteUser,
	getCalendars,
	transfCalendars,
	updateUser,
	removeCalendarStatus,
} from '../../actions/UsersActions';
import { showAlertAction } from '../../actions/AlertsActions';
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	InputAdornment,
	TextField,
	Toolbar,
	Tooltip,
	IconButton,
	Button,
} from '@material-ui/core';
import base64url from 'base64url';
import DeleteUser from './DeleteUser.js';
import BlockUser from './BlockUser.js';
import { withStyles } from '@material-ui/core/styles';
import TablePagination from '@material-ui/lab/Pagination';
import ClearIcon from '@material-ui/icons/Clear';
import Plus from '../../assets/tenants_plus.svg';
import Search from '../../assets/search.svg';
import { createHashHistory } from 'history';
import '../../styles/Users.css';
// import AddBlue from "../../assets/addBlue.svg";
import CircularProgress from '@material-ui/core/CircularProgress';
import TrashGreyDark from '../../assets/trashGreyDark.svg';
import Lock from '../../assets/lockGrey.svg';
import Unlock from '../../assets/unlockGrey.svg';
import Loupe from '../../assets/loupe.svg';
import ErrorToast from './ErrorToast.js';
import Pencil from '../../assets/pencil.svg';
import DarkTooltip from '../GeneralElements/DarkTooltip.js';
import ToolIcon from './Subs/ToolIcon.js';
import { withTranslation } from 'react-i18next';
import { Typography } from '@nct-mdc/ui-kit';
import ColumnsSwitcher from './Subs/ColumnsSwitcher.js';
import Header from './Subs/Header.js';
import DataInColumn from './Subs/dataInColumn.js';
import ColumnsIcon from '../../assets/columns.svg';
import { findAllByDisplayValue } from '@testing-library/react';

const mapStateToProps = state => ({
	usersFetching: state.users.usersFetching,
	users: state.users.users,
	selectedUser: state.users.selectedUser,
	usersPages: state.users.usersPages,
	currentUsersPage: state.users.currentUsersPage,
	deletingUser: state.resourceGroups.deletingUser,
	calendars: state.users.calendars,
	calendars_status: state.users.calendars_status,
	transf_status: state.users.transf_status,
	transf_code: state.users.transf_code,
	calendarsFetching: state.users.calendarsFetching,
	calendarsTransfering: state.users.calendarsTransfering,
	updatingUser: state.users.updatingUser,
	upd_status: state.users.upd_status,
	upd_code: state.users.upd_code,
	userFetching: state.users.userFetching,
});

const mapDispatchToProps = dispatch => ({
	getCalendars: mail => dispatch(getCalendars(mail)),
	transfCalendars: (mail, replace, t) => dispatch(transfCalendars(mail, replace, t)),
	getUsers: (page, search, t, sort, otderby, attrs) => dispatch(getUsers(page, search, t, sort, otderby, attrs)),
	cleanSelectedUser: () => dispatch(cleanSelectedUser()),
	deleteUser: userMail => dispatch(deleteUser(userMail)),
	updateUser: (mail, add, replace, remove, t, avatar) => dispatch(updateUser(mail, add, replace, remove, t, avatar)),
	showAlert: (message, type) => dispatch(showAlertAction(message, type)),
	removeCalendarStatus: () => dispatch(removeCalendarStatus()),
});

const styles = {
	newUserButton: {
		// marginTop: "12px",
		color: '#19252E',
		backgroundColor: 'rgba(68, 187, 255, 0.56)',
		fontWeight: '500',
		width: '223px',
		height: '32px',
		textTransform: 'none',
		fontSize: '14px',
		lineHeight: '24px',
		letterSpacing: '0.35px',
		'&:hover': {
			backgroundColor: 'rgba(68, 187, 255, 0.72)',
			color: '#19252E',
		},
		'&:active': {
			backgroundColor: 'red(68, 187, 255, 0.88)',
			color: '#19252E',
		},
	},
	roundTooltip: {
		width: '32px',
		height: '32px',
		marginTop: '16px',
		cursor: 'pointer',
		'&:hover': {
			backgroundColor: 'rgba(25, 37, 46, 0.08); !important',
		},
		'&:active': {
			backgroundColor: 'rgba(25, 37, 46, 0.16); !important',
		},
		'&.Mui-disabled': {
			opacity: '0.4',
			pointerEvents: 'unset',
			cursor: 'pointer',
			'&:hover': {
				backgroundColor: 'inherit',
			},
		},
		'&.selected': {
			backgroundColor: '#44BBFF8F',
		},
	},
	hoverRow: {
		'&.Mui-selected': {
			backgroundColor: 'rgba(25, 37, 46, 0.08) !important',
		},
	},
};

// const Transition = React.forwardRef(function Transition(props, ref) {
// 	return <Slide direction='up' ref={ref} {...props} />;
// });

const Users = props => {
	const [selectedUser, setSelectedUser] = useState('');
	const [search, setSearch] = useState('');
	const [isSearchSuccessful, setIsSearchSuccessful] = useState(false);
	const [trashWindow, setTrashWindow] = useState(false);
	const [blockWindow, setBlockWindow] = useState(false);
	const [selectedUserName, setSelectedUserName] = useState('');
	const [selectedUserSnName, setSelectedUserSnName] = useState('');
	const [selectedUserMail, setSelectedUserMail] = useState('');
	const [show, setShow] = useState('');
	const [ErrorCalToast, setErrorCalToast] = useState(false);
	const [timeStamp, setTimeStamp] = useState('');
	const [inetUserStatus, setInetUserStatus] = useState('');
	const [columnsHeaders, setColumnsHeaders] = useState([
		{ title: props.t('name'), bdName: 'cn', enabled: true, columnOrder: '1' },
		{
			title: props.t('middle_name'),
			bdName: 'initials',
			enabled: localStorage.getItem('enabledColumns')?.includes('initials2') ? true : false,
			columnOrder: '2',
		},
		{ title: props.t('surname'), bdName: 'sn', enabled: true, columnOrder: '3' },
		{ title: props.t('email'), bdName: 'mail', enabled: true, columnOrder: '4' },
		{
			title: props.t('position'),
			bdName: 'title',
			enabled: localStorage.getItem('enabledColumns')?.includes('title5') ? true : false,
			columnOrder: '5',
		},
		{
			title: props.t('status'),
			bdName: 'inetUserStatus',
			enabled: localStorage.getItem('enabledColumns')?.includes('inetUserStatus6') ? true : false,
			columnOrder: '6',
		},
		{
			title: props.t('role'),
			bdName: 'employeeNumber',
			enabled: localStorage.getItem('enabledColumns')?.includes('employeeNumber7') ? true : false,
			columnOrder: '7',
		},
		{
			title: props.t('quota'),
			bdName: 'quota',
			enabled: localStorage.getItem('enabledColumns')?.includes('quota8') ? true : false,
			columnOrder: '8',
		},
		{
			title: props.t('department'),
			bdName: 'ou',
			enabled: localStorage.getItem('enabledColumns')?.includes('ou9') ? true : false,
			columnOrder: '9',
		},
		{
			title: props.t('city'),
			bdName: 'l',
			enabled: localStorage.getItem('enabledColumns')?.includes('l10') ? true : false,
			columnOrder: '10',
		},
		{
			title: props.t('license_agreement'),
			bdName: 'inetUserStatus',
			enabled: localStorage.getItem('enabledColumns')?.includes('inetUserStatus11') ? true : false,
			columnOrder: '11',
		},
		{ title: props.t('actions'), bdName: '' },
	]);
	const [showColumnsList, setShowColumnsList] = useState(false);
	const [sort, setSort] = useState(localStorage.getItem('sort') ? Number(localStorage.getItem('sort')) : 0);
	const [orderby, setOrderby] = useState(localStorage.getItem('orderby') ? localStorage.getItem('orderby') : 'sn');
	const [columnOrderBy, setColumnOrderBy] = useState(localStorage.getItem('columnOrderBy') ? localStorage.getItem('columnOrderBy') : '1');
	const [attrs, setAttrs] = useState(
		JSON.stringify(['initials', 'title', 'inetUserStatus', 'employeeNumber', 'quota', 'ou', 'l', 'inetUserStatus'])
	);
	const [columnWidths, setColumnWidths] = useState([]);
	const [doRender, setDoRender] = useState(findAllByDisplayValue);
	const [calendarsStatus, setCalendarsStatus] = useState('');
	const tableRef = useRef(null);

	useEffect(() => {
		setTimeStamp(new Date());
		props.cleanSelectedUser();
		if (sessionStorage.searchQuery) {
			setSearch(sessionStorage.searchQuery);
			setIsSearchSuccessful(true);
		}
		props.getUsers(
			1,
			sessionStorage.getItem('searchQuery') ? sessionStorage.getItem('searchQuery') : search,
			props.t,
			sort,
			orderby,
			attrs
		);
	}, []);

	useEffect(() => {
		if (tableRef.current) {
			const columns = tableRef.current.querySelectorAll('thead > tr:first-child > th');
			const resizeObserver = new ResizeObserver(entries => {
				const widths = Array.from(columns).map(column => column.offsetWidth);
				setColumnWidths(widths);
			});
			columns.forEach(column => {
				resizeObserver.observe(column);
			});
			return () => {
				resizeObserver.disconnect();
			};
		}
	}, []);

	useEffect(() => {		
		if (props.calendars_status === 200 || !props.calendars_status) {
			setErrorCalToast(false);
	} else setErrorCalToast(true);


	}, [props.calendars_status]);

	const history = useHistory();

	const selectUser = (e, email) => {
		e.preventDefault();
		history.push(`/user/${email}/edit`);
		localStorage.setItem('emailCur', `${email}`);
	};

	const showNewUserForm = e => {
		e.preventDefault();
		props.cleanSelectedUser();
		history.push('/user/new');
	};

	const handlePageChange = (e, value, t) => {
		e.preventDefault();
		props.getUsers(value, search, props.t, sort, orderby, attrs);
	};

	const handleSearchKeyDown = async e => {
		if (e.keyCode === 13) {
			if (search.length < 3) {
				setShow('true');
				props.cleanSelectedUser();
				sessionStorage.removeItem('searchQuery');
				setIsSearchSuccessful(false);
				return;
			} else {
				e.preventDefault();
				setShow('');
				await props.getUsers(1, search, props.t, sort, orderby, attrs);
				sessionStorage.setItem('searchQuery', search);
				setIsSearchSuccessful(true);
			}
		}
	};

	const handleSearchChange = e => {
		const tester = /^[ёЁА-Яа-я0-9A-Za-z-_.@]+$/;
		if (tester.test(e.target.value) || !e.target.value) setSearch(e.target.value);
	};

	const handleClearClick = e => {
		e.preventDefault();
		if (!sessionStorage.searchQuery) {
			setSearch('');
			setShow('');
		} else {
			setSearch('');
			setShow('');
			props.cleanSelectedUser();
			sessionStorage.removeItem('searchQuery');
			props.getUsers(1, '', props.t, sort, orderby, attrs);
			setIsSearchSuccessful(false);
		}
	};

	const handleUserDelete = async (calendars, t) => {
		props.deleteUser(selectedUserMail);
		if (calendars.length > 0) {
			props.showAlert(t('users_page.deleted_user_with_calendars', { cn: selectedUserName, sn: selectedUserSnName }), 'admin_toast_success');
		}
		if (calendars.length === 0)
			props.showAlert(t('users_page.deleted_user', { cn: selectedUserName, sn: selectedUserSnName }), 'admin_toast_success');
		setTrashWindow(false);
		props.getUsers(1, search, props.t, sort, orderby, attrs);
	};

	const handleUserBlock = async (newUser, calendars, t) => {
		let mail = selectedUserMail;
		const replace = [];
		let dataObj = {};
		dataObj[`/inetUserStatus`] = inetUserStatus === '0' ? '1' : '0';
		replace.push(dataObj);
		let messageForManyCalendars, messageSuccessBlock, messageSuccessUnblock;
		await props.updateUser(mail, [], replace, [], t, ''); // cropped avatar last var
		messageSuccessUnblock = `${t('users_page.success_unblock', { cn: selectedUserName, sn: selectedUserSnName })} ${t(
			'users_page.success_unblock_2'
		)}`;
		if (calendars?.length > 0) {
			messageForManyCalendars = `${t('users_page.transfered_many_calendars', { newUser: newUser })}`;
			messageSuccessBlock = `${t('users_page.success_block', { cn: selectedUserName, sn: selectedUserSnName })} ${messageForManyCalendars}`;
		} else messageSuccessBlock = `${t('users_page.success_block', { cn: selectedUserName, sn: selectedUserSnName })}`;

		if (inetUserStatus !== '0') {
			props.showAlert(messageSuccessBlock, 'admin_toast_success');
			setBlockWindow(false);
		} else {
			props.showAlert(messageSuccessUnblock, 'admin_toast_success');
			setBlockWindow(false);
		}
		props.getUsers(1, search, props.t, sort, orderby, attrs);
	};

	const leftStyleForColumns = header => {
		let width = '';

		if (columnsHeaders[1].enabled) {
			if (header.columnOrder === '1') {
				width = columnWidths[0];
			}
			if (header.columnOrder === '2') {
				width = columnWidths[1] + columnWidths[0];
			}
			if (header.columnOrder === '3') {
				width = columnWidths[2] + columnWidths[1] + columnWidths[0];
			}
			if (header.columnOrder === '4') {
				width = columnWidths[3] + columnWidths[2] + columnWidths[1] + columnWidths[0];
			}
		} else {
			if (header.columnOrder === '1') {
				width = columnWidths[0];
			}
			if (header.columnOrder === '3') {
				width = columnWidths[1] + columnWidths[0];
			}
			if (header.columnOrder === '4') {
				width = columnWidths[2] + columnWidths[1] + columnWidths[0];
			}
		}
		return width;
	};

	const stickyColumns = header => {
		if (
			header.columnOrder === '1' ||
			header.columnOrder === '2' ||
			(header.columnOrder === '3' && header.enabled) ||
			header.columnOrder === '4'
		)
			return true;
	};

	const { classes, t } = props;
	let tenant = localStorage.getItem('tenant');
	let avatarsUrl = window.AVATARS_URL;

	return (
		<div className='UsersTable'>
			<DeleteUser
				open={trashWindow}
				setOpen={v => setTrashWindow(v)}
				t={t}
				cn={selectedUserName}
				sn={selectedUserSnName}
				mail={selectedUserMail}
				calendars={props.calendars}
				calendars_status={props.calendars_status}
				transf_status={props.transf_status}
				transf_code={props.transf_code}
				calendarsFetching={props.calendarsFetching}
				calendarsTransfering={props.calendarsTransfering}
				mainButton={{
					action: async replace => {
						if (replace.length > 0) {
							await props.transfCalendars(selectedUserMail, replace, t);
							handleUserDelete(replace, t);
						} else handleUserDelete(replace, t);
					},
				}}
			/>
			<BlockUser
				open={blockWindow}
				setOpen={v => setBlockWindow(v)}
				cn={selectedUserName}
				sn={selectedUserSnName}
				t={t}
				mail={selectedUserMail}
				calendars={props.calendars}
				calendars_status={props.calendars_status}
				transf_status={props.transf_status}
				transf_code={props.transf_code}
				upd_status={props.upd_status}
				upd_code={props.upd_code}
				updatingUser={props.updatingUser}
				calendarsFetching={props.calendarsFetching}
				calendarsTransfering={props.calendarsTransfering}
				inetUserStatus={inetUserStatus}
				blockButton={{
					action: async (replace, newUser) => {
						if (replace) {
							await props.transfCalendars(selectedUserMail, replace, t);
							handleUserBlock(newUser, replace, t);
						} else handleUserBlock(newUser, replace, t);
						setBlockWindow(false);
					},
				}}
				saveButton={{
					action: async replace => {
						if (replace) {
							await props.transfCalendars(selectedUserMail, replace, t);
						}
						props.showAlert(
							t('users_page.success_change_settings', { cn: selectedUserName, sn: selectedUserSnName }),
							'admin_toast_success'
						);
						setBlockWindow(false);
					},
				}}
				cancelButton={{
					action: () => {
						setBlockWindow(false);
						return;
					},
				}}
			/>

			<ErrorToast
				open={ErrorCalToast}
				setOpen={v => setErrorCalToast(v)}
				body={t('users_page.error_download_calendars')}
				button={t('users_page.repeat')}
				style={{ position: 'fixed', marginTop: '20px', zIndex: '103' }}
				mainButton={{
					action: () => {
						props.getCalendars(selectedUserMail);
					},
				}}
			/>

			<Toolbar className='toolbar'>
				<div>
					<div className='title'>{t('users')}</div>
					<Tooltip title={t('add_new_user')} style={{ display: window.pgs_integration ? 'none' : '', zIndex: '101' }}>
						<Button
							style={{ display: window.pgs_integration ? 'none' : '' }}
							className={classes.newUserButton}
							color='default'
							onClick={showNewUserForm}
						>
							<img width='24px' height='24px' src={Plus} alt={Plus} style={{ marginRight: '4px' }} />
							{t('users_page.add_user')}
						</Button>
					</Tooltip>
				</div>

				<TextField
					variant='outlined'
					className='searchUser'
					value={search}
					onKeyDown={handleSearchKeyDown}
					onChange={handleSearchChange}
					placeholder={t('search')}
					InputProps={{
						endAdornment: search ? (
							<InputAdornment position='end'>
								<Tooltip title={t('clear_search')}>
									<IconButton aria-label='clear search' edge='end' size='small' onClick={e => handleClearClick(e)}>
										<ClearIcon />
									</IconButton>
								</Tooltip>
							</InputAdornment>
						) : (
							<IconButton aria-label='clear search' edge='end' size='small'>
								<img width='24px' height='24px' src={Search} alt={Search} />
							</IconButton>
						),
					}}
				/>
			</Toolbar>
			<div
				style={{
					display: show ? 'block' : 'none',
					marginLeft: '85%',
					marginTop: '0',
					paddingTop: '0',
					height: '24px',
					fontStyle: 'normal',
					fontWeight: '400',
					fontSize: '14px',
					lineHeight: '24px',
					color: '#D43A3E',
				}}
			>
				{t('min_3_symbols_for_search')}
			</div>

			<TableContainer
				className='userTableContainer'
				style={{ height: 'calc(95vh - 64px)' }}
				component={Paper}
				sx={{
					'&::-webkit-scrollbar': {
						width: 20,
					},
					'&::-webkit-scrollbar-track': {
						backgroundColor: 'orange',
					},
					'&::-webkit-scrollbar-thumb': {
						backgroundColor: 'red',
						borderRadius: 2,
					},
				}}
			>
				<Table
					stickyHeader
					aria-label='sticky table'
					className={`userTable ${(props.users.length === 0 || props.usersFetching) && 'empty-users_table'}`}
					ref={tableRef}
				>
					<TableHead>
						<TableRow>
							<TableCell
								style={{
									left: '0px',
									position: 'sticky',
									backgroundColor: '#fff',
									zIndex: '400',
									minWidth: '60px',
								}}
							/>
							{columnsHeaders.map((header, index) =>
								header.enabled ? (
									<TableCell
										align='left'
										style={{
											left: leftStyleForColumns(header, index),
											position: 'sticky',
											backgroundColor: 'fff',
											zIndex: '400',
											zIndex: stickyColumns(header) ? '400' : '390',
										}}
									>
										<Header
											title={header.title}
											bdName={header.bdName}
											columnOrder={header.columnOrder}
											columnOrderBy={columnOrderBy}
											setColumnOrderBy={v => setColumnOrderBy(v)}
											sort={sort}
											changeSort={v => setSort(v)}
											getUsers={(newSort, newOrderby) => props.getUsers(1, search, t, newSort, newOrderby, attrs)}
										/>
									</TableCell>
								) : null
							)}
							{/* <here /> */}
							<TableCell
								className='colIconCell'
								align='right'
								style={{
									right: '0px',
									position: 'sticky',
									backgroundColor: '#fff',
									zIndex: '500',
									// minWidth: '60px',
								}}
							>
								<DarkTooltip title={<>{t('users_page.edit_view')}</>} placement='bottom-start'>
									<IconButton
										className={`${classes.roundTooltip} ${showColumnsList && 'selected'}`}
										style={{ marginTop: '0px' }}
										onClick={() => setShowColumnsList(!showColumnsList)}
									>
										<img src={ColumnsIcon} alt={ColumnsIcon} />
									</IconButton>
								</DarkTooltip>
							</TableCell>
						</TableRow>
						<ColumnsSwitcher
							columns={columnsHeaders}
							switchColumn={v => setColumnsHeaders(v)}
							isColumnsVisible={showColumnsList}
							doRender={doRender}
							setDoRender={v => setDoRender(v)}
						/>
					</TableHead>

					{props.users.length === 0 && !props.usersFetching && (
						<TableBody className='empty-users_body'>
							<TableRow>
								<TableCell className='empty-users_cell' colSpan='5'>
									<div className='empty-users_wrapper'>
										<img src={Loupe} alt='' />
										<h2 className='empty-users_header'>{t('nothing_found')}</h2>
										<Typography variant='body2'>{t('check_spelling')}</Typography>
									</div>
								</TableCell>
							</TableRow>
						</TableBody>
					)}

					{props.usersFetching ? (
						<TableBody className='loading'>
							<TableRow>
								<TableCell className='loadingCell' colSpan='5'>
									<CircularProgress />
								</TableCell>
							</TableRow>
						</TableBody>
					) : (
						<TableBody style={{ cursor: 'pointer' }}>
							{props.users.map((row, index) => (
								<TableRow
									// hover
									key={index}
									onMouseEnter={() => {
										setSelectedUserMail(row.mail);
									}}
									className={classes.hoverRow}
								>
									<TableCell
										className='userAva'
										onClick={event => selectUser(event, row.mail)}
										style={{
											backgroundColor: selectedUserMail === row.mail ? '#edeeee' : 'white',
										}}
									>
										<img
											style={{
												borderRadius: '50%',
												marginTop: '4px',
												cursor: 'pointer',
												opacity: row.inetUserStatus === '0' ? '0.5' : '',
											}}
											src={`${avatarsUrl}${tenant}/${base64url(row.mail)}/${base64url(`${row.cn} ${row.sn}`)}?${timeStamp}`}
											alt={'Avatar'}
											className='avaImgSmall'
										/>
									</TableCell>

									{columnsHeaders.map((header, index) =>
										header.enabled && header.title !== t('actions') ? (
											<TableCell
												align='left'
												className='userCells'
												onClick={event => selectUser(event, row.mail)}
												style={{
													left: leftStyleForColumns(header, index),
													position: stickyColumns(header) ? 'sticky' : 'static',
													backgroundColor: selectedUserMail === row.mail ? '#edeeee ' : 'white',
													zIndex: stickyColumns(header) ? '105' : '50',
												}}
											>
												{<DataInColumn header={header} value={row[header.bdName]} inetUserStatus={row.inetUserStatus} t={t} />}
											</TableCell>
										) : null
									)}
									<TableCell
										align='left'
										className='editCells'
										style={{
											display: 'flex',
											backgroundColor: selectedUserMail === row.mail ? '#edeeee' : 'white',
											right: '0px',
											position: 'sticky',
											zIndex: '400',
										}}
									>
										<div style={{ display: selectedUserMail === row.mail ? 'flex' : 'none' }}>
											<div style={{ marginRight: '12px' }} onClick={event => selectUser(event, row.mail)}>
												<ToolIcon editAction='edit' mail={row.mail} inetUserStatus={row.inetUserStatus} image={Pencil} t={t} />
											</div>
											<div
												style={{ marginRight: '12px' }}
												onClick={() => {														
													props.getCalendars(row.mail)											
													setSelectedUserName(row.cn);
													setSelectedUserSnName(row.sn);
													setSelectedUserMail(row.mail);
													setInetUserStatus(row.inetUserStatus);
													setBlockWindow(true);
												}}
											>
												<ToolIcon
													editAction='block'
													mail={row.mail}
													inetUserStatus={row.inetUserStatus}
													image={row.inetUserStatus === '0' ? Unlock : Lock}
													t={t}
												/>
											</div>

											<div
												onClick={async () => {
													props.getCalendars(row.mail)
													setSelectedUserName(row.cn);
													setSelectedUserSnName(row.sn);
													setSelectedUserMail(row.mail);
													setTrashWindow(true);
												}}
											>
												<ToolIcon editAction='delete' mail={row.mail} inetUserStatus={row.inetUserStatus} image={TrashGreyDark} t={t} />
											</div>
										</div>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					)}
				</Table>
			</TableContainer>
			<div style={{ width: '100%', height: '5vh', backgroundColor: '#0000000a' }}>
				<TablePagination
					disabled={props.usersFetching}
					count={props.usersPages}
					onChange={handlePageChange}
					page={props.currentUsersPage}
					nextIconButtonProps={{ disabled: true }}
					backIconButtonProps={{ disabled: true }}
				/>
			</div>
		</div>
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withTranslation()(Users)));

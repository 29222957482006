import React from 'react';
import { connect } from 'react-redux';
import { cleanSelectedUser } from '../../actions/UsersActions';
import { Config } from '../../configs/index.js';
import axios from 'axios';
import { getDomains, deleteDomain, updateDomain, removeFetchStatus } from '../../actions/DomainsActions';
import {
	Avatar,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	InputAdornment,
	Input,
	Toolbar,
	Tooltip,
	IconButton,
	Divider,
	TextField,
	Button,
	Dialog,
	DialogContent,
	DialogActions,
	DialogContentText,
	DialogTitle,
	Slide,
} from '@material-ui/core';
import DarkTooltip from '../GeneralElements/DarkTooltip';
import DeleteDomen from './DeleteDomen.js';
import PsnCheckbox from './../GeneralElements/PsnCheckbox';
import Info from '../../assets/info-red.svg';
import { withStyles } from '@material-ui/core/styles';
import { createHashHistory } from 'history';
import '../../styles/Domain.css';
import CircularProgress from '@material-ui/core/CircularProgress';
import TrashGreyDark from '../../assets/trashGreyDark.svg';
import Pencil from '../../assets/pencil.svg';
import { withTranslation } from 'react-i18next';

const mapStateToProps = state => ({
	domains: state.domains.domains ? state.domains.domains : [],
	domainsFetching: state.domains.domainsFetching,
	selectedUser: state.users.selectedUser,
	deletingDomain: state.domains.deletingDomain,
	delete_status: state.domains.delete_status,
	domains_status: state.domains.domains_status,
});

const mapDispatchToProps = dispatch => ({
	getDomains: () => dispatch(getDomains()),
	cleanSelectedUser: () => dispatch(cleanSelectedUser()),
	deleteDomain: (domain, t) => dispatch(deleteDomain(domain, t)),
	updateDomain: (domain, add, replace, remove, t) => dispatch(updateDomain(domain, add, replace, remove, t)),
	removeFetchStatus: () => dispatch(removeFetchStatus()),
});

const styles = {
	refreshButton: {   
		position: "absolute",  
		bottom: "32px",
		color: '#006FAE',
		backgroundColor: 'FFF0F0',
		fontWeight: '525',
		width: '99px',
		height: '24px',
		textTransform: 'none',
		fontSize: '14px',
		lineHeight: '24px',
		letterSpacing: '0.35px',
		'&:hover': {
			backgroundColor: 'rgba(25, 37, 46, 0.08)',
			color: '#006FAE',
		},
		'&:active': {
			backgroundColor: 'rgba(25, 37, 46, 0.16)',
			color: '#006FAE',
		},
	},
	newDomButton: {
		// marginTop: "12px",
		color: '#19252E',
		backgroundColor: 'rgba(68, 187, 255, 0.56)',
		fontWeight: '525',
		width: '154px',
		height: '32px',
		textTransform: 'none',
		fontSize: '14px',
		lineHeight: '24px',
		letterSpacing: '0.35px',
		'&:hover': {
			backgroundColor: 'rgba(68, 187, 255, 0.72)',
			color: '#19252E',
		},
		'&:active': {
			backgroundColor: 'red(68, 187, 255, 0.88)',
			color: '#19252E',
		},
	},
};

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction='up' ref={ref} {...props} />;
});

class Domains extends React.Component {
	constructor(props) {
		super(props);
		// this.selectUser = this.selectUser.bind(this);
		this.history = createHashHistory();
		// this.showNewUserForm = this.showNewUserForm.bind(this);
		this.state = {
			selectedDomain: '',
			deleteTitle: '',
			search: '',
			isSearchSuccessful: false,
			trashWindow: false,
			show: '',
		};
	}
	componentDidMount() {
		localStorage.removeItem('description');
		localStorage.removeItem('is_default');
		this.props.cleanSelectedUser();
		this.props.removeFetchStatus();
		this.props.getDomains();
	}

	componentDidUpdate(prevProps) {
		if (
			(this.props.deletingDomain !== prevProps.deletingDomain && this.props.deletingDomain) ||
			(this.props.delete_status !== prevProps.delete_status && this.props.delete_status !== 200)
		) {
			this.setState({ trashWindow: true });
		} else if ((this.props.deletingDomain !== prevProps.deletingDomain) & !this.props.deletingDomain) {
			this.setState({ trashWindow: false });
		}
	}

	selectDomain = (e, domain, description, is_default) => {
		e.preventDefault();
		this.history.push(`/domain/${domain}/edit`);
		localStorage.setItem('domainCur', domain);
		if (description) localStorage.setItem('description', description);
		if (is_default) localStorage.setItem('is_default', true);
		else localStorage.setItem('is_default', false);
	};
	showNewDomainForm = e => {
		e.preventDefault();
		// this.props.cleanSelectedUser();
		this.history.push('/domain/new');
	};

	selectis_default = (domain, value, t) => {
		const add = [];
		const replace = [];
		const remove = [];
		replace.push({ '/is_default': value });
		this.props.updateDomain(domain, add, replace, remove, t);
	};

	render() {
		const { classes, t } = this.props;
		return (
			<div className='DomainsTable' style={{ width: '100%' }}>
				<DeleteDomen
					open={this.state.trashWindow}
					setOpen={v => this.setState({ trashWindow: v })}
					// TransitionComponent={Transition}
					// transition={Slide}
					deletingDomain={this.props.deletingDomain}
					result={this.props.delete_status}
					title={this.state.deleteTitle}
					body={t('domains_page.delete_info')}
					t={t}
					mainButton={{
						action: () => {
							this.props.deleteDomain(this.state.selectedDomain, t);
						},
					}}
					cancelButton={{
						action: () => {
							this.setState({ trashWindow: false });
						},
					}}
				/>

				<Toolbar className='toolbar'>
					<div className='title'>{t('domains_page.mail_domains')}</div>

					<Button className={classes.newDomButton} onClick={this.showNewDomainForm}>
						{t('domains_page.add_domain')}
					</Button>

					<div
						className='DomainsGetError'
						style={{
							display:
								(this.props.domains_status && this.props.domains_status !== 404 && this.props.domains_status !== 200) || this.props.domains_status === 'unknown' ? 'block' : 'none',
							marginTop: '24px',
						}}
					>
						<div style={{ display: 'flex' }}>
							<img style={{ marginRight: '8px' }} src={Info} alt={''} width='24px' height='24px' />
							{t('domains_page.error_domains')}
						</div>
						<Button
							onClick={this.props.getDomains}
							className={classes.refreshButton}							
						>
							{t('domains_page.refresh')}
						</Button>
					</div>
				</Toolbar>
				<TableContainer style={{ height: 'calc(95vh - 64px)' }} component={Paper}>
					<Table stickyHeader aria-label='sticky table'>
						<TableHead>
							<TableRow>
								<TableCell align='left' style={{ width: '35%', paddingLeft: '24px' }}>
									{t('domains_page.domain')}
								</TableCell>
								<TableCell align='left' style={{ width: '40%' }}>
									{t('domains_page.description')}
								</TableCell>
								{/* <TableCell align="left">{t("email")}</TableCell>
                <TableCell align="left">{t("actions")}</TableCell> */}
								<TableCell align='center' style={{ width: '15%' }}>
									{t('domains_page.default_domain')}
								</TableCell>
								<TableCell align='left' style={{ width: '10%' }}></TableCell>
							</TableRow>
						</TableHead>

						{this.props.domainsFetching ? (
							<TableBody className='loading'>
								<TableRow>
									<TableCell className='loadingCell' colSpan='4'>
										<CircularProgress />
									</TableCell>
								</TableRow>
							</TableBody>
						) : (
							<TableBody>
								{this.props.domains.map((row, index) => (
									<TableRow className='domainsRow' style={{ cursor: 'pointer' }} selected={this.props.selectedUser === row.mail} hover key={index}>
										<TableCell align='left' className='domainCells' onClick={event => this.selectDomain(event, row.name, row.description, row.is_default)}>
											{row.name}
										</TableCell>
										<DarkTooltip title={<>{row.description}</>} placement='bottom-start' hidden={row.description ? false : true}>
											<TableCell
												align='left'
												className='domainCells'
												style={{ alignItems: 'center', maxWidth: '400px' }}
												onClick={event => this.selectDomain(event, row.name, row.description, row.is_default)}
											>
												{row.description ? row.description : ''}
											</TableCell>
										</DarkTooltip>
										<TableCell align='left' className='domainCells' style={{ align: 'center' }}>
											<div
												style={{
													width: '17px',
													height: '17px',
													marginTop: '3px',
													display: 'flex',
													marginLeft: 'auto',
													marginRight: 'auto',
												}}
											>
												<PsnCheckbox
													checked={row.is_default === true}
													onChange={() => {
														this.selectis_default(row.name, row.is_default === true ? false : true);
													}}
												/>
											</div>
										</TableCell>
										<TableCell align='left' className='domainCells'>
											<Tooltip title={t('edit')}>
												<IconButton aria-label='Delete' onClick={event => this.selectDomain(event, row.name, row.description, row.is_default)}>
													<img className='trashImgHover' src={Pencil} alt={Pencil} />
												</IconButton>
											</Tooltip>
											<Tooltip title={t('delete')}>
												<IconButton
													onClick={() =>
														this.setState({
															trashWindow: true,
															selectedDomain: row.name,
															deleteTitle: t('domains_page.delete_domain') + ' ' + row.name + '?',
														})
													}
												>
													<img className='trashImgHover' src={TrashGreyDark} alt={TrashGreyDark} />
												</IconButton>
											</Tooltip>
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						)}
					</Table>
				</TableContainer>
			</div>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withTranslation()(Domains)));

'use strict';

export const validate = (email) => {
    const tester = /^[0-9A-Za-z-_+](\.?[0-9A-Za-z-_+])*@[a-zA-Z0-9_](-*\.?[a-zA-Z0-9-_])*\.[a-zA-Z-_](-?[a-zA-Z0-9])+$/
    if (!email) return false;

    const emailParts = email.split('@');

    if (emailParts.length !== 2) return false;

    const account = emailParts[0];
    const address = emailParts[1];

    if (account.length < 1 || email.length > 255) {
        return false;
    }

    const domainParts = address.split('.');
    if (domainParts.some(function (part) {
        return part.length > 63;
    })) return false;

    return tester.test(email);
}

export const altValidate = (altemail) => {
    const alttester = /^[^@]+@[^@]+\.[^@]+$/
    if (!altemail) return false;

    const emailParts = altemail.split('@');

    if (emailParts.length !== 2) return false;

    const account = emailParts[0];
    const address = emailParts[1];

    if (account.length < 1 || address.length > 253) {
        return false;
    }
    const domainParts = address.split('.');
    if (domainParts.some(function (part) {
        return part.length > 63;
    })) return false;

    return alttester.test(altemail);
}

export const domainValidate = (domain) => {
    const tester = /^[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/
    if (!domain) return false;
    if (domain.split('.').length < 2) return false;
    return tester.test(domain);
}


import {
    ADDING_NEW_TENANT,
    ADDED_NEW_TENANT,
    PREVENT_TENANT_ADD,
    // CLEAN_SELECTED_USER,
    BEGIN_TENANTS_FETCH,
    SUCCESS_TENANTS_FETCH,
    BEGIN_TENANT_FETCH,
    SUCCESS_TENANT_FETCH,
    UPDATING_TENANT,
    UPDATED_TENANT     
  } from "../constants/TenantsConstants";
  
import { Config } from "../configs";
import { createHashHistory } from "history";
import { showAlertAction } from "./AlertsActions";
import i18next from "i18next";
import axios from "axios";
import { enqueueSnackbar } from 'notistack'

// ___________________________________GETTING ALL TENANTS_____________ 
export const getTenants = () => {
  return function (dispatch) {
    dispatch(beginTenantsFetch());  
    return axios.get(Config.tenantsUrl())
      .then((response) => {
        if (response.status === 200) {         
            dispatch(successTenantsFetch(response.data, response.status));           
        } 
      })
      .catch((response) => {
            if (!response.data) return dispatch(successTenantsFetch([], "unknown"));
            dispatch(successTenantsFetch(response.data, response.status));
          }) 
  };
};

export const beginTenantsFetch = () => ({
  type: BEGIN_TENANTS_FETCH,
  payload: { tenantsFetching: true },
});

export const successTenantsFetch = (tenants, status) => ({  
  type: SUCCESS_TENANTS_FETCH,
  payload: { tenantsFetching: false, tenants, status },
});

export const removeFetchStatus = () => { 
  return ({    
    type: SUCCESS_TENANTS_FETCH,
    payload: { tenantsFetching: false, tenants: [], status: ""  },
  });
};

// ___________________________________GETTING REQUIRED ONLY ONE TENANT_____________ 
export const getTenant = (id) => {
  return function (dispatch) {
    dispatch(beginTenantFetch());  
    return axios.get(Config.tenantsActionUrl(id))
      .then((response) => {
        if (response.status === 200) {        
          dispatch(successTenantFetch(response.data.results, response.status));             
        } 
      })
      .catch((response) => {
            if (!response.data) return dispatch(successTenantFetch({}, "unknown"));
            dispatch(successTenantFetch(response.data, response.status));
          }) 
  };
};

export const beginTenantFetch = () => ({
  type: BEGIN_TENANT_FETCH,
  payload: { tenantFetching: true },
});

export const successTenantFetch = (tenant, status) => ({  
  type: SUCCESS_TENANT_FETCH,
  payload: { tenantFetching: false, tenant, status },
});

export const removeTenantFetchStatus = () => { 
  return ({    
    type: SUCCESS_TENANT_FETCH,
    payload: { tenantFetching: false, tenant: {}, status: ""  },
  });
};

// ___________________ADDING, UPDATING_______________________________

export const addNewTenant = (name, domain, lang) => {    
    return function (dispatch) {  
      const formData = new FormData();
      formData.set("lang", lang)
      formData.set("name", name)
      formData.set("domain", domain)
      dispatch(addingNewTenant());    
        return axios
          .post(Config.tenantsUrl(), formData)
          .then((response) => {
            if (parseInt(response.status / 200) == 1) {
              let options = {
                type: "small",
                variant: "success",
              };                         
              dispatch(addedNewTenant());
            }
          })
          .catch((response) => {
            if (!response.data) return dispatch(errorNewTenant("unknown", ""));
            return dispatch(
              errorNewTenant(response.status, response.data?.error?.code)
            );
            //  if (response.status === 403) {
            //     return response.json().then((json) => {
            //       if (json.message === "User session was removed") {
            //         localStorage.setItem("token", "");
            //         localStorage.setItem("tenant", "");
            //         let history = createHashHistory();
            //         history.push("/login");
            //       } else {
            //         dispatch(oldToken)
            //       }
            //     })
            //   }

            //   else if (response.status === 400) {
            //     response.json().then((errorBody) => {
            //       if (errorBody.message === "tenant is invalid") dispatch(showAlertAction(i18next.t("invalid_domain_value"), "error"))
            //     })
            //   }

            //   dispatch(addedNewTenant());
            //   dispatch(getTenant());
            //   let history = createHashHistory();
            //   history.push("/domains");

            //   localStorage.setItem("token", "");
            //   localStorage.setItem("tenant", "");
            //   let history = createHashHistory();
            //   history.push("/login");
          });
    };
  };
  
export const addingNewTenant = () => ({
    type: ADDING_NEW_TENANT,
  });
  
export const addedNewTenant = () => {
  createHashHistory().push("/tenants") 
  return {type: ADDED_NEW_TENANT }
};

export const errorNewTenant = (status, code) => ({
  type: ADDED_NEW_TENANT,
  payload: { status: status, code: code },
});

export const removeAddStatus = () => { 
  return ({    
    type: ADDED_NEW_TENANT,
    payload: { add_status: "" },
  });
};



// export const deleteTenant = (id) => {
//   return function (dispatch) {  
//     axios
//       .delete(Config.tenantsActionUrl(id))
//       .then((response) => {
//         if (parseInt(response.status / 200) === 1) {       
//           dispatch(getTenants());
//         }
//       })     
//   };
// };  

export const updateTenant = (id, add, replace, remove, t) => {  
  let updArrow = [] 
  return function (dispatch) {    
    if (replace.length > 0) {
      for ( let i = 0; i < replace.length; i++) {       
        updArrow.push({ op: 'replace', path: Object.keys(replace[i])[0], value: replace[i][Object.keys(replace[i])] });        
      }    
		}
    if (add.length > 0) {
      for ( let i = 0; i < add.length; i++) {       
        updArrow.push({ op: 'add', path: Object.keys(add[i])[0], value: add[i][Object.keys(add[i])] });        
      }				
		}
		if (remove.length > 0) {
			for ( let i = 0; i < remove.length; i++) {       
        updArrow.push({ op: 'remove', path: Object.keys(remove[i])[0]});        
      }
    }  
    dispatch(updatingTenant());
    axios
      .patch(Config.tenantsActionUrl(id), updArrow,
      { headers:  {'Content-Type': 'application/json-patch+json'}  }    
  )
      .then((response) => {
        if (parseInt(response.status / 200) === 1) {
          dispatch(updatedTenant(id, response.status));
          // dispatch(getTenants());
        }
      })
      .catch((response) => {
        if (!response.data) return dispatch(errorUpdTenant("unknown"));
        return dispatch(
          errorUpdTenant(response.status, response.data?.error?.code)
        );
      });
  };
};

export const updatingTenant = () => ({
  type: UPDATING_TENANT,
  payload: { updatingTenant: true },
});
  
export const updatedTenant = (tenant, result) => {
	if (localStorage.getItem('role') === '3') createHashHistory().push('/tenants');
	else createHashHistory().push('/users');  
	return {
		type: UPDATED_TENANT,
		payload: { updatingTenant: false, tenant, result },
	};
};

export const errorUpdTenant = (status, code) => ({
	type: UPDATED_TENANT,
	payload: { status: status, code: code },
});

import React from 'react';
import { Button, TextField, MenuItem, TableRow } from '@material-ui/core';
import '../../styles/Resources.css';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { createHashHistory } from 'history';
import { addResourceToGroup } from '../../actions/ResourceGroupsActions';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { getDomains } from '../../actions/DomainsActions';
import ResourcesAvatar from '../../assets/resourcesAvatar.svg';
import CircularProgress from '@material-ui/core/CircularProgress';
import Capacity from './Capacity';
import Grid from '@material-ui/core/Grid';
import { withTranslation } from 'react-i18next';

const mapStateToProps = state => ({
	domains: state.users.domains,
	domainsFetching: state.users.domainsFetching,
	addingResourceToGroup: state.resourceGroups.addingResourceToGroup,
});

const mapDispatchToProps = dispatch => ({
	addResourceToGroup: (groupName, displayName, mail, domain, l, description, resource_capacity, t) =>
		dispatch(addResourceToGroup(groupName, displayName, mail, domain, l, description, resource_capacity, t)),
	getDomains: () => dispatch(getDomains()),
});

const styles = theme => ({
	root: {
		width: '100%',
		backgroundColor: 'white',
		minHeight: '100vh',
		boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)',
	},
	infoForm: {
		marginBottom: '10px',
		'& > *': {
			margin: '10px',
		},
	},
	buttonsBlock: {
		'& > *': {
			margin: '10px',
		},
	},
	inputResEmailError: {
		fontSize: '14px !important',
		lineHeight: '24px',
		color: '#E2333A',
	},
	inputResEmail: {
		fontSize: '14px !important',
		lineHeight: '24px',
	},
	mainButton: {
		marginLeft: '16px',
		// marginTop: "4px",
		color: '#19252E',
		backgroundColor: 'rgba(68, 187, 255, 0.56)',
		fontWeight: '550',
		width: '100px',
		height: '32px',
		textTransform: 'none',
		fontSize: '14px',
		lineHeight: '24px',
		letterSpacing: '0.35px',
		'&:hover': {
			backgroundColor: 'rgba(68, 187, 255, 0.72)',
			color: '#19252E',
		},
		'&:active': {
			backgroundColor: 'red(68, 187, 255, 0.88)',
			color: '#19252E',
		},
		'&:disabled': {
			backgroundColor: 'rgba(25, 37, 46, 0.08) !important',
			color: '#A3A8AB',
			border: '1px solid rgba(25, 37, 46, 0.08)',
		},
	},
	cancelButton: {
		fontWeight: '550',
		width: '84px',
		height: '32px',
		textTransform: 'none',
		fontSize: '14px',
		lineHeight: '16px',
		letterSpacing: '0.35px',
		border: '1px solid #C0C0C0',
		'&:hover': {
			backgroundColor: '#EDEEEF !important',
			color: '#19252E',
		},
	},
});

class NewResource extends React.Component {
	static propTypes = {
		match: PropTypes.object.isRequired,
		location: PropTypes.object.isRequired,
		history: PropTypes.object.isRequired,
	};
	constructor(props) {
		super(props);
		this.history = createHashHistory();
		this.state = {
			domain: '',
			displayName: '',
			mail: '',
			description: '',
			l: '',
			resource_capacity: '',
			openUploadWindow: false,
			file: '',
			imagePreviewUrl:
				'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB4%0AbWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5r%0APSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMjRweCIg%0AaGVpZ2h0PSIyNHB4IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHZlcnNpb249IjEu%0AMSI+CiAgICAKICAgIDx0aXRsZT5BRE0vcmVzdXJjZXMtYWxsLWNhdGVnb3Jp%0AZXM8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rl%0Ac2M+CiAgICA8ZyBpZD0iQURNL3Jlc3VyY2VzLWFsbC1jYXRlZ29yaWVzIiBz%0AdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmls%0AbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8cGF0aCBkPSJNMTAsOCBMMTQs%0AOCBMMTQsNiBMMTAsNiBMMTAsOCBaIE0xNCw0IEMxNS4xMDQ1Njk1LDQgMTYs%0ANC44OTU0MzA1IDE2LDYgTDE2LDggTDE4LDggQzE5LjEwNDU2OTUsOCAyMCw4%0ALjg5NTQzMDUgMjAsMTAgTDIwLDE3IEMyMCwxOC4xMDQ1Njk1IDE5LjEwNDU2%0AOTUsMTkgMTgsMTkgTDYsMTkgQzQuODk1NDMwNSwxOSA0LDE4LjEwNDU2OTUg%0ANCwxNyBMNCwxMCBDNCw4Ljg5NTQzMDUgNC44OTU0MzA1LDggNiw4IEw4LDgg%0ATDgsNiBDOCw0Ljg5NTQzMDUgOC44OTU0MzA1LDQgMTAsNCBMMTQsNCBaIE0x%0AOCwxMCBMNiwxMCBMNiwxNyBMMTgsMTcgTDE4LDEwIFoiIGlkPSJTaGFwZSIg%0AZmlsbD0iI2ZmZiIvPgogICAgPC9nPgo8L3N2Zz4K',
			active: 'edit',
			resExtraDot: false,
			resMaxSymb: false,
			resWrongSymb: false,
			capacityError: false,
		};
		this.handleAddResource = this.handleAddResource.bind(this);
		this.handleCancel = this.handleCancel.bind(this);
		this.backToGroups = this.backToGroups.bind(this);
	}
	handleAddResource(e) {
		e.preventDefault();
		let groupName = this.props.match.params.groupName;
		this.props.addResourceToGroup(
			groupName,
			this.state.displayName,
			this.state.mail,
			this.state.domain,
			this.state.l,
			this.state.description,
			this.state.resource_capacity,
			this.props.t
		);
	}
	componentDidMount() {
		this.props.getDomains();
	}
	componentDidUpdate(prevProps) {
		if (this.props.domains !== prevProps.domains) {
			this.setState({ domain: this.props.domains[0].name });
		}
	}
	handleCancel(e) {
		e.preventDefault();
		this.history.goBack();
	}
	backToGroups(e) {
		e.preventDefault();

		let groupName = this.props.match.params.groupName;
		this.props.history.push(`/resource_groups/${groupName}`);
	}

	handleResEmailChange(e) {
		e.preventDefault();
		let value = e.target.value;
		const tester = /^[-А-Яа-я0-9A-Z_a-z.]+$/;
		let validated = value.match(tester);

		if (!validated) {
			this.setState({ resWrongSymb: true });
		} else {
			this.setState({ resWrongSymb: false });
		}

		if (value.length + this.state.domain.length + 1 > 255) {
			this.setState({ resMaxSymb: true });
		} else {
			this.setState({ resMaxSymb: false });
		}
		if (/^\.{1,}/g.test(value) || /\.{1,}$/g.test(value) || /\.{2,}/g.test(value) & (value !== '')) {
			this.setState({ resExtraDot: true });
		} else {
			this.setState({ resExtraDot: false });
		}
		this.setState({ mail: value });
	}

	render() {
		const { classes, t } = this.props;
		return (
			<div className={classes.root}>
				{this.props.addingResourceToGroup ? (
					<Grid container spacing={0} direction='column' alignItems='center' justify='center' style={{ minHeight: '100vh' }}>
						<Grid item xs={3}>
							<CircularProgress />
						</Grid>
					</Grid>
				) : (
					<div className='newResource'>
						<div className='newUserForm'>
							<Button className='backBtnIcon' startIcon={<ArrowBackIcon color='primary' />} onClick={this.backToGroups}>
								{t('back')}
							</Button>
						</div>

						<div className='newResourceForm'>
							<div className='resourceAva'>
								<img className='resourcesAvatar' src={ResourcesAvatar} alt={ResourcesAvatar} onClick={() => this.setState({ openUploadWindow: true })} />
							</div>
							<TableRow>
								<div className='fieldLabel' style={{ marginTop: '40px' }}>
									{t('name')}
								</div>
								<TextField
									variant='outlined'
									className='userInfoField'
									id='resourceName'
									inputProps={{
										autoComplete: 'off',
										type: 'text',
									}}
									value={this.state.displayName}
									onChange={e => this.setState({ displayName: e.target.value })}
								/>
							</TableRow>
							<TableRow>
								<div style={{ display: 'flex', alignItems: 'center', marginTop: '12px' }}>
									<div>
										<div className='fieldLabel'>{t('resourses.res_mail')}</div>
										<TextField
											className={(this.state.resWrongSymb || this.state.resExtraDot || this.state.resMaxSymb) && this.state.mail ? 'mailFieldError' : 'userInfoField'}
											variant='outlined'
											id='resourceAddress'
											disableUnderline
											value={this.state.mail}
											onChange={e => this.handleResEmailChange(e)}
											InputProps={{
												autoComplete: 'off',
												type: 'text',
												classes: {
													root:
														(this.state.resWrongSymb || this.state.resExtraDot || this.state.resMaxSymb) && this.state.mail ? classes.inputResEmailError : classes.inputResEmail,
												},
											}}
										/>
									</div>
									<div
										style={{
											marginLeft: '16px',
											marginRight: '16px',
											marginTop: '24px',
											color: this.state.resMaxSymb ? '#E2333A' : '#000000',
										}}
									>
										@
									</div>
									<div>
										<div className='fieldLabel'>{t('domains_page.domain')}</div>
										<TextField
											className={(this.state.resWrongSymb || this.state.resExtraDot || this.state.resMaxSymb) && this.state.mail ? 'domainCellInRes' : 'domainCellInRes'}
											variant='outlined'
											labelId='label'
											id='select'
											select
											value={this.state.domain}
											onChange={e => this.setState({ domain: e.target.value })}
											InputProps={{
												className:
													(this.state.resWrongSymb || this.state.resExtraDot || this.state.resMaxSymb) && this.state.mail ? classes.inputResEmailError : classes.inputResEmail,
											}}
										>
											{this.props.domains.map((option, key) => (
												<MenuItem className='options' key={'-1' + key} value={option.name}>
													{option.name}
												</MenuItem>
											))}
										</TextField>
									</div>
								</div>
							</TableRow>

							<div
								className='underMailFieldError'
								style={{
									display: this.state.resWrongSymb && this.state.mail ? 'block' : 'none',
								}}
							>
								{t('alias.wrong_symb')}
							</div>
							<div
								className='underMailFieldError'
								style={{
									display: this.state.resMaxSymb ? 'block' : 'none',
								}}
							>
								{t('alias.max_symb')}
							</div>
							<div
								className='underMailFieldError'
								style={{
									display: this.state.resExtraDot ? 'block' : 'none',
								}}
							>
								{t('alias.alias_extra_dot')}
							</div>
							<TableRow>
								<div className='fieldLabel' style={{ marginTop: '12px' }}>
									{t('city')}
								</div>
								<TextField
									variant='outlined'
									className='userInfoField'
									id='resourceCity'
									inputProps={{
										autoComplete: 'off',
										type: 'text',
									}}
									value={this.state.l}
									onChange={e => {
										this.setState({ l: e.target.value });
									}}
								/>
							</TableRow>
							<TableRow>
								<div className='fieldLabel' style={{ marginTop: '12px' }}>
									{t('description')}
								</div>
								<TextField
									variant='outlined'
									className='userInfoField'
									id='resourceDescription'
									inputProps={{
										autoComplete: 'off',
										type: 'text',
									}}
									value={this.state.description}
									onChange={e => this.setState({ description: e.target.value })}
								/>
							</TableRow>
							<TableRow>
								<Capacity
									capacityValue={this.state.resource_capacity}
									setCapacityValue={v => {
										this.setState({ resource_capacity: v });
									}}
									setCapacityError={v => {
										this.setState({ capacityError: v });
									}}
									capacityError={this.state.capacityError}
									t={t}
								/>
							</TableRow>
						</div>
						<div style={{ marginTop: '40px', marginLeft: '25px' }}>
							<Button className={classes.cancelButton} onClick={this.handleCancel}>
								{t('cancel')}
							</Button>
							<Button
								className={classes.mainButton}
								type='submit'
								onClick={this.handleAddResource}
								disabled={
									!this.state.displayName ||
									!this.state.mail ||
									!this.state.domain ||
									this.state.resWrongSymb ||
									this.state.resMaxSymb ||
									this.state.resExtraDot ||
									this.state.capacityError
								}
							>
								{t('save')}
							</Button>
						</div>
					</div>
				)}
			</div>
		);
	}
}

export default withRouter(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(NewResource))));

import React from "react";
import {
  TextField,
  Button,  
  Tooltip,  
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { addNewDomain, removeAddStatus} from "../../actions/DomainsActions.js";
import { connect } from "react-redux";
import Info from "../../assets/info.svg";
import { createHashHistory } from "history";
import InfoRed from "../../assets/info-red.svg";
import { withTranslation } from "react-i18next";
import BackArrow from "../../assets/backArrow.svg";
import CircularProgress from "@material-ui/core/CircularProgress";
import {mailParser} from "../../allFunctions/parser.js"
import "../../styles/Domain.css";

const mapStateToProps = (state) => ({   
  add_status: state.domains.add_status,
  add_code: state.domains.add_code,
  addingNewDomain: state.domains.addingNewDomain
});

const mapDispatchToProps = (dispatch) => ({  
  addNewDomain: (domain, description, t) => dispatch(addNewDomain(domain, description, t)),
  removeAddStatus: () => dispatch(removeAddStatus())
});

const styles = (theme) => ({
  
  infoForm: {
    marginBottom: "10px",
    "& > *": {
      margin: "10px",
    },
  },
  buttonsBlock: {
    "& > *": {
      margin: "10px",
    },
  },
  
  spinner: {    
    position: "absolute",
    left: "500px",
    top: "276px",
    color: "#FF962E !important",   
  }, 
  descTextField: {
    maxHeight: "200px",
    overflowY: "scroll !important",
    paddingTop: "0px !important"     
  },
  // tenantTextField: {  
  //   height: "40px",
  //   "&:disabled": {
  //   backgroundColor: "red"
  //   },
  // },
  addDomainBtn: {
    width: "105px",
    height: "32px",
    paddingLeft:"16px",
    marginRight: "12px",
    color: "#19252E",
    backgroundColor: "rgba(68, 187, 255, 0.56)",
    // fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "24px",
    letterSpacing: "0.35px",
    border: "1px solid rgba(25, 37, 46, 0.4)",
    textTransform: "none",
    cursor: 'pointer',
    "&:hover": {
      backgroundColor: "rgba(68, 187, 255, 0.86) !important",
      color: "#19252E",
      border: "1px solid rgba(25, 37, 46, 0.08)",
    },
    "&:disabled": {
      backgroundColor: "rgba(25, 37, 46, 0.08) !important",
      color: "#A3A8AB",
      border: "1px solid rgba(25, 37, 46, 0.08)",
      cursor: 'pointer !important',
    },
    // "&:selected": {
    //   backgroundColor: "#BFDDF3 !important",
    //   color: "#0079D0",
    //   // border: "1px solid rgba(25, 37, 46, 0.08)",
    // },
    // "&:active": {
    //   backgroundColor: "#BFDDF3 !important",
    //   color: "#0079D0",
    //   // border: "1px solid rgba(25, 37, 46, 0.08)",
    // },
    },
   
    cancelButton: {
        paddingLeft:"16px",
        color: "#19252E",
        backgroundColor: "white !important",
        fontWeight: "525",
        width: "84px",
        height: "32px",
        textTransform: "none",
        fontSize: "14px",
        lineHeight: "16px",
        letterSpacing: "0.35px",
        border: "1px solid rgba(25, 37, 46, 0.4)",
        "&:hover": {
          backgroundColor: "rgba(25, 37, 46, 0.08) !important", 
          color: "#19252E",
          border: "1px solid rgba(25, 37, 46, 0.4)"
        },
        // "&:active": {
        //   backgroundColor: "#BFDDF3 !important",
        //   color: "#0079D0",
        //   border: "1px solid #0079D0"
        // }
      },
  RoundTooltip: {
    width: "32px",
    height: "32px",
    marginTop: "16px",
    "&:hover": {
      backgroundColor: "rgba(25, 37, 46, 0.08); !important",
    },
    "&:active": {
      backgroundColor: "rgba(25, 37, 46, 0.16); !important",
    },
  },
  
});

class NewDomain extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      domain: "",
      description: "",
      domainFieldError: false,
    };

    this.history = createHashHistory();
  }

  componentDidMount() {    
    this.props.removeAddStatus()
  }

  handleDomainSubmit = (e, t) => {
    e.preventDefault();
    this.props.addNewDomain(this.state.domain, this.state.description.trim(), t);
  };

  handleClickOpen = () => {
    this.setState({ errorWindow: true });
  };

  handleClose = () => {
    this.setState({ errorWindow: false });
  };

  handleDomainNameBlur(e) {
    e.preventDefault();
    let value = e.target.value;
    if (
      value &&
      (value.split(".").length < 2 || /\.$|-$|^\.|^-|(\W\W)/.test(value))
    )
      return this.setState({ domainFieldError: true });
    if (value.split(".").find((item) => item.length < 2)) {
      this.setState({ domainFieldError: true });
    } else this.setState({ domainFieldError: false });
  }

  domainNameChangeHandler = (e, v) => {
    e.preventDefault();
    let value = e.target.value;
    const tester = /^[0-9A-Z-a-z.]+$/;
    if (!value.match(tester) & (value !== "") || value.length > 63) {
      this.setState({ domainFieldError: true });
    } else {
      this.setState({ domainFieldError: false });
    }
    this.setState({ domain: mailParser(value) });
  };

  descChangeHandler = (e) => {
    if (e.target.value?.length < 1000) {
      this.setState({ description: e.target.value });
    } else return;
  };

  render() {
    const { classes, t } = this.props;    
    return (
      <div
        className={
          this.props.addingNewDomain ? "NewDomainRootLoading" : "NewDomainRoot"
        }
      >
        <CircularProgress
          className={classes.spinner}
          style={{ display: this.props.addingNewDomain ? "block" : "none" }}
        />
        <div
          style={{ display: "flex", cursor: "pointer" }}
          onClick={(e) => this.history.push("/domains")}
        >
          <div>
            <Tooltip title={t("back")}>
              <img src={BackArrow} alt={BackArrow} width="24px" />
            </Tooltip>
          </div>
          <div
            className="title"
            style={{ marginBottom: "32px", marginLeft: "12px" }}
          >
            {t("domains_page.new_domain")}
          </div>
        </div>
        <div
          className="DomainsAddError"
          style={{
            display:
              (this.props?.add_status === "unknown") 
                ? "flex"
                : "none",
          }}
        >
          <img
            style={{ marginRight: "8px" }}
            src={InfoRed}
            alt={InfoRed}
            width="24px"
            height="24px"
          />
          {t("domains_page.delete_error")}
        </div>
        <div className="NewDomainTitle2">
          {t("domains_page.tenant_id")}
        </div>
        <div className="NewDomainField1" style={{ marginBottom: "16px" }}>
          <TextField
            className="NewDomainField1"
            variant="outlined"
            id="outlined-required"
            value={localStorage.getItem("tenant")}
            disabled
            InputProps={{ classes: { input: classes.tenantTextField } }}
          />
        </div>

        <div className="NewDomainTitle2">
          {t("domains_page.domain_name")}
        </div>
        <div className="NewDomainField1">
          <TextField
            className="NewDomainField1"
            variant="outlined"
            id="outlined-required"
            placeholder={t("domains_page.place_holder")}
            value={this.state.domain}            
            onChange={(e) => {
              this.domainNameChangeHandler(e, "domain");
            }}
            onBlur={(e) => {
              this.handleDomainNameBlur(e);
            }}
          />
        </div>
        <div
          className="underDomainFieldError"
          style={{
            display: this.state.domainFieldError || this.props.add_code === 307 ? "block" : "none",
          }}
        >
          {t("domains_page.error_format")}
        </div>

        <div
          className="underDomainFieldError"
          style={{
            display: this.props.add_status === 409 && this.props.add_code === 101 ? "block" : "none",
          }}
        >
          {t("domains_page.exist_name")}
        </div>

        <div className="NewDomainInfoName" style={{ marginBottom: "16px" }}>
          {t("domains_page.info_name")}
        </div>

        <div className="NewDomainTitle2">
          {t("domains_page.description")}
        </div>
        <div className="NewDomainField2" style={{ marginBottom: "20px"}}>
          <TextField           
            multiline             
            variant="outlined"    
            InputProps={{ classes: { input: classes.descTextField } }}
            value={this.state?.description}
            onChange={(e) => {
              this.descChangeHandler(e);
            }}
          />
        </div>
        <div className="NewDomainInfo">
          <img
            style={{ marginRight: "12px" }}
            src={Info}
            alt={Info}
            width="24px"
            height="24px"
          />
          {t("domains_page.info")}
        </div>

        <div>
          <Button
            onClick={e => this.handleDomainSubmit(e, t)}
            className={classes.addDomainBtn}
            variant="outlined"
            disabled={!this.state.domain || this.state.domainFieldError}
          >
            {t("domains_page.save")}
          </Button>
          <Button
            onClick={(e) => this.history.push("/domains")}
            className={classes.cancelButton}
            variant="outlined"
          >
            {t("domains_page.cancel")}
          </Button>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(NewDomain))
);

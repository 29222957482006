import React, { useEffect, useState } from 'react';
import { TextField, makeStyles } from '@material-ui/core';
import { parser } from '../../allFunctions/parser.js';
import DarkTooltip from '../GeneralElements/DarkTooltip.js';
import { withTranslation } from 'react-i18next';
import '../../styles/Users.css';

const useStyles = makeStyles(theme => ({}));

const PersonInfo = ({
	cn,
	setCn,
	sn,
	setSn,
	l,
	setL,
	ou,
	setOu,
	initials,
	setInitials,
	title,
	setTitle,
	description,
	setDescription,
	displayName,
	setDisplayName,
	t,
	...props
}) => {
	const classes = useStyles();
	const [cnCur, setCnCur] = useState(cn);
	const [snCur, setSnCur] = useState(sn);
	const [lCur, setLCur] = useState(l);
	const [ouCur, setOuCur] = useState(ou);
	const [initialsCur, setInitialsCur] = useState(initials);
	const [titleCur, setTitleCur] = useState(title);
	const [descriptionCur, setDescriptionCur] = useState(description);
	const [displayNameCur, setDisplayNameCur] = useState(displayName);

	const nameChangeHandler = (e, v) => {
		let parsedValue = parser(e.target.value);
		if (parsedValue.match(/[^Ёёa-zA-Zа-яА-Я0-9_.-\s+]/g) || parsedValue.length > 255) return;
		else if (v === 'name') {
			setCnCur(parsedValue);
			setCn(parsedValue);
			// if (e.target.value !== this.recData.cn && !this.state.disableButton.includes('name')) this.state.disableButton.push('name');
			// if (e.target.value === this.recData.cn) this.state.disableButton = this.state.disableButton.filter(e => e !== 'name');
		} else if (v === 'surName') {
			setSnCur(parsedValue);
			setSn(parsedValue);
			// if (e.target.value !== this.recData.sn && !this.state.disableButton.includes('surName')) this.state.disableButton.push('surName');
			// if (e.target.value === this.recData.sn) this.state.disableButton = this.state.disableButton.filter(e => e !== 'surName');
		} else if (v === 'middleName') {
			setInitialsCur(parsedValue);
			setInitials(parsedValue);
			// if (!e.target.value && typeof this.recData.initials !== 'string') this.state.disableButton = this.state.disableButton.filter(e => e !== 'middleName');
			// else if (e.target.value !== this.recData.initials && !this.state.disableButton.includes('middleName')) this.state.disableButton.push('middleName');
			// else if (e.target.value === this.recData.initials) this.state.disableButton = this.state.disableButton.filter(e => e !== 'middleName');
		} else if (v === 'department') {
			setOuCur(parsedValue);
			setOu(parsedValue);
			// if (!e.target.value && typeof this.recData.ou !== 'string') this.state.disableButton = this.state.disableButton.filter(e => e !== 'department');
			// else if (e.target.value !== this.recData.department && !this.state.disableButton.includes('department')) this.state.disableButton.push('department');
			// else if (e.target.value === this.recData.department) this.state.disableButton = this.state.disableButton.filter(e => e !== 'department');
		} else if (v === 'position') {
			setTitleCur(parsedValue);
			setTitle(parsedValue);
			// if (!e.target.value && typeof this.recData.title !== 'string') this.state.disableButton = this.state.disableButton.filter(e => e !== 'position');
			// else if (e.target.value !== this.recData.position && !this.state.disableButton.includes('position')) this.state.disableButton.push('position');
			// else if (e.target.value === this.recData.position) this.state.disableButton = this.state.disableButton.filter(e => e !== 'position');
		} else if (v === 'city') {
			setLCur(parsedValue);
			setL(parsedValue);
			// if (!e.target.value && typeof this.recData.l !== 'string') this.state.disableButton = this.state.disableButton.filter(e => e !== 'city');
			// else if (e.target.value !== this.recData.city && !this.state.disableButton.includes('city')) this.state.disableButton.push('city');
			// else if (e.target.value === this.recData.city) this.state.disableButton = this.state.disableButton.filter(e => e !== 'city');
		} else if (v === 'description') {
			setDescriptionCur(parsedValue);
			setDescription(parsedValue);
			// if (!e.target.value && typeof this.recData.description !== 'string') this.state.disableButton = this.state.disableButton.filter(e => e !== 'description');
			// else if (e.target.value !== this.recData.description && !this.state.disableButton.includes('description')) this.state.disableButton.push('description');
			// else if (e.target.value === this.recData.description) this.state.disableButton = this.state.disableButton.filter(e => e !== 'description');
		} else if (v === 'displayName') {
			setDisplayNameCur(parsedValue);
			setDisplayName(parsedValue);
			// if (!e.target.value && typeof this.recData.description !== 'string') this.state.disableButton = this.state.disableButton.filter(e => e !== 'description');
			// else if (e.target.value !== this.recData.description && !this.state.disableButton.includes('description')) this.state.disableButton.push('description');
			// else if (e.target.value === this.recData.description) this.state.disableButton = this.state.disableButton.filter(e => e !== 'description');
		}
	};
	return (
		<>
			<div className='addNewUserForm' style={{ marginBottom: '8px' }}>
				{t('personal_information')}
			</div>
			{/* ________________________________1st LINE NAME SIRNAME____________________________________ */}
			<div className='loginRaw' style={{ marginBottom: '16px' }}>
				<div>
					<div className='fieldLabel asterisk'>{t('name')}</div>
					<DarkTooltip title={<>{t('users_page.tooltip_disabled_fields')}</>} placement='bottom-start' hidden={!window.pgs_integration}>
						<TextField
							disabled={window.pgs_integration}
							variant='outlined'
							className='userInfoField'
							value={cnCur}
							inputProps={{
								autocomplete: 'disabled',
								type: 'text',
							}}
							onChange={e => {
								nameChangeHandler(e, 'name');
							}}
						/>
					</DarkTooltip>
				</div>

				<div style={{ marginLeft: '48px' }}>
					<div className='fieldLabel asterisk'>{t('surname')}</div>
					<DarkTooltip title={<>{t('users_page.tooltip_disabled_fields')}</>} placement='bottom-start' hidden={!window.pgs_integration}>
						<TextField
							disabled={window.pgs_integration}
							variant='outlined'
							className='userInfoField'
							value={snCur}
							inputProps={{
								autocomplete: 'disabled',
								type: 'text',
							}}
							onChange={e => {
								nameChangeHandler(e, 'surName');
							}}
						/>
					</DarkTooltip>
				</div>
			</div>
			{/* ________________________________2st MIDDLE NAME DEPARTMENT_________________ */}
			<div className='loginRaw' style={{ marginBottom: '16px' }}>
				<div>
					<div className='fieldLabel'>{t('middle_name')}</div>
					<DarkTooltip title={<>{t('users_page.tooltip_disabled_fields')}</>} placement='bottom-start' hidden={!window.pgs_integration}>
						<TextField
							disabled={window.pgs_integration}
							variant='outlined'
							className='userInfoField'
							value={initialsCur}
							inputProps={{
								autocomplete: 'disabled',
								type: 'text',
							}}
							onChange={e => {
								nameChangeHandler(e, 'middleName');
							}}
						/>
					</DarkTooltip>
				</div>

				<div style={{ marginLeft: '48px' }}>
					<div className='fieldLabel'>{t('department')}</div>
					<DarkTooltip title={<>{t('users_page.tooltip_disabled_fields')}</>} placement='bottom-start' hidden={!window.pgs_integration}>
						<TextField
							disabled={window.pgs_integration}
							variant='outlined'
							className='userInfoField'
							value={ouCur}
							inputProps={{
								autocomplete: 'disabled',
								type: 'text',
							}}
							onChange={e => {
								nameChangeHandler(e, 'department');
							}}
						/>
					</DarkTooltip>
				</div>
			</div>
			{/* ________________________________3d POSITION DESCRIPTION__________ */}
			<div className='loginRaw' style={{ marginBottom: '16px' }}>
				<div>
					<div className='fieldLabel'>{t('position')}</div>
					<DarkTooltip title={<>{t('users_page.tooltip_disabled_fields')}</>} placement='bottom-start' hidden={!window.pgs_integration}>
						<TextField
							disabled={window.pgs_integration}
							variant='outlined'
							className='userInfoField'
							value={titleCur}
							inputProps={{
								autocomplete: 'disabled',
								type: 'text',
							}}
							onChange={e => {
								nameChangeHandler(e, 'position');
							}}
						/>
					</DarkTooltip>
				</div>

				<div style={{ marginLeft: '48px' }}>
					<div className='fieldLabel'>{t('description')}</div>
					<DarkTooltip title={<>{t('users_page.tooltip_disabled_fields')}</>} placement='bottom-start' hidden={!window.pgs_integration}>
						<TextField
							disabled={window.pgs_integration}
							variant='outlined'
							className='userInfoField'
							value={descriptionCur}
							inputProps={{
								autocomplete: 'disabled',
								type: 'text',
							}}
							onChange={e => {
								nameChangeHandler(e, 'description');
							}}
						/>
					</DarkTooltip>
				</div>
			</div>
			{/* ________________________________4th CITY__________ */}
			<div className='loginRaw' style={{ marginBottom: '16px' }}>
				<div>
					<div className='fieldLabel'>{t('city')}</div>
					<DarkTooltip title={<>{t('users_page.tooltip_disabled_fields')}</>} placement='bottom-start' hidden={!window.pgs_integration}>
						<TextField
							disabled={window.pgs_integration}
							variant='outlined'
							className='userInfoField'
							value={lCur}
							inputProps={{
								autocomplete: 'disabled',
								type: 'text',
							}}
							onChange={e => {
								nameChangeHandler(e, 'city');
							}}
						/>
					</DarkTooltip>
				</div>
			</div>
			{/* ________________________________5th DISPLAYNAME__________ */}
			<div className='loginRaw' style={{ marginBottom: '16px' }}>
				<div>
					<div className='fieldLabel'>{t('displayName')}</div>
					<DarkTooltip title={<>{t('users_page.tooltip_disabled_fields')}</>} placement='bottom-start' hidden={!window.pgs_integration}>
						<TextField
							disabled={window.pgs_integration}
							variant='outlined'
							className='userInfoField'
							value={displayNameCur}
							inputProps={{
								autocomplete: 'disabled',
								type: 'text',
							}}
							onChange={e => {
								nameChangeHandler(e, 'displayName');
							}}
							// onBlur={() => {
							// 	if (!this.state.displayName) {
							// 		let value = `${this.state.cn} ${this.state.sn}`
							// 		this.setState({displayName: value})
							// 	}
							// }}
						/>
					</DarkTooltip>
				</div>
			</div>
		</>
	);
};

export default withTranslation()(PersonInfo);

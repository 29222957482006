import React from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import { addNewGroup } from "../../actions/ResourceGroupsActions.js";
import { parser } from "../../allFunctions/parser.js"
import { connect } from "react-redux";
import { createHashHistory } from "history";
import "../../styles/ResourceGroups.css";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import { withTranslation } from "react-i18next";

const mapStateToProps = (state) => ({
  addingNewGroup: state.resourceGroups.addingNewGroup,
});

const mapDispatchToProps = (dispatch) => ({
  addNewGroup: (groupName, t) => dispatch(addNewGroup(groupName, t)),
});

const styles = (theme) => ({
  root: {
    width: "100%",
    backgroundColor: "white",
    minHeight: "100vh",
    boxShadow:
      "0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)",
    padding: "40px",
  },
  infoForm: {
    marginBottom: "10px",
    width: "400px"
  },
});

class NewGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = { groupName: "" };
    this.nameChangeHandler = this.nameChangeHandler.bind(this);
    this.submitNewGroup = this.submitNewGroup.bind(this);
  }
  nameChangeHandler(e) {
    e.preventDefault();
    if (e.target.value.match(/[^a-zA-Zа-яА-Я0-9_.-\s+]/g)) {
      return
    } else {
      this.setState({ groupName: parser(e.target.value) })
    }
  }
  submitNewGroup(e, t) {
    e.preventDefault();
    this.props.addNewGroup(this.state.groupName.trim(), t);
  }
  cancelNewGroup(e) {
    let history = createHashHistory();
    history.goBack();
  }
  render() {
    const { classes, t } = this.props;
    return (
      <div className={classes.root}>
        {this.props.addingNewGroup ? (
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justify="center"
            style={{ minHeight: "100vh" }}
          >
            <Grid item xs={3}>
              <CircularProgress />
            </Grid>
          </Grid>
        ) : (
          <div>
            <div className="newGroup">{t("new_group")}</div>
            <form className={classes.infoForm} noValidate autoComplete="off">
              <TextField
                style={{width: "250px"}}
                label={t("group_name")}
                value={this.state.groupName}
                onChange={this.nameChangeHandler}
              />
            </form>

            <div className={classes.buttonsBlock}>
              <Button
                onClick={this.cancelNewGroup}
                variant="outlined"
                color="primary"
                className="cancelGroupButton"
              >
                {t("cancel")}
              </Button>
              <Button
                  disabled={
                    !this.state.groupName ||
                   
                    this.state.groupName.length > 255
                  }
                onClick={e => this.submitNewGroup(e, t)}
                variant="outlined"
                color="primary"
                className="saveGroupButton"
              >
                {t("save")}
              </Button>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(NewGroup))
);

import React from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router";
import { Drawer, CssBaseline, List, Tooltip, Collapse, ListItem, ListItemText } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import SidebarLink from "./SidebarLink";
import TenantLine from "./TenantLine"
import TenantSettingsLine from "./TenantSettingsLine"
import "../../styles/Sidebar.css";
import LogoLeft from "../../assets/logo.svg";
import Logout from "../../assets/exit.svg";
import Up from "../../assets/up.svg";
import { logout, getSelectedTenantMenu, cleanSelectedTenantMenu } from "../../actions/LoginActions";
import { getTenants} from "../../actions/DomainsActions";
import { getTenant} from "../../actions/TenantsActions.js";
import {ReactComponent as ExternalLink}  from '../../assets/externalLink.svg'
import UsersIcon from "../../assets/person.svg";
import ResourceGroupsIcon from "../../assets/resources.svg";
import ChangeLanguage from "../ChangeLanguage/ChangeLanguage";
import MaillistsIcon from "../../assets/maillists.svg";
import { createHashHistory } from "history";
import { withTranslation } from "react-i18next";
import DarkTooltip from "../GeneralElements/DarkTooltip"

const drawerWidth = 240;

const mapStateToProps = (state) => ({
  tenants: state.domains.tenants,
  tenant: state.tenants.tenant,
  tenantsFetching: state.domains.tenantsFetching,
  selectedTenantMenuItem: state.auth.selectedTenantMenuItem,
  selectedTenantMenuTenant: state.auth.selectedTenantMenuTenant
});

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logout()),
  getTenants: () => dispatch(getTenants()),
  getTenant: (id) => dispatch(getTenant(id)),
  getSelectedTenantMenu: (tenant, menuItem) => dispatch(getSelectedTenantMenu(tenant, menuItem)),
});

const sidebarItems = (t) => ([
  {
    title: t("tenants.settings"),
    path: `/tenant/${localStorage.getItem("tenant")}/edit`,
  },
  {
    title: t("domains_page.mail_domains"),
    path: "/domains",
    // icon: <img src={UsersIcon} alt={UsersIcon} />,
  },
  {
    title: t("users"),
    path: "/users",
    // icon: <img src={UsersIcon} alt={UsersIcon} />,
  },
  {
    title: t("resource_groups"),
    path: "/resource_groups",
    // icon: <img src={ResourceGroupsIcon} alt={ResourceGroupsIcon} />,
  },
  {
    title: t("mailLists.mailGroups"),
    path: "/maillists",
    // icon: <img src={MaillistsIcon} alt={MaillistsIcon} />,
  },
]);

const styles = (theme) => ({
  root: {    
    
    display: "flex",
    backgroundColor: "transparent !important",
  },
  drawer: {
    marginLeft: "56px",
    width: drawerWidth,
    flexShrink: 0,
    backgroundColor: "transparent !important",
  },
  drawerPaper: {    
    width: drawerWidth,
    borderRight: 0,
    backgroundColor: "transparent !important",
    marginLeft: "56px",
  },
  content: {
    flexGrow: 1,
  },
  changeLang: {
    marginTop: "auto",
    marginBottom: "40px",
    marginLeft:"2vw",
    display:"flex",
    width: "300px",
    height: "432px",
    // backgroundColor:"brown",
  },
});

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      langWindow: false,
      tenants: [],
      arrow: false,
      open: "",
      tenantName: "",
    };
   
    this.history = createHashHistory();
  }

  closeLangWindow() {
    this.setState({ langWindow: false });
  }  

  async componentDidMount() {    
    if (localStorage.getItem("role") === "3") {
      await this.props.getTenants()
      this.setState({tenants: this.props.tenants})  
    } else await this.props.getTenant(localStorage.getItem('tenant'))
      this.setState({tenantName: this.props.tenant.name})     
  }  

  render() {
    const { classes, logout, t } = this.props;
    
    return (
			<div className={classes.root}>
				<div className='greyBar'>
					<img className='logoImgLeftBar' src={LogoLeft} alt={LogoLeft} width='160px' />
					<div className='help'>
						<ChangeLanguage positionStyle={this.props.classes.changeLang} />

            <div className="logoutButton" onClick={logout}>
              <Tooltip title={t("logout")} placement="right">
                <img
                  style={{ height: "30px" }}
                  className="logoutBtn"
                  src={Logout}
                  alt={Logout}
                />
              </Tooltip>
            </div>
          </div>
        </div>
        <CssBaseline />
        {localStorage.getItem("role") === "3" ? (
          <Drawer
            className={classes.drawer}
            variant="permanent"
            classes={{
              paper: classes.drawerPaper,
            }}
            anchor="left"
          >
            <div className="mainMenu">
              { !window.pgs_integration ?
                <TenantSettingsLine
                  t={t}
                  tenant={localStorage.getItem("tenant")}
                  selectedTenantMenuItem={this.props.selectedTenantMenuItem}
                  selectedTenantMenuTenant={
                    this.props.selectedTenantMenuTenant
                  }
                />
                :
                null
              }
              {this.state.tenants.map((el) => (
                <div
                  onClick={() => {
                    localStorage.setItem("tenant", Object.keys(el));
                  }}
                  style={{ marginBottom: "12px" }}
                >
                  <TenantLine
                    tenant={Object.keys(el)}
                    tenantName={el[Object.keys(el)[0]].name}
                    selectedTenantMenuItem={this.props.selectedTenantMenuItem}
                    selectedTenantMenuTenant={
                      this.props.selectedTenantMenuTenant
                    }
                  />
                </div>
              ))}
            </div>
          </Drawer>
        ) : (
          <Drawer
            className={classes.drawer}  
            variant="permanent"
            classes={{
              paper: classes.drawerPaper,
            }}
            anchor="left"
          >
            <div className="mainMenu">
            <DarkTooltip title={<>{this.state.tenantName ? this.state.tenantName : localStorage.getItem("tenant")}</>}>               
                <div className="tenantName">{this.state.tenantName ? this.state.tenantName : localStorage.getItem("tenant")}</div>
            </DarkTooltip>
              {sidebarItems(t).map((sidebarItem, index) => (
                <SidebarLink
                  t={t}
                  key={index}
                  to={sidebarItem.path}
                  selectedTenantMenuItem={this.props.selectedTenantMenuItem}
                  selectedTenantMenuTenant={this.props.selectedTenantMenuTenant}
                  tenant={localStorage.getItem("tenant")}
                  primary={sidebarItem.title}
                  // icon={sidebarItem.icon}
                />
              ))}
              {!!window.PGS_ADMIN_URL && window.PGS_ADMIN_URL !== "" && (
                <SidebarLink
                  onClick={() => window.open(window.PGS_ADMIN_URL, "_blank")}
                  // to={window.PGS_ADMIN_URL}
                  primary={t("administration")}
                  className="sidebar_pgs-link"
                  icon={<ExternalLink />}
                  t={t}
                />
              )}
            </div>
          </Drawer>
        )}
      </div>
    );
  }
}

export default  withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withTranslation()(Sidebar))
));

import {
	BEGIN_USERS_FETCH,
	SUCCESS_USERS_FETCH,
	SUCCESS_USERSFORMODALS_FETCH,
	BEGIN_USER_FETCH,
	SUCCESS_USER_FETCH,
	ADDING_NEW_USER,
	ADDED_NEW_USER,
	PREVENT_USER_ADD,
	CLEAN_SELECTED_USER,
	BEGIN_DOMAINS_FETCH,
	SUCCESS_DOMAINS_FETCH,
	UPDATING_USER,
	UPDATED_USER,
	DELETED_USER,
	DELETING_USER,
	MIN_LOGIN_LEN,
	MIN_PASSWORD_LEN,
	USERS_PER_PAGE,
	BEGIN_CALENDARS_FETCH,
	SUCCESS_CALENDARS_FETCH,
	BEGIN_TRANSF_CALENDARS,
	SUCCESS_TRANSF_CALENDARS,
	REMOVE_CALENDARS_STATUS,
} from '../constants/UsersConstants';
import { Config } from '../configs';
import { createHashHistory } from 'history';
import { showAlertAction } from './AlertsActions';
import { validate } from '../components/Validations/EmailValidator';
import { altValidate } from '../components/Validations/EmailValidator';
import axios from 'axios';

export const getDomains = () => {
	return function (dispatch) {
		dispatch(beginDomainsFetch());
		return axios
			.get(Config.domainsUrl())
			.then(response => {
				if (response.status === 200) {
					// dispatch(getPasswordInfo());
					dispatch(successDomainsFetch(response.data));
				}
			})
			.catch(() => {
				return dispatch(showAlertAction('Error', 'error'));
			});
	};
};

export const beginDomainsFetch = () => ({
	type: BEGIN_DOMAINS_FETCH,
	payload: { domainsFetching: true },
});

export const successDomainsFetch = domains => ({
	type: SUCCESS_DOMAINS_FETCH,
	payload: { domainsFetching: false, domains },
});

export const getUsers = (page, search, t, sort, orderby, attrs) => {
	return function (dispatch) {
		return axios
			.get(Config.usersUrl(page, search, sort, orderby, attrs))
			.then(response => {
				if (response.status === 200) {
					let users = response.data.results ? response.data.results.users : [];
					const usersPagesCount = Math.ceil(response.data.results.count / 50);
					if (sort === 0 || sort === 1) {
						dispatch(beginUsersFetch());
						dispatch(successUsersFetch(users, usersPagesCount, page));
					} else {
						dispatch(successUsersForModalsFetch(users));
					}
				}
			})
			.catch((response, users, page) => {
				if (response.status === 403 || response.status === 404 || response.status === 409) {
					dispatch(successUsersFetch([]));
					dispatch(showAlertAction(t('fail_auth'), 'warning'));
				} else {
					dispatch(successUsersFetch([]));
					// dispatch(showAlertAction(t("no_user_found"), "warning"));
				}
			});
	};
};

export const beginUsersFetch = () => ({
	type: BEGIN_USERS_FETCH,
	payload: { usersFetching: true },
});

export const successUsersFetch = (users, usersPagesCount, page) => ({
	type: SUCCESS_USERS_FETCH,
	payload: { usersFetching: false, users, usersPagesCount, page },
});

export const successUsersForModalsFetch = usersForModals => ({
	type: SUCCESS_USERSFORMODALS_FETCH,
	payload: { usersFetching: false, usersForModals },
});

export const getUser = email => {
	return function (dispatch) {
		dispatch(beginUserFetch(email));
		return axios
			.get(Config.userUrl(localStorage.getItem('tenant'), email))
			.then(response => {
				if (response.status === 200) {
					dispatch(successUserFetch(response.data.results));
				}
			})
			.catch(() => {
				return dispatch(showAlertAction('Error', 'error'));
			});
	};
};

export const beginUserFetch = email => ({
	type: BEGIN_USER_FETCH,
	payload: { selectedUser: email, userFetching: true },
});

export const successUserFetch = user => ({
	type: SUCCESS_USER_FETCH,
	payload: { userFetching: false, user },
});

export const addNewUser = (userInfo, t) => {
	return function (dispatch, getState) {
		let {
			cn,
			sn,
			l,
			ou,
			domain,
			altDomain,
			initials,
			title,
			description,
			telephoneNumber,			
			homePhone,
			email,
			altEmail,
			employeeNumber,
			userPassword,
			quota,
			displayName,
			labeledURI,
			aliases,
		} = userInfo;

		if (parseFloat(quota) <= 0 || quota.match(/^\./) || quota.match(/\.$/)) {
			dispatch(showAlertAction(t('invalid_quota_value'), 'error'));
			return;
		}

		if (
			!validate(`${email}@${domain}`) ||
			email.length < MIN_LOGIN_LEN ||
			email.match(/(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/g)
		) {
			dispatch(showAlertAction(t('invalid_login_value'), 'error'));
			return;
		}

		if (window.enable_alternative_login && (altEmail || altDomain)) {
			if (
				altEmail.length < MIN_LOGIN_LEN ||
				!altValidate(`${altEmail}@${altDomain}`) ||
				altEmail.match(/(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/g)
			) {
				dispatch(showAlertAction(t('invalid_login_value'), 'error'));
				return;
			}
		}

		if (`${email}@${domain}`.length > 255 || `${altEmail}@${altDomain}`.length > 255) {
			dispatch(showAlertAction(t('invalid_login_limit'), 'error'));
			return;
		}
		
		if (userPassword) {			
			if (userPassword.match(/[а-яА-Я]/g)) {
				dispatch(showAlertAction(t('users_page.pass_req') + ' ' + t('password_only_latin'), 'error'));
				return;
			}			
		}

		const avatar = userInfo.croppedAvatar;
		let avatarForm = null;
		let alias = aliases.map(alias => `"${alias.email}@${alias.domain}"`);
		alias = alias.length > 0 ? `[${alias.join(',')}]` : '';
		let body = avatarForm;
		let mail = `${email}@${domain}`;
		let altMail = `${altEmail}@${altDomain}`;
		const formData = new FormData();
		dispatch(addingNewUser());
		if (l !== '') {
			formData.set('l', l);
		}
		if (title !== '') {
			formData.set('title', title);
		}
		if (ou !== '') {
			formData.set('ou', ou);
		}
		if (description !== '') {
			formData.set('description', description);
		}
		if (telephoneNumber !== '') {
			formData.set('telephoneNumber', telephoneNumber);
		}
		if (homePhone !== '') {
			formData.set('homePhone', homePhone);
		}
		// if (info !== "") {formData.set("info", info)}
		if (displayName !== '') {
			formData.set('displayName', displayName);
		}
		formData.set('cn', cn);
		formData.set('sn', sn);
		if (initials !== '') {
			formData.set('initials', initials);
		}
		formData.set('userPassword', userPassword);

		formData.set('employeeNumber', employeeNumber);
		if (alias && alias !== '[]') {
			formData.set('alias', alias);
		}
		if (quota === 0 || quota === '') {
			formData.set('quota', 0);
		} else formData.set('quota', Math.floor(quota * 1048576));
		if (window.enable_alternative_login && altEmail && altDomain) {
			formData.set('mailAlternateAddress', altMail);
		}

		axios
			.put(Config.editDeleteUserUrl(mail), formData)
			.then(response => {
				if (parseInt(response.status / 200) === 1) {
					if (avatar) {
						avatarForm = new FormData();
						avatarForm.append('file', avatar);
						axios.put(Config.avatarUrl(mail), avatarForm);
					}
					return dispatch(addedNewUser());
				}
			})
			.catch(response => {
				if (response.status === 409) {
					if (response.data.extend) {
						if ('min_length' in response.data.extend) {
							dispatch(
								showAlertAction(
									t('users_page.pass_req') + ' ' + t('users_page.min_length') + ' ' + response.data.extend.min_length,
									'error'
								)
							);
							return dispatch({ type: PREVENT_USER_ADD });
						}
						if ('max_length' in response.data.extend) {
							dispatch(
								showAlertAction(
									t('users_page.pass_req') + ' ' + t('users_page.max_length') + ' ' + response.data.extend.max_length,
									'error'
								)
							);
							return dispatch({ type: PREVENT_USER_ADD });
						}
						if ('wrong_symbol' in response.data.extend) {
							dispatch(
								showAlertAction(
									t('users_page.pass_req') + ' ' + t('users_page.wrong_symbol') + ' ' + response.data.extend.wrong_symbol,
									'error'
								)
							);
							return dispatch({ type: PREVENT_USER_ADD });
						}
						if ('min_digits_count' in response.data.extend) {
							dispatch(
								showAlertAction(
									t('users_page.pass_req') + ' ' + t('users_page.min_digits_count') + ' ' + response.data.extend.min_digits_count,
									'error'
								)
							);
							return dispatch({ type: PREVENT_USER_ADD });
						}
						if ('min_lowercase_letter_count' in response.data.extend) {
							dispatch(
								showAlertAction(
									t('users_page.pass_req') +
										' ' +
										t('users_page.min_lowercase_letter_count') +
										' ' +
										response.data.extend.min_lowercase_letter_count,
									'error'
								)
							);
							return dispatch({ type: PREVENT_USER_ADD });
						}
						if ('min_uppercase_letter_count' in response.data.extend) {
							dispatch(
								showAlertAction(
									t('users_page.pass_req') +
										' ' +
										t('users_page.min_uppercase_letter_count') +
										' ' +
										response.data.extend.min_uppercase_letter_count,
									'error'
								)
							);
							return dispatch({ type: PREVENT_USER_ADD });
						}
						if ('min_spec_symbols_count' in response.data.extend) {
							dispatch(
								showAlertAction(
									t('users_page.pass_req') +
										' ' +
										t('users_page.min_spec_symbols_count') +
										' ' +
										response.data.extend.min_spec_symbols_count,
									'error'
								)
							);
							return dispatch({ type: PREVENT_USER_ADD });
						}
						if (Object.values(response.data.extend).includes('must be greater than or equal to 0')) {
							let paramName = Object.keys(response.data.extend).find(
								key => response.data.extend[key] === 'must be greater than or equal to 0'
							);
							dispatch(showAlertAction(`${t('users_page.parameter')} ${paramName} : ${t('users_page.geater_zero')}`, 'error'));
							return dispatch({ type: PREVENT_USER_ADD });
						}
						if (Object.values(response.data.extend).includes('must be less than or equal to 65535')) {
							let paramName = Object.keys(response.data.extend).find(
								key => response.data.extend[key] === 'must be less than or equal to 65535'
							);
							dispatch(showAlertAction(`${t('users_page.parameter')} ${paramName} : ${t('users_page.less_than')}`, 'error'));
							return dispatch({ type: PREVENT_USER_ADD });
						}
						if (response.data.extend.existing_aliases) {
							dispatch(preventUserAdd(response.data.error.code, response.data.extend.existing_aliases));
							return dispatch({ type: PREVENT_USER_ADD });
						}
					}
					if (response.data.error.code && response.data.error.code === 101) {
						dispatch(showAlertAction(t('users_page.user_already_exists'), 'error'));
						return dispatch({ type: PREVENT_USER_ADD });
					}
					if (response.data.error.code && response.data.error.code === 102) {
						dispatch(showAlertAction(t('users_page.email_already_exists'), 'error'));
						return dispatch({ type: PREVENT_USER_ADD });
					}
					dispatch(showAlertAction(t('users_page.error409'), 'error'));
					return dispatch({ type: PREVENT_USER_ADD });
				} else if (response.status === 400) {
					if (response.data?.error?.code === 304) {
						dispatch(showAlertAction(t('users_page.wrong_mail_format'), 'error'));
						return dispatch({ type: PREVENT_USER_ADD });
					} else {
						dispatch(showAlertAction(t('users_page.required_params'), 'error'));
						return dispatch({ type: PREVENT_USER_ADD });
					}
				} else if (response.status === 401) {
					dispatch(showAlertAction(t('users_page.old_token'), 'error'));
					return dispatch({ type: PREVENT_USER_ADD });
				} else if (response.status === 403) {
					dispatch(showAlertAction(t('users_page.no_permission'), 'error'));
					return dispatch({ type: PREVENT_USER_ADD });
				} else if (response.status === 500) {
					dispatch(showAlertAction(t('Error'), 'error'));
					return dispatch({ type: PREVENT_USER_ADD });
				}
			});
	};
};

export const preventUserAdd = (errorType, aliases) => {
	return function (dispatch) {
		// switch (errorType) {
		//   case 102:
		//     dispatch(
		//       showAlertAction(t("users_page.email_already_exists"), "error")
		//     );
		//     break;
		//   default:
		//     break;
		// }
		localStorage.setItem('aliases', aliases);
		dispatch({ type: PREVENT_USER_ADD });
	};
};

export const addingNewUser = () => ({
	type: ADDING_NEW_USER,
});

export const addedNewUser = () => {
	createHashHistory().push('/users');
	return { type: ADDED_NEW_USER };
};

export const updateUser = (mail, add, replace, remove, t, avatar) => {
	let avatarForm = null;
	if (avatar) {
		avatarForm = new FormData();
		avatarForm.append('file', avatar);
		axios.put(Config.avatarUrl(mail), avatarForm);
	}
	let updArrow = [];
	if (replace.length > 0) {
		for (let i = 0; i < replace.length; i++) {
			let path = Object.keys(replace[i])[0];
			let pathFullValue = replace[i][path];
			// 104876 = 1024x1024 = 2^20
			if (path === '/quota') {
				updArrow.push({ op: 'replace', path: path, value: Math.round(pathFullValue * 1048576) });
			} else if (!pathFullValue.includes(',')) {
				updArrow.push({ op: 'replace', path: path, value: pathFullValue });
			} else {
				let aliasPath = `/alias/${pathFullValue.split(',')[0]}`;
				updArrow.push({ op: 'replace', path: aliasPath, value: pathFullValue.split(',')[1] });
			}
		}
	}
	if (add.length > 0) {
		for (let i = 0; i < add.length; i++) {
			let path = Object.keys(add[i])[0];
			let pathFullValue = add[i][path];		
			if (path === '/quota') {
				updArrow.push({ op: 'add', path: path, value: Math.round(pathFullValue * 1048576) });
			} else if (path.includes('alias')) {			
				path = '/alias'
				updArrow.push({ op: 'add', path: path, value: add[i][Object.keys(add[i])] });
			}
			else updArrow.push({ op: 'add', path: path, value: add[i][Object.keys(add[i])] });
		}
	}
	if (remove.length > 0) {
		for (let i = 0; i < remove.length; i++) {
			let path = Object.keys(remove[i])[0];
			let pathValue = remove[i][Object.keys(remove[i])[0]];
			if (path.includes('alias')) {
				updArrow.push({ op: 'remove', path: `/alias/${pathValue}` });
			} else if (!path.includes('quota')) updArrow.push({ op: 'remove', path: path });
		}
	}
	return function (dispatch, getState) {
		
		dispatch(updatingUser());
		return axios
			.patch(Config.editDeleteUserUrl(mail), updArrow, {
				headers: {
					isAdmin: 'webAdminSolo',
					'Content-Type': 'application/json-patch+json',
				},
			})
			.then(response => {
				if (parseInt(response.status / 200) === 1) {
					return dispatch(updatedUser(response.status));
				}
			})
			.catch(response => {
				if (response.status === 409) {
					if (response.data.extend) {
						if ('min_length' in response.data.extend) {
							dispatch(
								showAlertAction(
									t('users_page.pass_req') + ' ' + t('users_page.min_length') + ' ' + response.data.extend.min_length,
									'error'
								)
							);
							return dispatch({ type: PREVENT_USER_ADD });
						}
						if ('max_length' in response.data.extend) {
							dispatch(
								showAlertAction(
									t('users_page.pass_req') + ' ' + t('users_page.max_length') + ' ' + response.data.extend.max_length,
									'error'
								)
							);
							return dispatch({ type: PREVENT_USER_ADD });
						}
						if ('wrong_symbol' in response.data.extend) {
							dispatch(
								showAlertAction(
									t('users_page.pass_req') + ' ' + t('users_page.wrong_symbol') + ' ' + response.data.extend.wrong_symbol,
									'error'
								)
							);
							return dispatch({ type: PREVENT_USER_ADD });
						}
						if ('min_digits_count' in response.data.extend) {
							dispatch(
								showAlertAction(
									t('users_page.pass_req') + ' ' + t('users_page.min_digits_count') + ' ' + response.data.extend.min_digits_count,
									'error'
								)
							);
							return dispatch({ type: PREVENT_USER_ADD });
						}
						if ('min_lowercase_letter_count' in response.data.extend) {
							dispatch(
								showAlertAction(
									t('users_page.pass_req') +
										' ' +
										t('users_page.min_lowercase_letter_count') +
										' ' +
										response.data.extend.min_lowercase_letter_count,
									'error'
								)
							);
							return dispatch({ type: PREVENT_USER_ADD });
						}
						if ('min_uppercase_letter_count' in response.data.extend) {
							dispatch(
								showAlertAction(
									t('users_page.pass_req') +
										' ' +
										t('users_page.min_uppercase_letter_count') +
										' ' +
										response.data.extend.min_uppercase_letter_count,
									'error'
								)
							);
							return dispatch({ type: PREVENT_USER_ADD });
						}
						if ('min_spec_symbols_count' in response.data.extend) {
							dispatch(
								showAlertAction(
									t('users_page.pass_req') +
										' ' +
										t('users_page.min_spec_symbols_count') +
										' ' +
										response.data.extend.min_spec_symbols_count,
									'error'
								)
							);
							return dispatch({ type: PREVENT_USER_ADD });
						}
						if (Object.values(response.data.extend).includes('must be greater than or equal to 0')) {
							let paramName = Object.keys(response.data.extend).find(
								key => response.data.extend[key] === 'must be greater than or equal to 0'
							);
							dispatch(showAlertAction(`${t('users_page.parameter')} ${paramName} : ${t('users_page.geater_zero')}`, 'error'));
							return dispatch({ type: PREVENT_USER_ADD });
						}
						if (Object.values(response.data.extend).includes('must be less than or equal to 65535')) {
							let paramName = Object.keys(response.data.extend).find(
								key => response.data.extend[key] === 'must be less than or equal to 65535'
							);
							dispatch(showAlertAction(`${t('users_page.parameter')} ${paramName} : ${t('users_page.less_than')}`, 'error'));
							return dispatch({ type: PREVENT_USER_ADD });
						}
						if (response.data.extend.existing_aliases) {
							dispatch(preventUserAdd(response.data.error.code, response.data.extend.existing_aliases));
							return dispatch({ type: PREVENT_USER_ADD });
						}
					}

					if (response.data.error.code && response.data.error.code === 101) {
						dispatch(showAlertAction(t('users_page.user_already_exists'), 'error'));
						return dispatch({ type: PREVENT_USER_ADD });
					}
					if (response.data.error.code && response.data.error.code === 102) {
						dispatch(showAlertAction(t('users_page.email_already_exists'), 'error'));
						return dispatch({ type: PREVENT_USER_ADD });
					}
					dispatch(showAlertAction(t('users_page.error409'), 'error'));
					return dispatch({ type: PREVENT_USER_ADD });
				}
				if (response.status === 400) {
					dispatch(showAlertAction(t('users_page.required_params'), 'error'));
					return dispatch({ type: PREVENT_USER_ADD });
				}
				if (response.status === 401) {
					dispatch(showAlertAction(t('users_page.old_token'), 'error'));
					return dispatch({ type: PREVENT_USER_ADD });
				}
				if (response.status === 403) {
					dispatch(showAlertAction(t('users_page.no_permission'), 'error'));
					return dispatch({ type: PREVENT_USER_ADD });
				}
				if (!response.data || response.status === 500) return dispatch(errorUpdUser('unknown', ''));
				return dispatch(errorUpdUser(response.status, response.data?.error?.code));
			});
	};
};
export const updatingUser = () => ({
	type: UPDATING_USER,
	payload: { updatingUser: true },
});

export const updatedUser = status => {
	createHashHistory().push('/users');
	return {
		type: UPDATED_USER,
		payload: { updatingUser: false, status },
	};
};
export const cleanSelectedUser = () => ({
	type: CLEAN_SELECTED_USER,
	payload: { selectedUser: '' },
});
export const errorUpdUser = (status, code) => ({
	type: UPDATED_USER,
	payload: { status: status, code: code },
});
export const RemoveUpdStatus = () => ({
	type: UPDATED_USER,
	payload: { status: 'empty', code: '' },
});

export const deleteUser = (userMail, t) => {
	return function (dispatch) {
		dispatch(deletingUser());
		axios
			.delete(Config.editDeleteUserUrl(userMail))
			.then(() => {
				dispatch(deletedUser(userMail));
			})
			.catch(() => {
				return dispatch(showAlertAction('Error', 'error'));
			});
	};
};

export const deletingUser = () => ({
	type: DELETING_USER,
	payload: { deletingUser: true },
});

export const deletedUser = userMail => {
	createHashHistory().push('/users');
	return {
		type: DELETED_USER,
		payload: { deletingUser: false, userMail },
	};
};

export const getCalendars = mail => {
	return function (dispatch) {
		dispatch(beginCalendarsFetch());
		return axios
			.get(Config.calendarsUrl(mail))
			.then(response => {
				if (response.status === 200) {					
					dispatch(successCalendarsFetch(response.data, response.status));
				}
			})
			.catch(response => {							
				dispatch(successCalendarsFetch(response.data, response.status));
			});
	};
};

export const beginCalendarsFetch = () => ({
	type: BEGIN_CALENDARS_FETCH,
	payload: { calendarsFetching: true },
});

export const successCalendarsFetch = (calendars, status) => ({
	type: SUCCESS_CALENDARS_FETCH,
	payload: { calendarsFetching: false, calendars, status },
});

export const removeCalendarStatus = () => {
	return {
		type: REMOVE_CALENDARS_STATUS,
		payload: { status: 'empty' },
	};
};

export const transfCalendars = (mail, replace, t) => {
	// export const transfCalendars = (mail, newUser, calendars, t ) => {
	let updArrow = [];
	return function (dispatch) {
		if (replace.length > 0) {
			for (let i = 0; i < replace.length; i++) {
				updArrow.push();
			}
		}
		// const formData = new FormData();
		// formData.set("new_owner", newUser)
		// formData.set("calendar_ids", JSON.stringify(calendars))
		dispatch(beginTransfCalendars());
		return axios
			.patch(
				Config.calendarsUrl(mail),
				replace,
				// formData,
				{
					headers: {
						isAdmin: 'webAdminSolo',
						'Content-Type': 'application/json-patch+json',
					},
				}
			)
			.then(response => {
				if (parseInt(response.status / 200) === 1) {
					return dispatch(successTransfCalendars(mail, response.status));
				}
			})

			.catch(response => {
				if (!response.data) return dispatch(errorTransfCalendars('unknown', ''));
				return dispatch(errorTransfCalendars(response.status, response.data?.error?.code));
			});
	};
};

export const beginTransfCalendars = () => ({
	type: BEGIN_TRANSF_CALENDARS,
	payload: { calendarsTransfering: true },
});

export const successTransfCalendars = (mail, status) => {
	// createHashHistory().push("/user/:mail/edit")
	//
	return {
		type: SUCCESS_TRANSF_CALENDARS,
		payload: { calendarsTransfering: false, mail, status },
	};
};

export const errorTransfCalendars = (status, code) => ({
	type: SUCCESS_TRANSF_CALENDARS,
	payload: { status: status, code: code },
});

export const RemoveTransfStatus = () => ({
	type: SUCCESS_TRANSF_CALENDARS,
	payload: { status: 'empty', code: '' },
});

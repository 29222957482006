import React from 'react';
import { TextField, Button, Radio, RadioGroup, FormControlLabel, FormControl } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { addNewMaillist, removeAddStatus, getMaillists, getSelectedMaillist } from '../../actions/MaillistsActions.js';
import { withTranslation } from 'react-i18next';
import { getUsers } from '../../actions/UsersActions';
import { getDomains } from '../../actions/DomainsActions';
import StaticFields from './Subs/StaticFields.js';
import UserFields from './Subs/UsersField.js';
import Filters from './Subs/Filters.js';
import { createHashHistory } from 'history';
import Chip from '@material-ui/core/Chip';
import '../../styles/Maillists.css';

const mapStateToProps = state => ({
	usersForModals: state.users.usersForModals,
	domains: state.domains.domains,
	domainsFetching: state.domains.domainsFetching,
	add_status: state.maillists.add_status,
});

const mapDispatchToProps = dispatch => ({
	getMaillists: t => dispatch(getMaillists(t)),
	addNewMaillist: (mail, alias, displayName, typeMailList, t, generationQualifier, filters) =>
		dispatch(addNewMaillist(mail, alias, displayName, typeMailList, t, generationQualifier, filters)),
	getUsers: (page, search, t) => dispatch(getUsers(page, search, t)),
	getDomains: () => dispatch(getDomains()),
	removeAddStatus: () => dispatch(removeAddStatus()),
	getSelectedMaillist: displayName => dispatch(getSelectedMaillist(displayName)),
});

const styles = theme => ({
	'@global': {
		'*::-webkit-scrollbar': {
			width: '4px',
		},
		'*::-webkit-scrollbar-track': {
			// backgroundColor: "red",
			// width:"4px"
		},
		'*::-webkit-scrollbar-thumb': {
			backgroundColor: 'rgba(25, 37, 46, 0.16)',
			// width:"14px",
			// paddingTop: "28px",
			borderRadius: '2px',
		},
	},
	root: {
		color: 'purple',
		'&$checked': {
			color: 'orange',
		},
	},
	checked: {},

	mainButton: {
		backgroundColor: 'rgba(68, 187, 255, 0.56) !important',
		color: 'rgba(25, 37, 46, 1)',
		width: '98px',
		height: '32px',
		marginRight: '4px',
		textTransform: 'none',
		fontWeight: '525',
		fontSize: '14px',
		lineHeight: '16px',
		letterSpacing: '0.35px',
		border: '1px solid rgba(25, 37, 46, 0.08)',
		'&:hover': {
			backgroundColor: 'rgba(68, 187, 255, 0.72) !important',
			color: 'black',
			border: '1px solid rgba(25, 37, 46, 0.08)',
		},
		'&:disabled': {
			backgroundColor: 'rgba(25, 37, 46, 0.08) !important',
			color: 'rgba(163, 168, 171, 1)',
			border: '1px solid rgba(25, 37, 46, 0.08)',
		},
	},
	cancelButton: {
		color: 'black',
		fontWeight: '525',
		width: '98px',
		height: '32px',
		textTransform: 'none',
		fontSize: '14px',
		lineHeight: '16px',
		letterSpacing: '0.35px',
		border: '1px solid #C0C0C0',
		'&:hover': {
			color: 'black',
			backgroundColor: 'rgba(25, 37, 46, 0.08) !important',
		},
	},
});

class NewMaillist extends React.Component {
	constructor(props) {
		super(props);
		this.history = createHashHistory();
		this.state = {
			maillistEmail: '',
			maillistAlias: [],
			maillistName: '',
			domain: '',
			typeMailList: 'static',
			maillistAliasAdded: false,
			emailFieldError: false,
			generationQualifier: 'OR',
			filterArr: [
				{
					type: 'title',
					value: '',
					id: Date.now(),
					isValid: true,
				},
			],
			editingGroup: false,
			uniqNum: 0,
		};

		this.RadioIcon = (
			<svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
				<rect x='1' y='1' width='16' height='16' rx='8' stroke='#19252E' strokeOpacity='0.56' strokeWidth='2' />
			</svg>
		);
		this.RadioIconChecked = (
			<svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
				<rect width='18' height='18' rx='9' fill='#44BBFF' fillOpacity='0.56' />
				<circle cx='9' cy='9' r='4' fill='#2B363F' />
				<rect x='0.5' y='0.5' width='17' height='17' rx='8.5' stroke='#19252E' strokeOpacity='0.08' />
			</svg>
		);
	}

	async componentDidMount() {
		await this.props.removeAddStatus();
		await this.props.getDomains().then(() => {
			this.setState({ domain: this.props.domains[0].name });
		});
	}

	componentDidUpdate(prevProps, prevState) {
		if (!this.props.domains) {
			this.props.getDomains();
		}
	}
	handleaddNewMaillist = async (e, t) => {
		e.preventDefault();
		if (this.state.typeMailList === 'static') {
			await this.props.addNewMaillist(
				this.state.maillistEmail + '@' + this.state.domain,
				this.state.maillistAlias,
				this.state.maillistName.trim(),
				this.state.typeMailList,
				t
			);
		}
		if (this.state.typeMailList === 'dynamic') {
			let filters = this.state.filterArr.map(({ uniqId, ...rest }) => ({ ...rest }));
			let r = filters.map(el => `{"type":"${el.type}","value":"${el.value}"}`);
			await this.props.addNewMaillist(
				this.state.maillistEmail + '@' + this.state.domain,
				[],
				this.state.maillistName.trim(),
				this.state.typeMailList,
				t,
				this.state.generationQualifier,
				r
			);
		}
		if (!this.props.add_status) {
			this.props.getSelectedMaillist(this.state.maillistName);
		}
	};

	handleCancel = e => {
		e.preventDefault();
		this.history.push('/maillists/');
	};

	render() {
		const { classes, t } = this.props;
		return (
			<div className='root'>
				<form className='infoForm' noValidate autoComplete='off'>
					<div className='newListTitle' style={{ marginBottom: '24px' }}>
						{t('mailLists.new_group')}
					</div>
					<StaticFields
						t={t}
						nameChange={v => this.setState({ maillistName: v })}
						emailChange={v => this.setState({ maillistEmail: v })}
						domainChange={v => this.setState({ domain: v })}
						typeChange={v => this.setState({ typeMailList: v })}
						maillistEmail={this.state.maillistEmail}
						maillistName={this.state.maillistName}
						domain={this.state.domain}
						typeMailList={this.state.typeMailList}
						domains={this.props.domains}
						add_status={this.props.add_status}
						emailFieldError={this.state.emailFieldError}
						setEmailFieldError={v => this.setState({ emailFieldError: v })}
						editingGroup={this.state.editingGroup}
					/>

					<div className='title' style={{ marginTop: '30px', marginBottom: '16px' }}>
						{t('mailLists.members')}
					</div>
					<div style={{ display: this.state.typeMailList === 'static' ? 'block' : 'none' }}>
						<UserFields
							users={this.props.usersForModals}
							getUsers={v => this.props.getUsers(1, v, t)}
							maillistAlias={this.state.maillistAlias}
							setMaillistAlias={v => this.setState({ maillistAlias: v })}
							edit={true}
						/>
					</div>
					<div style={{ display: this.state.typeMailList === 'dynamic' ? '' : 'none', flexDirection: 'column' }}>
						<div className='dynamicInfo' style={{ marginTop: '6px', marginBottom: '24px' }}>
							{t('mailLists.dynamic_info')}
						</div>
						<div className='titleSmall' style={{ marginBottom: '16px' }}>
							{t('mailLists.add_to_group_if')}
						</div>
						<div style={{ display: this.state.filterArr.length > 1 ? '' : 'none' }}>
							<FormControl className='radioRoot'>
								<RadioGroup
									row
									value={this.state.generationQualifier}
									onChange={e => this.setState({ generationQualifier: e.target.value })}
								>
									<FormControlLabel
										value='OR'
										control={<Radio icon={this.RadioIcon} checkedIcon={this.RadioIconChecked} />}
										label={t('mailLists.one_condition')}
										className='custom-label'
									/>
									<FormControlLabel
										value='AND'
										control={<Radio icon={this.RadioIcon} checkedIcon={this.RadioIconChecked} />}
										label={t('mailLists.all_conditions')}
										style={{ marginLeft: '0px' }}
										className='custom-label'
									/>
								</RadioGroup>
							</FormControl>
						</div>
						<Filters
							domains={this.props.domains}
							filters={this.state.filterArr}
							setFilters={v => this.setState({ filterArr: v })}
							uniqNum={this.state.uniqNum}
							setUniqNum={v => this.setState({ uniqNum: v })}
						/>
					</div>
				</form>
				<div className='maillistButtons'>
					<Button
						disabled={
							!this.state.maillistName ||
							this.state.maillistName.length > 255 ||
							!this.state.maillistEmail ||
							(this.state.typeMailList === 'static' && !this.state.maillistAlias) ||
							this.state.emailFieldError ||
							(this.state.typeMailList === 'static' && this.state.maillistAlias.length === 0) ||
							(this.state.typeMailList === 'dynamic' && this.state.filterArr.length === 0) ||
							(this.state.typeMailList === 'dynamic' && this.state.filterArr.find(el => !el.isValid)) ||
							(this.state.typeMailList === 'dynamic' && this.state.filterArr.find(el => !el.value))
						}
						onClick={e => this.handleaddNewMaillist(e, t)}
						className={classes.mainButton}
					>
						{t('save')}
					</Button>
					<Button className={classes.cancelButton} onClick={this.handleCancel} style={{ marginLeft: '12px' }}>
						{t('cancel')}
					</Button>
				</div>
			</div>
		);
	}
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(NewMaillist)));
